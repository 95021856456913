import {makeAutoObservable, runInAction} from "mobx";
import {TokenUtil} from "../utils/token";
import {http} from "../utils/http";

export class Authentication {
  isLoggedIn = false;
  isLoginLoading = false;
  ctx;
  profileData = {};
  dataProfit=[];
  listImage=[];
  imageProfil=[];

  constructor(ctx) {
    this.ctx = ctx;
    makeAutoObservable(this);
  }

  get userData() {
    const defaultValue = {
      role: '',
      user_id: '',
      username: '',
    };

    try {
      return JSON.parse(atob(TokenUtil.accessToken.split('.')[1]));
    } catch (err) {
      return defaultValue;
    }
  }

  async login({username, password}) {
    runInAction(() => {
      this.isLoginLoading = true;
    });

    try {
      const result = await http.post('/auth/login').send({username, password});
      
      TokenUtil.setAccessToken(result.body.access_token);
      TokenUtil.persistToken();
      runInAction(() => {
        this.isLoginLoading = false;
        this.isLoggedIn = true;
      });
    } catch (e) {
      runInAction(() => {
        this.isLoginLoading = false;
      });
      console.error(e);
      throw e;
    }
  }

  async getProfit(id) {
    try {
      const response = await http.get(`/auth/profile/${id}`);
      console.log(response,"Data Gambar Store")
      this.dataProfit = response.body ?? [];
      this.listImage = this.dataProfit.userDetail?.image_store ? JSON.parse(this.dataProfit.userDetail?.image_store) : [];  
      this.total_data = response?.body?.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async getProfile() {
    try {
      const response = await http.get('/auth/profile');
      console.log(response,"Data Profile")
      this.profileData = response.body;
      this.imageProfil = this.profileData.userDetail?.image_store ? JSON.parse(this.profileData.userDetail?.image_store) : [];
     
      //this.imageProfil = this.profileData.userDetail?.image_store ? JSON.parse(this.profileData.userDetail?.image_store) : [];
    } catch (e) {
      console.error(e);
    }
  }

  logout() {
    TokenUtil.clearAccessToken();
    TokenUtil.persistToken();
    this.isLoggedIn = false;
    window.location.reload();
  }
}
