import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  List,
  message,
  DatePicker,
  Row,
  Table,
  Tag,
  Form,
  Modal,
} from "antd";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { FilterOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { PaybackModal } from "./PaybackModal";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { LINKS } from "../../routes/app";
import { ModalLoaderContext } from "../../utils/modal";
import { appConfig } from "../../config/app";
import { PAYBACK_STATUS } from "../../constants/payback";
import moment from "moment";

export const PaybackCreated = observer(() => {
  const [form] = Form.useForm();
  const store = useStore();
  const [filterMembership, setFilterMembership] = useState([]);
  const [initialData, setInitialData] = useState({});
  const modalLoader = useContext(ModalLoaderContext);

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        await Promise.allSettled([
          store.payback.getDataCreated(),
          store.authentication.getProfile(),
        ]);
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const columns = [
    {
      title: "Gambar",
      dataIndex: "image_prove",
      key: "image_prove",
      render: (text, record) => (
        <Image
          src={`${appConfig.apiUrl}/config/image/${text}`}
          style={{ width: "5vw" }}
          alt={record.id}
        />
      ),
    },
    {
      title: "Nominal",
      dataIndex: "amount",
      key: "amount",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (text, record) => (
        <Tag
          color={
            record.status === 0
              ? "purple"
              : record.status === 1
              ? "blue"
              : record.status === 2
              ? "warning"
              : record.status === 3
              ? "success"
              : "red"
          }
        >
          {record.status === 0
            ? "Pending"
            : record.status === 1
            ? "Success"
            : record.status === 2
            ? "Failed"
            : record.status === 3
            ? "Disetujui"
            : "Ditolak"}
        </Tag>
      ),
    },
  ];

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.PAYBACK_CREATED,
      name: <span style={{ fontWeight: "bold" }}>Bukti Pembayaran</span>,
    },
  ];

  const dataRoute = [
    {
      route: LINKS.PAYBACK_CREATED,
      name: "Bukti Pembayaran",
    },
  ];

  const handleRemoveFilter = async () => {
    store.payback.filterStart = null;
    store.payback.filterEnd = null;
    form.resetFields();
    await store.payback.getDataCreated();
    store.payback.visibleModalFilterCreate = false;
  };

  const handleCancelFilter = async () => {
    store.payback.filterStart = null;
    store.payback.filterEnd = null;
    store.payback.visibleModalFilterCreate = false;
    await store.payback.getDataCreated();
  };

  const handleSubmitFilter = async () => {
    const data = form.getFieldsValue();
    console.log(data);
    store.payback.filterStart = moment(data.start_date).format(
      "YYYY-MM-DD 00:00:00"
    );
    store.payback.filterEnd = moment(data.end_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    modalLoader.setLoading(true);
    await store.payback.getDataCreated();
    modalLoader.setLoading(false);
    store.payback.visibleModalFilterCreate = false;
  };

  const footerLayoutFilter = [
    <Button
      key={"remove"}
      onClick={handleRemoveFilter}
      style={{
        backgroundColor: "#e74e5e",
        color: "#fff",
      }}
    >
      Remove Filter
    </Button>,
    <Button key={"cancel"} onClick={handleCancelFilter}>
      Cancel
    </Button>,
    <Button
      key={"submit"}
      onClick={handleSubmitFilter}
      style={{
        backgroundColor: "#4e79e7",
        color: "#fff",
      }}
    >
      Apply
    </Button>,
  ];

  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent
        data={
          store.authentication.userData.role === "Admin" ? routeData : dataRoute
        }
      />
      <Card>
        <div>
          <Row style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Button
                onClick={() => {
                  store.payback.visibleModalFilterCreate = true;
                }}
              >
                <FilterOutlined />
                Filter
              </Button>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              {/* <Search
                placeholder="input search text"
                style={{
                  width: store.ui.mediaQuery.isMobile ? 160 : 200,
                  marginRight: store.ui.mediaQuery.isMobile ? 0 : 10,
                  marginBottom: store.ui.mediaQuery.isMobile ? 10 : 0,
                }}
              /> */}
              {store.authentication.userData.role !== "Admin" && (
                <Button
                  onClick={() => {
                    setInitialData({});
                    store.payback.visibleModalPayback = true;
                  }}
                >
                  <PlusSquareOutlined /> New
                </Button>
              )}
            </Col>
          </Row>
          {store.ui.mediaQuery.isDesktop && (
            <Table
              key="1"
              hasEmpty
              columns={columns}
              dataSource={store.payback.dataCreated}
              bordered
              pagination={{
                pageSize: store.payback.pageSizeCreated,
                total: store.payback.totalDataCreated,
                current: store.payback.pageCreated + 1,
                showSizeChanger: true,
                simple: false,
              }}
              onChange={async (page) => {
                let pageNumber = page.current;
                store.payback.pageSizeCreated = page.pageSize;
                store.payback.pageCreated = pageNumber - 1;
                modalLoader.setLoading(true);
                await store.payback.getDataCreated();
                modalLoader.setLoading(false);
              }}
            />
          )}

          {store.ui.mediaQuery.isMobile && (
            <List
              itemLayout="horizontal"
              position={"top"}
              pagination={{
                onChange: async (page, pageSize) => {
                  store.payback.pageSizeCreated = pageSize;
                  store.payback.pageCreated = page - 1;
                  modalLoader.setLoading(true);
                  await store.payback.getDataCreated();
                  modalLoader.setLoading(false);
                },
                pageSize: store.payback.pageSizeCreated,
                total: store.payback.totalDataCreated,
                current: store.payback.pageCreated + 1,
                style: { marginBottom: "1rem", marginRight: "1rem" },
              }}
              dataSource={store.payback.dataCreated}
              style={{ padding: 0 }}
              renderItem={(item) => {
                return (
                  <div>
                    <List.Item
                      key={item.id}
                      style={{
                        backgroundColor: "#ffffff",
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <List.Item.Meta
                        className={[""].join(" ")}
                        title={item.name}
                        description={
                          <div style={{ marginBottom: 10 }}>
                            <small>Amount: {item.amount}</small>
                            <br />
                            <div style={{ marginTop: 5 }}>
                              <small>Status : </small>
                              <Tag
                                color={
                                  item.status === 0
                                    ? "purple"
                                    : item.status === 1
                                    ? "blue"
                                    : item.status === 2
                                    ? "warning"
                                    : item.status === 3
                                    ? "success"
                                    : "red"
                                }
                              >
                                {item.status === 0
                                  ? "Pending"
                                  : item.status === 1
                                  ? "Success"
                                  : item.status === 2
                                  ? "Failed"
                                  : item.status === 3
                                  ? "Disetujui"
                                  : "Ditolak"}
                              </Tag>
                            </div>
                          </div>
                        }
                      />
                      <div style={{ marginRight: 16 }}>
                        <p
                          style={{
                            fontSize: 9,
                            margin: 0,
                          }}
                        >
                          {" "}
                          <Image
                            src={`${appConfig.apiUrl}/config/image/${item.image_prove}`}
                            style={{ width: "20vw" }}
                            preview={true}
                          />
                        </p>
                      </div>
                    </List.Item>
                    <Divider plain style={{ margin: 0 }} />
                  </div>
                );
              }}
            />
          )}
        </div>
      </Card>
      <Modal
        visible={store.payback.visibleModalFilterCreate}
        title={"Filter"}
        footer={footerLayoutFilter}
        onCancel={async () => {
          //form.resetFields();
          store.payback.filterStart = null;
          store.payback.filterEnd = null;
          store.payback.visibleModalFilterCreate = false;
          await store.payback.getDataCreated();
        }}
      >
        <Row>
          <Col span={24}>
            <Form layout="vertical" name="filter" form={form}>
              <Form.Item
                name="start_date"
                label="Dari"
                rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="end_date"
                label="Sampai"
                rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
      <PaybackModal initialData={initialData} />
    </div>
  );
});
