import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  List,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Select,
  Typography,
  DatePicker,
  Form,
  Divinder,
} from "antd";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { LINKS } from "../../routes/app";
import { ModalLoaderContext } from "../../utils/modal";
import { appConfig } from "../../config/app";
import { capitalize } from "lodash";
import { PAYBACK_STATUS } from "../../constants/payback";
import moment from "moment";
import { useHistory } from "react-router-dom";

export const Konfirmasi = observer(() => {
  const history = useHistory();
  const { Option } = Select;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const store = useStore();
  const modalLoader = useContext(ModalLoaderContext);
  const [filterMembership, setFilterMembership] = useState([]);
  const [filterSubCategories, setFilterSubCategories] = useState([]);
  const [visibleModalToko, setVisibleModalToko] = useState(false);
  const [VisibleModalIdentitas, setVisibleModalIdentitas] = useState(false);
  const [toko, setToko] = useState({});
  const [identitas, setIdentitas] = useState({});

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        await store.approval.getData();
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const columns = [
    {
      title: "Nama",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Foto Identitas",
      dataIndex: ["user_detail", "image_identity"],
      key: "user_detail.image_identity",
      render: (text, record) =>
        record.user_detail?.image_identity ? (
          <Image
            src={`${appConfig.apiUrl}/config/image/${text}`}
            style={{ width: "5vw" }}
            alt={record.image_identity}
          />
        ) : (
          <Image
            src="https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
            style={{ width: "5vw" }}
          />
        ),
    },
    {
      title: "Foto Toko",
      render: (text, record) =>
        record.roles?.name !== "Sales" &&
        (record.user_detail?.image_store !== "[]" ||
          record.user_detail?.image_store !== '""') ? (
          <Button
            onClick={async () => {
              setToko(record);
              setVisibleModalToko(true);
            }}
          >
            Lihat Foto
          </Button>
        ) : (
          ""
        ),
    },
    {
      title: "Tindakan",
      dataIndex: "amount",
      key: "action",
      width: "10%",
      render: (text, record) =>
        record.is_active === false ? (
          <Space size="small">
            <Button
              onClick={async () => {
                Modal.confirm({
                  title: `Are you sure Accept this submission?`,
                  icon: <CheckOutlined />,
                  okText: "Accept",
                  cancelText: "Batal",
                  okType: "primary",
                  onOk() {
                    handleApprove(record.id);
                    console.log(record.id);
                  },
                  onCancel() {
                    console.log("Batal");
                  },
                });
              }}
              icon={<CheckCircleOutlined />}
              style={{
                backgroundColor: "#1bb91d",
                color: "#fff",
                borderColor: "#1bb91d",
              }}
            >
              Accept
            </Button>
            <Button
              onClick={async () => {
                Modal.confirm({
                  title: `Are you sure Reject this submission?`,
                  icon: <StopOutlined />,
                  okText: "Reject",
                  cancelText: "Batal",
                  okType: "primary",
                  onOk() {
                    handleReject(record.id);
                  },
                  onCancel() {
                    console.log("Batal");
                  },
                });
              }}
              icon={<CloseOutlined />}
              style={{
                backgroundColor: "#ff1c1c",
                color: "#fff",
                borderColor: "#ff1c1c",
              }}
            >
              Reject
            </Button>
          </Space>
        ) : (
          <Tag
            color={record.is_active === false ? "red" : "cyan"}
            style={{ color: "#4F566B" }}
          >
            {record.is_active === false ? "Tidak Aktif" : "Aktif"}
          </Tag>
        ),
    },
  ];

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.KONFIRMASI,
      name: <span style={{ fontWeight: "bold" }}>Konfirmasi Retail</span>,
    },
  ];

  const dataRoute = [
    {
      route: LINKS.PAYBACK,
      name: "Konfirmasi Retail",
    },
  ];

  const handleApprove = async (id) => {
    modalLoader.setLoading(true);
    try {
      const response = await store.approval.approveUser(id);
      console.log(response);
      response.body.statusCode !== 201 && response.body.statusCode !== 200
        ? message.error(response?.body?.message || `Failed Approve`)
        : message.success(response?.body?.message || `Success Approve`);
    } catch (e) {
      console.error(e, "apa errornya");
      message.error(e.response?.body?.message || "Fail Approve");
    }
    modalLoader.setLoading(false);
  };

  const handleReject = async (id) => {
    modalLoader.setLoading(true);
    try {
      const response = await store.approval.rejectUser(id);
      console.log(response);
      response.body.statusCode !== 201 && response.body.statusCode !== 200
        ? message.error(response?.body?.message || `Failed Reject`)
        : message.success(response?.body?.message || `Success Reject`);
    } catch (e) {
      console.error(e, "apa errornya");
      message.error(e.response?.body?.message || "Fail Approve");
    }
    modalLoader.setLoading(false);
  };

  //if (store.approval.user_detail.image_store === []) delete columns[2];
  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent
        data={
          store.authentication.userData.role === "Admin" ||
          store.authentication.userData.role === "Customer Service"
            ? routeData
            : dataRoute
        }
      />
      <Card>
        <div style={{ marginTop: 30 }}>
          {store.ui.mediaQuery.isDesktop && (
            <Table
              key="1"
              hasEmpty
              columns={columns}
              dataSource={store.approval.data}
              bordered
              pagination={{
                pageSize: store.approval.pageSize,
                total: store.approval.total_data,
                current: store.approval.pageSize + 1,
                showSizeChanger: true,
                simple: false,
              }}
              onChange={async (page) => {
                let pageNumber = page.current;
                store.approval.pageSize = page.pageSize;
                store.approval.page = pageNumber - 1;
                modalLoader.setLoading(true);
                await store.approval.getData();
                modalLoader.setLoading(false);
              }}
            />
          )}

          {store.ui.mediaQuery.isMobile && (
            <List
              itemLayout="horizontal"
              position={"top"}
              pagination={{
                onChange: async (page) => {
                  store.approval.pageSize = page.pageSize;
                  store.approval.page = page.current - 1;
                  modalLoader.setLoading(true);
                  await store.approval.getData();
                  modalLoader.setLoading(false);
                },
                pageSize: store.approval.pageSize,
                total: store.approval.total_data,
                current: store.approval.page + 1,
                style: { marginBottom: "1rem", marginRight: "1rem" },
              }}
              dataSource={store.approval.data}
              style={{ padding: 0 }}
              renderItem={(item) => {
                return (
                  <div>
                    <List.Item
                      key={item.id}
                      style={{
                        backgroundColor: "#ffffff",
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <List.Item.Meta
                        className={[""].join(" ")}
                        description={
                          <div style={{}}>
                            <p>
                              <b>Username: {item.username}</b>
                              <br />
                              {item.is_active === false ? (
                                <Space size="small">
                                  <Button
                                    onClick={async () => {
                                      Modal.confirm({
                                        title: `Are you sure Accept this submission?`,
                                        icon: <CheckOutlined />,
                                        okText: "Accept",
                                        cancelText: "Batal",
                                        okType: "primary",
                                        onOk() {
                                          handleApprove(item.id);
                                        },
                                        onCancel() {
                                          console.log("Batal");
                                        },
                                      });
                                    }}
                                    icon={<CheckCircleOutlined />}
                                    style={{
                                      backgroundColor: "#1bb91d",
                                      color: "#fff",
                                      borderColor: "#1bb91d",
                                    }}
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    onClick={async () => {
                                      Modal.confirm({
                                        title: `Are you sure Reject this submission?`,
                                        icon: <StopOutlined />,
                                        okText: "Reject",
                                        cancelText: "Batal",
                                        okType: "primary",
                                        onOk() {
                                          handleReject(item.id);
                                        },
                                        onCancel() {
                                          console.log("Batal");
                                        },
                                      });
                                    }}
                                    icon={<CloseOutlined />}
                                    style={{
                                      backgroundColor: "#ff1c1c",
                                      color: "#fff",
                                      borderColor: "#ff1c1c",
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </Space>
                              ) : (
                                <Tag
                                  color={
                                    item.is_active === false ? "red" : "cyan"
                                  }
                                  style={{ color: "#4F566B" }}
                                >
                                  {item.is_active === false
                                    ? "Tidak Aktif"
                                    : "Aktif"}
                                </Tag>
                              )}
                              {item.user_detail?.image_identity !== "" ? <Button
                                style={
                                  item.is_active === true
                                    ? {
                                        marginTop: 5,
                                      }
                                    : { marginLeft: 10, marginTop: 5 }
                                }
                                onClick={async () => {
                                  setIdentitas(item);
                                  setVisibleModalIdentitas(true);
                                }}
                              >
                                Foto Identitas
                              </Button>: ""}
                              
                              {item.user_detail?.image_store !== '""' ? (
                                <Button
                                  style={
                                    item.is_active === true
                                      ? {
                                          marginLeft: 10,
                                        }
                                      : { marginTop: 10 }
                                  }
                                  onClick={async () => {
                                    setToko(item);
                                    setVisibleModalToko(true);
                                  }}
                                >
                                  Foto Toko
                                </Button>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        }
                      />
                      <div style={{ marginRight: 16 }}></div>
                    </List.Item>
                    <Divider plain style={{ margin: 0 }} />
                  </div>
                );
              }}
            />
          )}
        </div>
      </Card>
      <Modal
        visible={
          visibleModalToko === true ? visibleModalToko : VisibleModalIdentitas
        }
        okText={"Confirm"}
        onCancel={() => {
          form.resetFields();
          setVisibleModalToko(false);
          setVisibleModalIdentitas(false);
        }}
        width={1000}
        footer={false}
      >
        {visibleModalToko === true ? (
          <Row>
            {JSON.parse(toko.user_detail.image_store).map((gmbr, idx) => (
              <Card
                style={
                  store.ui.mediaQuery.isDesktop
                    ? {
                        width: "20vw",
                        borderColor: "salmon",
                        marginLeft: "10px",
                      }
                    : {
                        width: "75vw",
                        borderColor: "salmon",
                        marginBottom: "10px",
                      }
                }
              >
                <Image
                  src={`${appConfig.apiUrl}/config/image/${gmbr}`}
                  alt={idx}
                  preview={false}
                />
              </Card>
            ))}
          </Row>
        ) : (
          <Image
            src={`${appConfig.apiUrl}/config/image/${identitas.user_detail?.image_identity}`}
            alt="No image"
            preview={false}
          />
        )}
      </Modal>
    </div>
  );
});
