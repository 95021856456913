import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  AppstoreOutlined,
  DatabaseOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  FileSyncOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  SlackOutlined,
  CodepenOutlined,
  WindowsOutlined,
  AliyunOutlined,
  SettingOutlined,
  IdcardOutlined,
  AppstoreAddOutlined,
  DollarCircleOutlined,
  PieChartOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { useStore } from "../../utils/useStore";
import { LINKS } from "../../routes/app";

const { SubMenu } = Menu;

export const MenuList = observer((props) => {
  const store = useStore();
  useEffect(() => {}, []);

  const [setKeys, setSetKeys] = useState(["dashboard"]);

  return (
    <Menu
      defaultOpenKeys={["sub4"]}
      theme="light"
      style={{
        backgroundColor: "transparent",
        borderRightWidth: 0,
        fontWeight: 400,
        paddingLeft: 0,
      }}
      onClick={({ keyPath, item }) => {
        props.closeLeftDrawer();
      }}
      mode="inline"
      selectedKeys={setKeys}
      onSelect={({ setKeys, item, selectedKeys }) => setSetKeys(selectedKeys)}
      overflowedIndicator={0}
      forceSubMenuRender={true}
    >
      {(store.authentication.userData.role === "Admin" ||
        store.authentication.userData.role === "Customer Service") && (
        <Menu.Item key="home">
          <Link to={LINKS.HOME}>
            <HomeOutlined />
            <span>Beranda</span>
          </Link>
        </Menu.Item>
      )}
      {store.authentication.userData.role === "Admin Partner" && (
        <Menu.Item key="home">
          <Link to={LINKS.HOME}>
            <HomeOutlined />
            <span>Beranda</span>
          </Link>
        </Menu.Item>
      )}
      {(store.authentication.userData.role === "Admin" ||
        store.authentication.userData.role === "Customer Service") && (
        <SubMenu
          key="keanggotaan"
          icon={<UsergroupAddOutlined />}
          title="Keanggotaan"
        >
          <Menu.Item key="membership">
            <Link to={LINKS.MEMBERSHIP}>
              <UnorderedListOutlined />
              <span>Daftar Anggota</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="konfirmasi">
            <Link to={LINKS.KONFIRMASI}>
              <FormOutlined />
              <span>Konfirm Retail</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      {store.authentication.userData.role === "Supervisor" && (
        <SubMenu
          key="keanggotaan"
          icon={<UsergroupAddOutlined />}
          title="Keanggotaan"
        >
          <Menu.Item key="membership">
            <Link to={LINKS.MEMBERSHIP}>
              <UnorderedListOutlined />
              <span>Daftar Anggota</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="konfirmasi">
            <Link to={LINKS.KONFIRMASI}>
              <FormOutlined />
              <span>Konfirm Retail</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      {/* {store.authentication.userData.role === "Sales" && (
          <SubMenu
            key="keanggotaan"
            icon={<UsergroupAddOutlined />}
            title="Keanggotaan"
          >
            <Menu.Item key="membership">
              <Link to={LINKS.MEMBERSHIP}>
                <UnorderedListOutlined />
                <span>Daftar Anggota</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        )} */}
      {/* {store.authentication.userData.role === "Supervisor" && (
        <Menu.Item key="membership">
          <Link to={LINKS.MEMBERSHIP}>
            <IdcardOutlined />
            <span>Keanggotaan</span>
          </Link>
        </Menu.Item>
      )} */}
      {store.authentication.userData.role === "Sales" && (
        <Menu.Item key="membership">
          <Link to={LINKS.MEMBERSHIP}>
            <IdcardOutlined />
            <span>Keanggotaan</span>
          </Link>
        </Menu.Item>
      )}
      {(store.authentication.userData.role === "Admin" ||
        store.authentication.userData.role === "Customer Service") && (
        <SubMenu key="config" icon={<SettingOutlined />} title="Config">
          <Menu.Item key="partner">
            <Link to={LINKS.PARTNER}>
              <WindowsOutlined />
              <span>Rekanan</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="commision">
            <Link to={LINKS.COMMISSION}>
              <SlackOutlined />
              <span>Komisi</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="supplier">
            <Link to={LINKS.SUPPLIER}>
              <AppstoreOutlined />
              <span>Supplier</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      {(store.authentication.userData.role === "Admin" ||
        store.authentication.userData.role === "Customer Service") && (
        <SubMenu
          key="product-main"
          icon={<AppstoreAddOutlined />}
          title="Produk"
        >
          <Menu.Item key="produk">
            <Link to={LINKS.PRODUCT}>
              <PieChartOutlined />
              <span>Produk</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="sub-category">
            <Link to={LINKS.SUBCATEGORY}>
              <FileSyncOutlined />
              <span>Sub Kategori</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="category">
            <Link to={LINKS.CATEGORY}>
              <FileAddOutlined />
              <span>Kategori</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      {store.authentication.userData.role === "Admin Partner" && (
        <Menu.Item key="retail">
          <Link to={LINKS.PRODUCT}>
            <PieChartOutlined />
            <span>Produk</span>
          </Link>
        </Menu.Item>
      )}
      {store.authentication.userData.role === "Sales" && (
        <Menu.Item key="retail">
          <Link to={LINKS.PRODUCT}>
            <PieChartOutlined />
            <span>Produk</span>
          </Link>
        </Menu.Item>
      )}
      {store.authentication.userData.role === "Supervisor" && (
        <Menu.Item key="retail">
          <Link to={LINKS.PRODUCT}>
            <PieChartOutlined />
            <span>Produk</span>
          </Link>
        </Menu.Item>
      )}
      {store.authentication.userData.role === "Retail" && (
        <Menu.Item key="transaction">
          <Link to={LINKS.TRANSACTION}>
            <ShoppingCartOutlined />
            <span>Transaksi</span>
          </Link>
        </Menu.Item>
      )}
      {store.authentication.userData.role !== "Admin Partner" && (
        <SubMenu
          key="payback-main"
          icon={<DollarCircleOutlined />}
          title="Pembayaran"
        >
          {store.authentication.userData.role !== "Retail" && (
            <Menu.Item key="payback-to-user">
              <Link to={LINKS.PAYBACK}>
                <FileProtectOutlined />
                <span>Konfirmasi</span>
              </Link>
            </Menu.Item>
          )}
          {store.authentication.userData.role !== "Admin" &&
            store.authentication.userData.role !== "Customer Service" && (
              <Menu.Item key="payback-from-user">
                <Link to={LINKS.PAYBACK_CREATED}>
                  <FileProtectOutlined />
                  <span>Dibuat oleh Saya</span>
                </Link>
              </Menu.Item>
            )}
        </SubMenu>
      )}
      {store.authentication.userData.role !== "Admin" && (
        <Menu.Item key="profile">
          <Link to={LINKS.PROFILE}>
            <UserOutlined />
            <span>Profil</span>
          </Link>
        </Menu.Item>
      )}
      <Menu.Divider style={{ background: "transparent", paddingTop: 15 }} />
    </Menu>
  );
});
