import React, { useState, useEffect, useContext } from "react";
import { Button, Drawer, Layout, Menu, Popover, Typography, Card } from "antd";
import { MenuList } from "./MenuList";
import { Link, useHistory } from "react-router-dom";
import { ModalLoaderContext } from "../../utils/modal";
import {
  AlipayOutlined,
  DatabaseOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  FileSyncOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  PayCircleOutlined,
  ProfileOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  IdcardOutlined,
  SettingOutlined,
  WindowsOutlined,
  SlackOutlined,
  AppstoreAddOutlined,
  PieChartOutlined,
  AppstoreOutlined,
  DollarCircleOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { AppRoute, LINKS } from "../../routes/app";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "react-responsive";

const { Text, Paragraph } = Typography;
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

export const DesktopLayout = observer(() => {
  const modalLoader = useContext(ModalLoaderContext);
  let history = useHistory();
  const xl = useMediaQuery({ minWidth: 1024 });
  const store = useStore();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    (async () => {
      modalLoader.setLoading(true);
      await Promise.allSettled([store.authentication.getProfile()]);
      modalLoader.setLoading(false);
    })();
  }, []);
  return (
    <Layout
      theme={"light"}
      className={"transparent"}
      hasSider={store.ui.mediaQuery.isDesktop}
      style={{
        paddingLeft: xl ? "calc(70vw - 1024px)" : "0",
        display: "flex",
        // minWidth: 1024,
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {store.ui.mediaQuery.isDesktop && (
        <Sider
          className={"transparent"}
          width={240}
          style={{
            overflowX: "hidden",
            bottom: 0,
            justifyContent: "flex-start",
            paddingTop: 20,
            paddingLeft: 20,
            position: "fixed",
            top: 0,
            zIndex: 10,
          }}
        >
          <div
            style={{
              paddingLeft: 20,
              marginBottom: 40,
            }}
          >
            <Paragraph
              style={{
                margin: 0,
                padding: 0,
                fontSize: 20,
                marginLeft: 5,
                fontWeight: 600,
                color: "#828282",
              }}
            >
              PPOB
            </Paragraph>
            <Paragraph
              style={{
                margin: 0,
                padding: 0,
                fontSize: 11,
                marginLeft: 5,
                fontWeight: 600,
                color: "#413d3e",
              }}
            >
              {store.authentication.userData.role}
            </Paragraph>
          </div>
          <MenuList closeLeftDrawer={() => {}} />
        </Sider>
      )}

      {store.ui.mediaQuery.isMobile && (
        <Drawer
          title={`PPOB ${store.authentication.userData.role}`}
          placement={"left"}
          closable={false}
          width={"50%"}
          onClose={() => {
            store.ui.toggleLeftDrawerIsShown();
          }}
          visible={store.ui.leftDrawerIsShown}
          key={"dashboard-drawer"}
          bodyStyle={{
            padding: 0,
            paddingTop: 20,
          }}
        >
          <div
            style={{
              marginLeft: 0,
              paddingLeft: 0,
            }}
          >
            <Menu>
              {(store.authentication.userData.role === "Admin" ||
                store.authentication.userData.role === "Customer Service") && (
                <Menu.Item key="home">
                  <Link to={LINKS.HOME}>
                    <HomeOutlined />
                    <span>Beranda</span>
                  </Link>
                </Menu.Item>
              )}
              {store.authentication.userData.role === "Admin Partner" && (
                <Menu.Item key="home">
                  <Link to={LINKS.HOME}>
                    <HomeOutlined />
                    <span>Beranda</span>
                  </Link>
                </Menu.Item>
              )}
              {(store.authentication.userData.role === "Admin" ||
                store.authentication.userData.role === "Customer Service") && (
                <SubMenu
                  key="keanggotaan"
                  icon={<UsergroupAddOutlined />}
                  title="Keanggotaan"
                >
                  <Menu.Item key="membership">
                    <Link to={LINKS.MEMBERSHIP}>
                      <UnorderedListOutlined />
                      <span>Daftar Anggota</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="konfirmasi">
                    <Link to={LINKS.KONFIRMASI}>
                      <FormOutlined />
                      <span>Konfirmasi Retail</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {store.authentication.userData.role === "Supervisor" && (
                <SubMenu
                  key="keanggotaan"
                  icon={<UsergroupAddOutlined />}
                  title="Keanggotaan"
                >
                  <Menu.Item key="membership">
                    <Link to={LINKS.MEMBERSHIP}>
                      <UnorderedListOutlined />
                      <span>Daftar Anggota</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="konfirmasi">
                    <Link to={LINKS.KONFIRMASI}>
                      <FormOutlined />
                      <span>Konfirmasi Retail</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {store.authentication.userData.role === "Sales" && (
                <Menu.Item key="membership">
                  <Link to={LINKS.MEMBERSHIP}>
                    <IdcardOutlined />
                    <span>Keanggotaan</span>
                  </Link>
                </Menu.Item>
              )}
              {/* {store.authentication.userData.role === "Supervisor" && (
                <Menu.Item key="membership">
                  <Link to={LINKS.MEMBERSHIP}>
                    <IdcardOutlined />
                    <span>Keanggotaan</span>
                  </Link>
                </Menu.Item>
              )} */}
              {(store.authentication.userData.role === "Admin" ||
                store.authentication.userData.role === "Customer Service") && (
                <SubMenu
                  key="config"
                  icon={<SettingOutlined />}
                  title="Config"
                  style={{ backgroundColor: "#e3e8ee" }}
                >
                  <Menu.Item key="partner">
                    <Link to={LINKS.PARTNER}>
                      <WindowsOutlined />
                      <span>Rekanan</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="commision">
                    <Link to={LINKS.COMMISSION}>
                      <SlackOutlined />
                      <span>Komisi</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="supplier">
                    <Link to={LINKS.SUPPLIER}>
                      <AppstoreOutlined />
                      <span>Supplier</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {(store.authentication.userData.role === "Admin" ||
                store.authentication.userData.role === "Customer Service") && (
                <SubMenu
                  key="product-main"
                  icon={<PieChartOutlined />}
                  title="Product"
                >
                  <Menu.Item key="product">
                    <Link to={LINKS.PRODUCT}>
                      <PieChartOutlined />
                      <span>Produk</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sub-category">
                    <Link to={LINKS.SUBCATEGORY}>
                      <FileSyncOutlined />
                      <span>Sub Kategori</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="category">
                    <Link to={LINKS.CATEGORY}>
                      <FileAddOutlined />
                      <span>Kategori</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {store.authentication.userData.role === "Supervisor" && (
                <Menu.Item key="product">
                  <Link to={LINKS.PRODUCT}>
                    <PieChartOutlined />
                    <span>Produk</span>
                  </Link>
                </Menu.Item>
              )}
              {store.authentication.userData.role === "Admin Partner" && (
                <Menu.Item key="product">
                  <Link to={LINKS.PRODUCT}>
                    <PieChartOutlined />
                    <span>Produk</span>
                  </Link>
                </Menu.Item>
              )}
              {store.authentication.userData.role === "Sales" && (
                <Menu.Item key="product">
                  <Link to={LINKS.PRODUCT}>
                    <PieChartOutlined />
                    <span>Produk</span>
                  </Link>
                </Menu.Item>
              )}
              {/* {store.authentication.userData.role === "Admin Partner" && (
                <Menu.Item key="transaction">
                  <Link to={LINKS.TRANSACTION}>
                    <ShoppingCartOutlined />
                    <span>Transaksi</span>
                  </Link>
                </Menu.Item>
              )} */}
              {store.authentication.userData.role === "Retail" && (
                <Menu.Item key="transaction">
                  <Link to={LINKS.TRANSACTION}>
                    <ShoppingCartOutlined />
                    <span>Transaksi</span>
                  </Link>
                </Menu.Item>
              )}
              {store.authentication.userData.role !== "Admin Partner" && (
                <SubMenu
                  key="payback-main"
                  icon={<DollarCircleOutlined />}
                  title="Pembayaran"
                >
                  {store.authentication.userData.role !== "Retail" && (
                    <Menu.Item key="payback-to-user">
                      <Link to={LINKS.PAYBACK}>
                        <PayCircleOutlined />
                        <span>Konfirmasi</span>
                      </Link>
                    </Menu.Item>
                  )}
                  {store.authentication.userData.role !== "Admin" &&
                    store.authentication.userData.role !==
                      "Customer Service" && (
                      <Menu.Item key="payback-from-user">
                        <Link to={LINKS.PAYBACK_CREATED}>
                          <AlipayOutlined />
                          <span>Dibuat oleh Saya</span>
                        </Link>
                      </Menu.Item>
                    )}
                </SubMenu>
              )}
              {store.authentication.userData.role !== "Admin" && (
                <Menu.Item key="profile">
                  <Link to={LINKS.PROFILE}>
                    <UserOutlined />
                    <span>Profil</span>
                  </Link>
                </Menu.Item>
              )}
              <Menu.Divider
                style={{ background: "transparent", paddingTop: 15 }}
              />
            </Menu>
          </div>
        </Drawer>
      )}

      <Layout
        className={"transparent"}
        style={{
          position: "relative",
          display: "block",
          paddingLeft: store.ui.mediaQuery.isDesktop ? 200 : 0,
          height: "100vh",
        }}
      >
        <Header
          theme={"light"}
          className={store.ui.mediaQuery.isMobile ? "shadow" : null}
          style={{
            height: store.ui.mediaQuery.isDesktop ? 24 : 35,
            // width: 'calc(100vw - 200px)',
            width: "100%",
            position: store.ui.mediaQuery.isDesktop ? "fixed" : "fixed",
            zIndex: 99,
            paddingLeft: store.ui.mediaQuery.isDesktop ? 14 : 0,
            paddingRight: store.ui.mediaQuery.isDesktop ? 14 : 0,
            backgroundColor: store.ui.mediaQuery.isMobile
              ? "#fff"
              : "transparent",
            maxWidth: store.ui.mediaQuery.isDesktop ? 1024 : 768,
            // minWidth: store.ui.mediaQuery.isDesktop ? 768 : 0,
            top: store.ui.mediaQuery.isMobile ? 0 : 12,
            /**/
          }}
        >
          {store.ui.mediaQuery.isMobile && (
            <div
              style={{
                top: 0,
                left: 0,
                paddingTop: 8,
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                flexDirection: "column",
                // boxShadow: '0 7px 14px 0 rgba(60, 66, 87, 0.05), 0 3px 6px 0 rgba(0, 0, 0, 0.05)'
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  paddingLeft: store.ui.mediaQuery.isMobile ? 10 : 20,
                  paddingRight: store.ui.mediaQuery.isMobile ? 0 : 20,

                  marginTop: -20,
                }}
              >
                <Button
                  type="link"
                  icon={
                    <MenuOutlined
                      style={{
                        fontSize: "12px",
                        color: "#5b5b5b",
                        marginLeft: -10,
                      }}
                    />
                  }
                  onClick={() => {
                    store.ui.toggleLeftDrawerIsShown();
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    padding: 0,
                    margin: 0,
                    paddingTop: 5,
                  }}
                >
                  {/* <img
                    className={[classes.logoFull]}
                    style={{
                      maxHeight: 18,
                      maxWidth: 75,
                    }}
                    src={parkirLogoFull}
                  /> */}
                  {/* <Paragraph
                    style={{
                     
                      padding: 0,
                      lineHeight: "18px",
                      fontSize: 8,
                      fontWeight: 800,
                      color: "#8c8c8c",
                     
                    }}
                  >
                    {store.authentication.userData.role || "Apps"}
                  </Paragraph> */}
                </div>

                <Popover
                  className={store.ui.mediaQuery.isDesktop ? "shadow" : null}
                  autoAdjustOverflow={true}
                  placement="bottomRight"
                  content={
                    <Menu
                      type={"line"}
                      inlineIndent={0}
                      theme="light"
                      style={{
                        backgroundColor: "transparent",
                        borderRightWidth: 0,
                      }}
                      mode="inline"
                    >
                      {store.authentication.userData.role !== "Admin" && (
                        <Menu.Item>
                          <Link to={LINKS.PROFILE}>
                            <UserOutlined />
                            <span>Profile</span>
                          </Link>
                        </Menu.Item>
                      )}
                      <Menu.Item
                        onClick={() => {
                          store.authentication.logout();
                          history.push("/login");
                        }}
                      >
                        <LogoutOutlined />
                        <span>Sign out</span>
                      </Menu.Item>
                    </Menu>
                  }
                  title={
                    <Text>
                      {store.user.data.username}
                      <Paragraph
                        style={{ fontWeight: 400, marginTop: "0.5rem" }}
                        type={"secondary-dark"}
                        strong
                      >
                        {store.authentication.userData.username}
                      </Paragraph>
                    </Text>
                  }
                  trigger="click"
                  visible={clicked}
                  onVisibleChange={() => setClicked(!clicked)}
                >
                  {store.authentication.userData.role !== "Admin" &&
                    store.authentication.userData.role !== "Customer Service" &&
                    new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    }).format(store.authentication.profileData?.wallet || 0)}

                  <Button
                    size={"default"}
                    type={store.ui.mediaQuery.isDesktop ? "" : "link"}
                    title="tes"
                    style={{
                      marginRight: store.ui.mediaQuery.isDesktop ? 20 : 10,
                    }}
                    icon={
                      store.ui.mediaQuery.isDesktop ? (
                        <UserOutlined style={{ fontSize: "13px" }} />
                      ) : (
                        <UserOutlined
                          style={{ fontSize: "13px", color: "#5b5b5b" }}
                        />
                      )
                    }
                  />
                </Popover>
              </div>
              <div
                style={{
                  borderTopWidth: 0.5,
                  borderTopColor: "#e3e3e3",
                  borderTopStyle: "solid",
                }}
              />
              {/*<BreadcumbComponent data={}/>*/}
            </div>
          )}

          {store.ui.mediaQuery.isDesktop && (
            <div
              className={["transparent"].join(" ")}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
                maxWidth: 1024,
                minWidth: 768,
                width: "calc(100vw - 220px)",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
              ></div>
              {store.authentication.userData.role !== "Admin" &&
                store.authentication.userData.role !== "Customer Service" && (
                  <Card
                    size="small"
                    title={
                      <span
                        style={{
                          fontStyle: "bold",
                          textAlign: "center",
                        }}
                      >
                        Saldo :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.authentication.profileData?.wallet || 0
                        )}
                      </span>
                    }
                    style={{
                      marginRight: 10,
                      width: 210,
                      height: 31,
                    }}
                  ></Card>
                )}
              <Popover
                className={store.ui.mediaQuery.isDesktop ? "shadow" : null}
                autoAdjustOverflow={true}
                placement="bottomRight"
                content={
                  <Menu
                    type={"line"}
                    inlineIndent={0}
                    theme="light"
                    style={{
                      backgroundColor: "transparent",
                      borderRightWidth: 0,
                    }}
                    mode="inline"
                  >
                    {store.authentication.userData.role !== "Admin" && (
                      <Menu.Item key="profile">
                        <Link to={LINKS.PROFILE}>
                          <UserOutlined />
                          <span>Profile</span>
                        </Link>
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key={"logout"}
                      onClick={() => {
                        store.authentication.logout();
                        history.push("/login");
                      }}
                    >
                      <LogoutOutlined />
                      <span>Sign out</span>
                    </Menu.Item>
                  </Menu>
                }
                title={
                  <Text>
                    <Paragraph
                      style={{ fontWeight: 400, marginTop: "0.5rem" }}
                      type={"secondary-dark"}
                      strong
                    >
                      {store.authentication.userData.username}
                    </Paragraph>
                  </Text>
                }
                trigger="click"
                visible={clicked}
                onVisibleChange={() => setClicked(!clicked)}
              >
                <Button
                  size={"default"}
                  type={store.ui.mediaQuery.isDesktop ? "" : "link"}
                  style={{
                    marginRight: store.ui.mediaQuery.isDesktop ? 30 : 10,
                    width: 50,
                  }}
                  icon={
                    store.ui.mediaQuery.isDesktop ? (
                      <UserOutlined style={{ fontSize: "15px" }} />
                    ) : (
                      <UserOutlined
                        style={{ fontSize: "13px", color: "#5b5b5b" }}
                      />
                    )
                  }
                />
              </Popover>
            </div>
          )}
        </Header>

        <Layout
          className={["transparent"].join(" ")}
          tabIndex={"-1"}
          style={{
            zIndex: 0,
            display: "flex",
            // overflowX: store.ui.mediaQuery.isMobile ? 'auto' : "hidden",
            // paddingLeft: 8,
            // paddingRight: 8,
            //  width: 'calc(90vw - 220px)',
            height: "100%",
          }}
        >
          <Content
            className={["transparent"].join(" ")}
            style={{
              // maxHeight: 'calc(100vh - 98px)',
              // paddingBottom: 48,
              padding: 0,
              margin: 0,
              position: "relative",
              marginTop: store.ui.mediaQuery.isDesktop ? 43 : 37,
              // overflow: 'auto',
              overflowX: store.ui.mediaQuery.isMobile ? "auto" : "hidden",
              // minWidth: 768,
              maxWidth: 1024,
              // minWidth: 768,
              width: store.ui.mediaQuery.isDesktop
                ? "calc(100vw - 210px)"
                : "calc(100vw)",
              zIndex: 22,
              // height: `calc(100vh - ${store.ui.mediaQuery.isDesktop ? 78 : 71}px)`,
              height: `calc(100vh - ${
                store.ui.mediaQuery.isDesktop ? 43 : 37
              }px)`,
              // paddingLeft: 8,
              // paddingRight: 8
            }}
          >
            <AppRoute />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
});
