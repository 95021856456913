import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useStore } from "../utils/useStore";
import { LINKS } from "../routes/app";
import { ModalLoaderContext } from "../utils/modal";

const { Title, Text } = Typography;

export const ProductComponent = observer((props) => {
  const store = useStore();
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();
  const [idData, setIdData] = useState("");
  const [filterSupplier, setFilterSupplier] = useState([]);
  const [filterSubCategories, setFilterSubCategories] = useState([]);
  const modalLoader = useContext(ModalLoaderContext);
  const role = store.authentication.userData.role

  const handleEditButton = (data) => {
    console.log(data, "isi data");
    form.setFieldsValue({
      name: data.name,
      price: data.price,
      markUpPrice: data.basePrice,
      code: data.code,
      status: data.status,
      subCategoriesId: data.sub_categories.id,
    });
    store.product.visibleModalProduct = true;
    setIdData(data.id);
  };

  const columns = [
    {
      title: "Kode",
      dataIndex: "product_code",
      key: "product_code",
    },
    {
      title: "Produk",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Harga Beli",
      dataIndex: "price",
      key: "price",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Harga Jual",
      dataIndex: "mark_up_price",
      key: "mark_up_price",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Harga",
      dataIndex: "price",
      key: "price",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Biaya Admin",
      dataIndex: "admin_price",
      key: "admin_price",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Fee Jual",
      dataIndex: "partner_fee",
      key: "partner_fee",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    {
      title: "Sub Kategori",
      dataIndex: "sub_categories_name",
      key: "sub_categories_name",
    },
    {
      title: "Tersedia",
      dataIndex: "tersedia",
      key: "tersedia",
      render: (text, record) => (
        
        <Tag
          color={record?.status === "ACTIVE" ? "blue" : "#E3E8EE"}
          style={{ color: "#4F566B" }}
        >
          {record?.status === "ACTIVE" ? " Tersedia" : "Tidak"}
        </Tag>
      ),
    },
    {
      title: "Tindakan",
      key: "action",
      fixed: 'right',
      render: (text, record) => (
        <Button
          onClick={async () => {
            history.push(
              LINKS.PRODUCT_DETAIL.replace(":id", record.product_id)
            );
          }}
        >
          Detail
        </Button>
      ),
    },
  ];
  //if (store.authentication.userData.role === "Admin Partner") delete columns[2];
  if (store.authentication.userData.role === "Admin Partner") delete columns[10];
  if (store.authentication.userData.role === "Admin Partner") delete columns[3];
  if (store.authentication.userData.role !== "Admin Partner") delete columns[4];
  if (store.authentication.userData.role !== "Admin") delete columns[9];
  if (store.authentication.userData.role !== "Admin") delete columns[6];
  if (store.authentication.userData.role !== "Admin") delete columns[7];
  if (store.authentication.userData.role !== "Admin") delete columns[2];
  if (store.authentication.userData.role === "Admin Partner") delete columns[8];
  //if (store.authentication.userData.role !== "Admin") delete columns[8];
  //if (store.authentication.userData.role === "Admin Partner") delete columns[5];

  const deleteData = async (id) => {
    try {
      console.log(id);
      await store.product.delete(id);
      message.success("Data Berhasil Dihapus");
      history.push(LINKS.PRODUCT);
    } catch (err) {
      console.log("error", err);
      message.error("Gagal menghapus");
    }
  };
  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure delete this record?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "Batal",
      onOk() {
        return deleteData(id);
      },
      onCancel() {
        console.log("Batal");
      },
    });
  };

  const handleCancel = () => {
    setIdData("");
    store.product.visibleModalProduct = false;
  };

  const handleSubmit = async (data) => {
    console.log(data, "isi data2");
    if (idData !== "") {
      modalLoader.setLoading(true);
      try {
        const response = await store.product.update(idData, data);
        response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(
            response?.body?.message || "Berhasil Ubah Data Produk"
          )
          : message.error(response?.body?.message || "Gagal Ubah Data Produk");
      } catch (e) {
        message.error(e.response?.body?.message || "Gagal Ubah Data Produk");
      }
      modalLoader.setLoading(false);
      store.product.visibleModalProduct = false;
      setIdData("");
      form.resetFields();
    } else {
      modalLoader.setLoading(true);
      try {
        const response = await store.product.create(data);
        response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(response?.body?.message || "Berhasil Tambah Produk")
          : message.error(response?.body?.message || "Gagal Tambah Produk");
      } catch (e) {
        console.log(e, "apa errornya");
        message.error(e.response?.body?.message || "Gagal Tambah Produk");
      }
      modalLoader.setLoading(false);
      store.product.visibleModalProduct = false;
      setIdData("");
      form.resetFields();
    }
  };

  const handleRemoveFilter = async () => {
    store.product.pageProductPartner = 0
    store.product.visibleModalFilterProduct = false;
    store.product.filterSupplier = null;
    store.product.filterSubCategory = null;
    setFilterSupplier([]);
    setFilterSubCategories([]);
    store.authentication.userData.role === "Admin Partner"
      ? await store.product.getProductPartner()
      : await store.product.getData();
    //await store.product.getData();
    modalLoader.setLoading(false);
  };

  const handleCancelFilter = async () => {
    store.product.visibleModalFilterProduct = false;
    //await store.product.getData();
  };

  const handleSubmitFilter = async () => {
    store.product.pageProductPartner = 0
    store.product.visibleModalFilterProduct = false;
    store.product.filterSupplier = filterSupplier;
    store.product.filterSubCategory = filterSubCategories;
    modalLoader.setLoading(true);
    store.authentication.userData.role === "Admin Partner"
      ? await store.product.getProductPartner(filterSubCategories)
      : await store.product.getData();
    //await store.product.getData();
    modalLoader.setLoading(false);
    //store.product.page=1
  };

  const footerLayoutFilter = [
    <Button
      key={"remove"}
      onClick={handleRemoveFilter}
      style={{
        backgroundColor: "#e74e5e",
        color: "#fff",
      }}
    >
      Hapus Filter
    </Button>,
    <Button key={"cancel"} onClick={handleCancelFilter}>
      Batal
    </Button>,
    <Button
      key={"submit"}
      onClick={handleSubmitFilter}
      style={{
        backgroundColor: "#4e79e7",
        color: "#fff",
      }}
    >
      Terapkan
    </Button>,
  ];

  const handleClickRow = (record, index) => ({
    onClick: (event) => {
      history.push(LINKS.PRODUCT_DETAIL.replace(":id", record.product_id))
    },
  });
  return (
    <div>
      {store.ui.mediaQuery.isDesktop && (
        <Table
          style={{
            cursor: "pointer",
            textAlign: "center",
            width: store.ui.mediaQuery.isMobile ? 250 : "",
          }}
          scroll={role === "Admin Partner" ? false : { x: 1300 }}
          columns={columns}
          dataSource={
            store.authentication.userData.role === "Admin Partner"
              ? store.product.dataProductPartner
              : store.product.data
          }
          onRow={role === "Admin Partner" ? false : handleClickRow}
          pagination={{
            pageSize: store.authentication.userData.role === "Admin" ? store.product.pageSize : store.product.pageSizeProductPartner,
            total:
              store.authentication.userData.role === "Admin Partner"
                ? store.product.total_data_partner
                : store.product.total_data,
            current: store.authentication.userData.role === "Admin" ? store.product.page + 1 : store.product.pageProductPartner + 1,
            showSizeChanger: true,
            simple: false,
          }}
          onChange={async (page) => {
            let pageNumber = page.current;
            store.authentication.userData.role === "Admin" ? store.product.pageSize = page.pageSize : store.product.pageSizeProductPartner = page.pageSize;
            store.authentication.userData.role === "Admin" ? store.product.page = pageNumber - 1 : store.product.pageProductPartner = pageNumber - 1
            store.product.filterSubCategory = filterSubCategories
            modalLoader.setLoading(true);
            store.authentication.userData.role === "Admin Partner"
              ? await store.product.getProductPartner(
                filterSubCategories
              )
              : await store.product.getData();
            modalLoader.setLoading(false);
          }}
        />
      )}
      {store.ui.mediaQuery.isMobile && (
        <List
          itemLayout="horizontal"
          position={"top"}
          pagination={{
            onChange: async (page, pageSize) => {
              store.authentication.userData.role === "Admin" ? store.product.pageSize = pageSize : store.product.pageSizeProductPartner = pageSize;
              store.authentication.userData.role === "Admin" ? store.product.page = page - 1 : store.product.pageProductPartner = page - 1
              modalLoader.setLoading(true);
              store.authentication.userData.role === "Admin Partner"
                ? await store.product.getProductPartner(
                  filterSubCategories
                )
                : await store.product.getData();
              //await store.product.getData();
              modalLoader.setLoading(false);
            },
            pageSize: store.authentication.userData.role === "Admin" ? store.product.pageSize : store.product.pageSizeProductPartner,
            total: store.authentication.userData.role === "Admin Partner"
              ? store.product.total_data_partner
              : store.product.total_data,
            current: store.authentication.userData.role === "Admin" ? store.product.page + 1 : store.product.pageProductPartner + 1,
            style: { marginBottom: "1rem", marginRight: "1rem" },
          }}
          dataSource={
            store.authentication.userData.role === "Admin Partner"
              ? store.product.dataProductPartner
              : store.product.data
          }
          style={{ padding: 0 }}
          renderItem={(item) => {
            return (
              <div>
                <List.Item
                  key={item.id}
                  style={{
                    backgroundColor: "#ffffff",
                    paddingTop: 0,
                    paddingBottom: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <List.Item.Meta
                    className={[""].join(" ")}
                    title={item.product_code}
                    description={
                      <div style={{}}>
                        <p>
                          <small>{item.product_name}</small> <br />
                          {/* <small>Harga Beli : {item.current_price_price}</small>
                          <br /> */}
                          <small>Harga Jual : {item.mark_up_price}</small>
                          <br />
                          <Button
                            onClick={async () => {
                              history.push(
                                LINKS.PRODUCT_DETAIL.replace(
                                  ":id",
                                  item.product_id
                                )
                              );
                            }}
                          >
                            Detail
                          </Button>
                        </p>
                        <p></p>
                      </div>
                    }
                  />
                  <div style={{ marginRight: 16 }}>
                    <p
                      style={{
                        fontSize: 9,
                        margin: 0,
                      }}
                    >
                      <Tag
                        color={
                          item?.status === "ACTIVE"  ? "blue" : "#E3E8EE"
                        }
                        style={{ color: "#4F566B" }}
                      >
                        {item?.status === "ACTIVE" 
                          ? " Tersedia"
                          : "Tidak"}
                      </Tag>
                    </p>
                  </div>
                </List.Item>
                <Divider plain style={{ margin: 0 }} />
              </div>
            );
          }}
        />
      )}
      <Modal
        visible={store.product.visibleModalProduct}
        title={idData ? "Edit Product" : "Create a new Product"}
        okText={idData ? "Edit" : "Create"}
        cancelText="Batal"
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values, "isi form");
              handleSubmit(values);
              form.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Please input price!" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\Rp.\s?|(,*)/g, "")}
            />
          </Form.Item>
          <Form.Item
            name="markUpPrice"
            label="Mark Up Price"
            rules={[{ required: true, message: "Please input mark up price!" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\Rp.\s?|(,*)/g, "")}
            />
          </Form.Item>
          <Form.Item
            name="code"
            label="Code"
            rules={[{ required: true, message: "Please input code!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: "Please select Status!" }]}
          >
            <Select placeholder="Select Sub Category" allowClear>
              <Option value="ACTIVE">AKTIF</Option>
              <Option value="INACTIVE">INAKTIF</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="subCategoriesId"
            label="Sub categories"
            rules={[
              { required: true, message: "Please select sub categories!" },
            ]}
          >
            <Select placeholder="Select Sub Category" allowClear>
              {store.category.dataSubCategories.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={store.product.visibleModalFilterProduct}
        title={"Filter"}
        footer={footerLayoutFilter}
        onCancel={async () => {
          // setFilterSupplier([]);
          // setFilterSubCategories([]);
          store.product.filterSupplier = null;
          store.product.filterSubCategory = null;
          store.product.visibleModalFilterProduct = false;
          await store.product.getData();
        }}
      >
        <Row>
          {store.authentication.userData.role === "Admin" && (
            <Col span={24}>
              <Title level={5} type={"secondary"} strong>
                Filter Supplier
              </Title>
              <Select
                mode={"multiple"}
                placeholder="Choose Supplier"
                onChange={(val) => {
                  setFilterSupplier(val);
                }}
                style={{ marginBottom: "20px", width: "100%" }}
                value={filterSupplier}
              >
                {store.supplier.data.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Col>
          )}
          <Col span={24}>
            <Title level={5} type={"secondary"} strong>
              Filter Sub-Categories
            </Title>
            <Select
              mode={"multiple"}
              placeholder="Choose Sub-Category"
              onChange={(val) => {
                setFilterSubCategories(val);
              }}
              style={{ marginBottom: "20px", width: "100%" }}
              value={filterSubCategories}
            >
              {store.product.dataSubCategories.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
