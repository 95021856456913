import React, { useContext, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Space,
  Table,
  Tag,
} from "antd";
import { observer } from "mobx-react-lite";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useStore } from "../utils/useStore";
import { LINKS } from "../routes/app";
import { ModalLoaderContext } from "../utils/modal";

export const PartnerComponent = observer((props) => {
  const store = useStore();
  const [value, setValue] = useState();
  const [form] = Form.useForm();
  const history = useHistory();
  const [idData, setIdData] = useState("");
  const [isChangePassword, setIsChangePassword] = useState(false);
  const modalLoader = useContext(ModalLoaderContext);

  const changeStatus = async (id, isActive) => {
    const status = isActive ? "inactive" : "active";
    const status2 = isActive ? "Inactivating" : "Activating";
    modalLoader.setLoading(true);
    try {
      const response = await store.partner.changeStatus(id, status);
      modalLoader.setLoading(false);
      response?.body?.statusCode === 201
        ? message.success(`Success ${status2} Partner`)
        : message.error(`Failed ${status2} Partner`);
    } catch (err) {
      console.log("error", err);
      message.error(`Failed ${status2} Partner`);
    }
  };

  const handleEditButton = (data) => {
    console.log(data, "isi data");
    form.setFieldsValue({
      name: data.name,
      npwp: data.npwp,
      address: data.address,
      code: data.code,
    });
    setIsChangePassword(false);
    store.partner.visibleModalPartner = true;
    setIdData(data.id);
  };

  const changePassword = (data) => {
    // form.setFieldsValue({
    //   name: data.name,
    //   npwp: data.npwp,
    //   address: data.address,

    // });
    setIsChangePassword(true);
    store.partner.visibleModalPartner = true;
    setIdData(data.id);
    console.log(data.id);
  };

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Npwp",
      dataIndex: "npwp",
      key: "npwp",
    },
    {
      title: "Alamat",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          color={record?.status === true ? "processing" : "#E3E8EE"}
          style={{ color: "#4F566B", cursor: "pointer" }}
        >
          {record?.status === true ? " ACTIVE" : "INACTIVE"}
        </Tag>
      ),
    },
    {
      title: "Tindakan",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type={record?.status === true ? "danger" : "primary"}
            onClick={() => changeStatus(record?.id, record?.status)}
          >
            {record?.status === true ? "Inactive" : "Active"}
          </Button>
          <Button onClick={() => handleEditButton(record)}>Edit</Button>
          {/* <Button onClick={() => handleDelete(record.id)}>Delete</Button> */}
          <Button onClick={() => changePassword(record)}>
            Change Password
          </Button>
        </Space>
      ),
    },
  ];

  if (store.authentication.userData.role === "Customer Service") columns.pop();

  const deleteData = async (id) => {
    try {
      modalLoader.setLoading(true);
      await store.partner.delete(id);
      modalLoader.setLoading(false);
      message.success("Data Berhasil Dihapus");
      history.push(LINKS.PARTNER);
    } catch (err) {
      console.log("error", err);
      message.error("Gagal menghapus");
    }
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure delete this record?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "Batal",
      onOk() {
        return deleteData(id);
      },
      onCancel() {
        console.log("Batal");
      },
    });
  };

  const handleCancel = () => {
    setIdData("");
    store.partner.visibleModalPartner = false;
  };

  const handleSubmit = async (data) => {
    console.log(data, "isi data2");
    if (idData !== "") {
      modalLoader.setLoading(true);
      try {
        isChangePassword
          ? await store.partner.updatePassword(idData, data)
          : await store.partner.update(idData, data);
        modalLoader.setLoading(false);
        message.success(
          isChangePassword
            ? "Success Change Password"
            : "Success Update Data Partner"
        );

        //await store.partner.getData()
      } catch (e) {
        modalLoader.setLoading(false);
        message.error(
          isChangePassword
            ? "Failed Change Password"
            : "Failed Update Data Partner"
        );
      }
      store.partner.visibleModalPartner = false;
      setIdData("");
      form.resetFields();
    } else {
      modalLoader.setLoading(true);
      try {
        const response = await store.partner.create(data);
        modalLoader.setLoading(false);
        response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(
              response?.body?.message || "Berhasil Tambah Partner"
            )
          : message.error(response?.body?.message || "Gagal Tambah Partner");
      } catch (e) {
        console.log(e, "apa errornya");
        message.error(e.response?.body?.message || "Gagal Tambah Partner");
      }
      modalLoader.setLoading(false);
      store.partner.visibleModalPartner = false;
      setIdData("");
      form.resetFields();
    }
  };
  return (
    <div>
      {store.ui.mediaQuery.isDesktop && (
        <Table
          style={{ textAlign: "center" }}
          columns={columns}
          dataSource={store.partner.data}
          bordered
          pagination={{
            pageSize: store.partner.pageSize,
            total: store.partner.total_data,
            current: store.partner.page + 1,
            showSizeChanger: true,
            simple: false,
          }}
          onChange={async (page) => {
            let pageNumber = page.current;
            store.partner.pageSize = page.pageSize;
            store.partner.page = pageNumber - 1;
            modalLoader.setLoading(true);
            await store.partner.getData();
            modalLoader.setLoading(false);
          }}
        />
      )}
      {store.ui.mediaQuery.isMobile && (
        <List
          itemLayout="horizontal"
          position={"top"}
          pagination={{
            onChange: async (page, pageSize) => {
              store.partner.pageSize = pageSize;
              store.partner.page = page - 1;
              modalLoader.setLoading(true);
              await store.partner.getData();
              modalLoader.setLoading(false);
            },
            pageSize: store.partner.pageSize,
            total: store.partner.total_data,
            current: store.partner.page + 1,
            style: { marginBottom: "1rem", marginRight: "1rem" },
          }}
          dataSource={store.partner.data}
          style={{ padding: 0 }}
          renderItem={(item) => {
            return (
              <div>
                <List.Item
                  key={item.id}
                  style={{
                    backgroundColor: "#ffffff",
                    paddingTop: 0,
                    paddingBottom: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <List.Item.Meta
                    className={[""].join(" ")}
                    title={item.code}
                    description={
                      <div style={{}}>
                        <p>
                          <div style={{ marginBottom: 10 }}>
                            <small>Nama : {item.name}</small> <br />
                            <small>Npwp : {item.npwp}</small> <br />
                            <small>Alamat : {item.address}</small>
                          </div>
                          <Space size="small">
                            <Button
                              type={
                                item?.status === true ? "danger" : "primary"
                              }
                              onClick={() =>
                                changeStatus(item?.id, item?.status)
                              }
                            >
                              {item?.status === true ? "Inactive" : "Active"}
                            </Button>
                            <Button
                              onClick={(owner, name) => handleEditButton(item)}
                            >
                              Edit
                            </Button>
                            {/* <Button onClick={() => handleDelete(record.id)}>Delete</Button> */}
                            <Button onClick={() => changePassword(item)}>
                              Change Password
                            </Button>
                          </Space>
                        </p>
                        <p></p>
                      </div>
                    }
                  />
                  <div style={{ marginRight: 16 }}>
                    <p
                      style={{
                        fontSize: 9,
                        marginBottom: 40,
                      }}
                    >
                      <Tag
                        color={item?.status === true ? "processing" : "#E3E8EE"}
                        style={{ color: "#4F566B", cursor: "pointer" }}
                      >
                        {item?.status === true ? " ACTIVE" : "INACTIVE"}
                      </Tag>
                    </p>
                  </div>
                </List.Item>
                <Divider plain style={{ margin: 0 }} />
              </div>
            );
          }}
        />
      )}
      <Modal
        visible={store.partner.visibleModalPartner}
        title={
          isChangePassword
            ? "Ganti Password Rekanan"
            : idData
            ? "Edit Rekanan"
            : "Buat Rekanan Baru"
        }
        okText={idData ? "Edit" : "Create"}
        cancelText="Batal"
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values, "isi form");
              handleSubmit(values);
              form.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          {!isChangePassword && (
            <Form.Item
              name="name"
              label="Name"
              rules={[
                idData
                  ? { required: false }
                  : { required: true, message: "Please input name!" },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {!idData && (
            <Form.Item
              name="code"
              label="Kode"
              rules={[
                idData
                  ? { required: false }
                  : { required: true, message: "Please input code partner!" },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {!idData && (
            <Form.Item
              name="owner"
              label="Owner"
              rules={[
                idData
                  ? { required: false }
                  : { required: true, message: "Please input owner name!" },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {((idData && isChangePassword) || !idData) && (
            <Form.Item
              name="password_account"
              label="Password Account"
              rules={[
                idData
                  ? { required: false }
                  : {
                      required: true,
                      message: "Please input password account!",
                    },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          {!idData && (
            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[
                idData
                  ? { required: false }
                  : {
                      required: true,
                      message: "Please input password phone number!",
                    },
                {
                  pattern: /^(?:\d*)$/,
                  message: "Phone number should contain just number",
                },
                {
                  pattern: /^[\d]{10,12}$/,
                  message: "Phone number should be 10 - 12 character",
                },
              ]}
            >
              <Input
                onChange={(value) => {
                  setValue(value);
                }}
              />
            </Form.Item>
          )}
          {!isChangePassword && (
            <>
              <Form.Item
                name="npwp"
                label="Npwp"
                rules={[
                  idData
                    ? { required: false }
                    : { required: true, message: "Please input npwp!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="address"
                label="Alamat"
                rules={[
                  idData
                    ? { required: false }
                    : {
                        required: true,
                        message: "Please input address!",
                      },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
});
