import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Typography,
  DatePicker,
  Modal,
  Form,
  Input,
  Divider,
  List,
  Image,
  Tag,
  Tabs,
  Space,
  message,
} from "antd";
import moment from "moment";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { LINKS } from "../../routes/app";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { FilterOutlined, ExportOutlined } from "@ant-design/icons";
import { format, parseISO } from "date-fns";
import { appConfig } from "../../config/app";
import { ModalLoaderContext } from "../../utils/modal";
import { useParams } from "react-router-dom";
const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
export const Profile = observer(() => {
  const store = useStore();
  const [form] = Form.useForm();
  const [formFilterByTrxCode] = Form.useForm();
  const [formExport] = Form.useForm();
  const { id } = useParams();
  const modalLoader = useContext(ModalLoaderContext);
  const [filterStart, setFilterStart] = useState([]);
  const [filterEnd, setFilterEnd] = useState([]);
  const [filterTrxId, setFilterTrxId] = useState([]);
  const [filterPartnerTrxId, setFilterPartnerTrxId] = useState([]);
  const [actionFilter, setAction] = useState(false);
  const [actionFilterByCode, setActionFilterByCode] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalExport, setModalExport] = useState(false);

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.PROFILE,
      name: <span style={{ fontWeight: "bold" }}>Profil</span>,
    },
  ];

  const dataRoute = [
    {
      route: LINKS.PROFILE,
      name: "Profil",
    },
  ];

  useEffect(() => {
    (async () => {
      modalLoader.setLoading(true);

      await Promise.allSettled([
        store.authentication.getProfile(),
        store.transaction.getDataHistoryTransaction(),
        store.transaction.getDataHistoryCheckBill(),
      ]);
      await store.transaction.getDataHistoryTopUpProfile(
        store.authentication.profileData?.id
      );
      modalLoader.setLoading(false);
    })();
  }, [store.transaction.filterStart, store.transaction.filterEnd]);

  const handleRemoveFilter = async () => {
    store.transaction.filterStart = null;
    store.transaction.filterEnd = null;
    form.resetFields();
    setFilterStart([]);
    setFilterEnd([]);
    //await store.transaction.getDataHistoryTransaction();
    // actionFilter === true
    //   ? await store.transaction.getDataHistoryTopUpProfile(
    //     store.authentication.profileData?.id
    //   )
    //   : await store.transaction.getDataHistoryTransaction();
    store.transaction.visibleModalFilterTransaction = false;
    setAction(false);
  };

  const handleRemoveFilterByTrxId = async () => {
    store.transaction.filterStart = null;
    store.transaction.filterEnd = null;
    store.transaction.filterTrxId = null;
    store.transaction.filterPartnerTrxId = null;
    formFilterByTrxCode.resetFields();
    setFilterStart([]);
    setFilterEnd([]);
    setFilterTrxId([]);
    setFilterPartnerTrxId([]);
    store.transaction.visibleModalFilterTransactionByCode = false;
    setActionFilterByCode(false);
    await store.transaction.getDataHistoryTransaction();
  };

  const handleCancelFilter = async () => {
    store.transaction.filterStart = null;
    //form.resetFields();
    store.transaction.filterEnd = null;
    store.transaction.visibleModalFilterTransaction = false;
    // actionFilter === true
    //   ? await store.transaction.getDataHistoryTopUpProfile(
    //     store.authentication.profileData?.id
    //   )
    //   : await store.transaction.getDataHistoryTransaction();
    setAction(false);
    //await store.transaction.getDataHistoryTransaction();
  };

  const handleCancelFilterByTrxId = async () => {
    store.transaction.filterStart = null;
    store.transaction.filterEnd = null;
    store.transaction.filterTrxId = null;
    store.transaction.filterPartnerTrxId = null;
    store.transaction.visibleModalFilterTransactionByCode = false;

    setActionFilterByCode(false);
  };

  const handleSubmitFilter = async () => {
    const data = form.getFieldsValue();
    store.transaction.filterStart = moment(data.start_date).format(
        "YYYY-MM-DD"
    ) + " 00:00:00";
    store.transaction.filterEnd = moment(data.end_date).format(
        "YYYY-MM-DD"
    ) + " 23:59:59";
    modalLoader.setLoading(true);
    // actionFilter === true
    //   ? await store.transaction.getDataHistoryTopUpProfile(
    //     store.authentication.profileData?.id
    //   )
    //   : await store.transaction.getDataHistoryTransaction();
    modalLoader.setLoading(false);
    // store.transaction.filterStart = null;
    // store.transaction.filterEnd = null;
    //form.resetFields();
    store.transaction.visibleModalFilterTransaction = false;
    setAction(false);
  };

  const handleSubmitFilterByTrxId = async (data) => {
      store.transaction.filterStart = null
      store.transaction.filterEnd = null
    store.transaction.filterTrxId = data.trx_id == undefined || data.trx_id == '' ? null : data.trx_id
    store.transaction.filterPartnerTrxId = data.partner_trx_id == undefined || data.partner_trx_id == '' ? null : data.partner_trx_id
      modalLoader.setLoading(true);

      modalLoader.setLoading(false);
      store.transaction.visibleModalFilterTransactionByCode = false;
      setActionFilterByCode(false);
      await store.transaction.getDataHistoryTransaction();
  };

  const footerLayoutFilter = [
    <Button
      key={"remove"}
      onClick={handleRemoveFilter}
      style={{
        backgroundColor: "#e74e5e",
        color: "#fff",
      }}
    >
      Hapus Filter
    </Button>,
    <Button key={"cancel"} onClick={handleCancelFilter}>
      Batal
    </Button>,
    <Button
      key={"submit"}
      onClick={handleSubmitFilter}
      style={{
        backgroundColor: "#4e79e7",
        color: "#fff",
      }}
    >
      Terapkan
    </Button>,
  ];

  const footerLayoutFilterByTrxId = [
    <Button
        key={"remove"}
        onClick={handleRemoveFilterByTrxId}
        style={{
          backgroundColor: "#e74e5e",
          color: "#fff",
        }}
    >
      Hapus Filter
    </Button>,
    <Button key={"cancel"} onClick={handleCancelFilterByTrxId}>
      Batal
    </Button>,
    <Button
        key={"submit"}
        onClick={() => {
          formFilterByTrxCode.validateFields().then((data) => {
            handleSubmitFilterByTrxId(data)
          }).catch((info) => {
            console.error("Validate Failed:", info);
          });
        }}
        style={{
          backgroundColor: "#4e79e7",
          color: "#fff",
        }}
    >
      Terapkan
    </Button>,
  ];

  const columns = [
    {
      title: "Nama Produk",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Harga",
      dataIndex: "price",
      key: "price",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Sisa Saldo",
      dataIndex: "balance_remaining",
      key: "balance_remaining",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Pembeli",
      dataIndex: "buyer",
      key: "buyer",
      width: "10%",
    },
    {
      title: "Tujuan",
      dataIndex: "transaction_destination",
      key: "transaction_destination",
    },
    {
      title: "Kode Transaksi",
      dataIndex: "transaction_code",
      key: "transaction_code",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <Tag
            color={
              record.status === 1
                ? "success"
                : record.status === 0
                  ? "warning"
                  : "processing"
            }
          >
            {record.status === 1
              ? "Sukses"
              : record.status === 0
                ? "Dalam Proses"
                : "Gagal"}
          </Tag>
        );
      },
    },
    {
      title: "No Seri",
      dataIndex: "seri_number",
      key: "seri_number",
    },
    {
      title: "IDTrx Mitra",
      dataIndex: "partner_transaction_code",
      key: "partner_transaction_code",
    },
    {
      title: "Tanggal Transaksi",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
      render: (text, record) => {
        return (
          <Text>
            {moment(new Date (record.created_at).toISOString().slice(0, -1)).format("MM-DD-YYYY HH:mm:ss")}
          </Text>
        );
      },
    },
    {
      title: "Alasan Gagal",
      dataIndex: "failed_reason",
      key: "failed_reason",
    },
  ];

  const column = [
    {
      title: "Pengirim",
      dataIndex: "sender_name",
      key: "sender_name",
    },
    {
      title: "Nominal",
      dataIndex: "amount",
      key: "amount",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Tanggal Transaksi",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text, record) => {
        return (
          <Text>
            {moment(new Date (record.transaction_date).toISOString().slice(0, -1)).format("DD MMMM YYYY HH:mm:ss")}
          </Text>
        );
      },
    },
  ];

  const columnBill = [
    {
      title: "Code Product",
      dataIndex: "product_code",
      key: "product_code",
    },
    {
      title: "Admin Price",
      dataIndex: "admin_price",
      key: "admin_price",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Tagihan Postpaid",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Nomor tujuan",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "Transaction Id",
      dataIndex: "trx_id",
      key: "trx_id",
    },
    {
      title: "Partner Transaction Id",
      dataIndex: "partner_trx_id",
      key: "partner_trx_id",
    },
    {
      title: "Tanggal Transaksi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => {
        return (
          <Text>
            {format(parseISO(record.createdAt), "dd MMMM yyyy HH:mm:ss")}
          </Text>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        console.log("status", record)
        return (
          <Tag
            color={
              record.status === "SUCCESS"
                ? "success"
                : record.status === "PENDING"
                  ? "warning"
                  : "processing"
            }
          >
            {record.status === "SUCCESS"
              ? "Sukses"
              : record.status === "PENDING"
                ? "Dalam Proses"
                : "Gagal"}
          </Tag>
        );
      },
    },
  ];
  if (store.authentication.userData.role !== "Admin Partner") delete columns[2];
  const styleSaldoTitle = store.ui.mediaQuery.isDesktop
    ? {
      display: "flex",
      justifyContent: "center",
    }
    : { fontSize: "0.75rem" };
  const styleSaldoContent = store.ui.mediaQuery.isDesktop
    ? {
      fontSize: "1.25rem",
      display: "flex",
      justifyContent: "center",
    }
    : null;

  const handleSubmit = async (id, data) => {
    try {
      modalLoader.setLoading(true);
      const response = await store.membership.changePassword(id, data);
      console.log(id, "id yang masuk");
      console.log(data, "data yang masuk");
      message.success(response?.body?.message || "Berhasil Ganti Password");
    } catch (e) {
      console.log(e, "apa errornya");
      message.error(e.response?.body?.message || "Gagal Ganti Password");
    }

    modalLoader.setLoading(false);
    setVisible(false);
    form.resetFields();
  };

 const handleSubmitExport = async () => {
    const date = formExport.getFieldsValue();
    const dataDate ={
      dateStart: moment(date.start_date).format("YYYY-MM-DD"),
      dateEnd: moment(date.end_date).format("YYYY-MM-DD")
    }
    modalLoader.setLoading(true);
    try {
      await store.transaction.exportTransaction(store.authentication.profileData, dataDate)
        // console.log('responseiniyak', response);
        // message.success("Success export");
        setModalExport(false);
        modalLoader.setLoading(false);
     } catch (e) {
      setModalExport(false);
      console.error(e, "apa errornya");
      message.error(e.response?.body?.message || "Failed to export");
      modalLoader.setLoading(false);
    }
  };

  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent
        data={
          store.authentication.userData.role === "Admin" ||
            store.authentication.userData.role === "Admin Partner" ||
            store.authentication.userData.role === "Customer Service"
            ? routeData
            : dataRoute
        }
      />
      <Card>{console.log(store.authentication.profileData?.userDetail,':)')}

        {store.authentication.userData.role === "Admin Partner" ? (
        <Row style={{ marginBottom: 10 }}>
        <Title strong>Profile</Title>
          <Col
            lg={17}
            xs={17}
            style={{ textAlign: "right" }}
          >
            <Space
              size="small"
              align={"center"}
              wrap={true}
              style={{ textAlign: "center" }}
            >
                <Button
                  onClick={() => setModalExport(true)}
                >
                  <ExportOutlined /> Export
                </Button>
            </Space>
          </Col>
        </Row>
        ):
        <Title strong>Profile</Title>}

        <Row style={{ marginBottom: 20 }}>
          <Col lg={12} xs={24}>
            <Row>
              <Col span={12}>
                <Text strong>Nama</Text>
              </Col>
              <Col span={12}>
                <Text>
                  {store.authentication.profileData?.userDetail?.name}
                </Text>
              </Col>
              <Col span={12}>
                <Text strong>No. Telepon</Text>
              </Col>
              <Col span={12}>
                <Text>
                  {store.authentication.profileData?.userDetail?.phone_number}
                </Text>
              </Col>
              <Col span={12}>
                <Text strong>Username</Text>
              </Col>
              <Col span={12}>
                <Text>{store.authentication.profileData?.username}</Text>
              </Col>
              {/* <Col span={12}>
                <Text strong>Role</Text>
              </Col>
              <Col span={12}>
                <Text>{store.authentication.profileData.roles?.name}</Text>
              </Col> */}
              <Col span={12}>
                <Text strong>Upline</Text>
              </Col>
              <Col span={12}>
                <Text>
                  {store.authentication.profileData.superior?.username}
                </Text>
              </Col>
              {store.authentication.userData.role === "Retail" && (
                <Row>
                  <Col span={12}>
                    <Text strong>Foto Identitas</Text>
                  </Col>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <Text>
                      <Image
                        src={
                          store.authentication.profileData.userDetail
                            ?.image_identity
                            ? `${appConfig.apiUrl}/config/image/${store.authentication.profileData.userDetail?.image_identity}`
                            : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                        }
                        style={{ width: "10vw" }}
                      />
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text strong>Foto Toko</Text>
                    <Text>
                      <Row>
                        {store.authentication.imageProfil.map((item) => (
                          <Image
                            src={
                              item
                                ? `${appConfig.apiUrl}/config/image/${item}`
                                : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                            }
                            style={{ width: "10vw", marginRight: 15 }}
                          />
                        ))}
                      </Row>
                    </Text>
                  </Col>
                </Row>
              )}
            </Row>
          </Col>
          <Col span={store.ui.mediaQuery.isMobile ? 24 : 5}>
            <Row justify={"center"}>
              <Col lg={12} xs={12}>
                <Title strong level={3} style={styleSaldoTitle}>
                  Saldo
                </Title>
              </Col>
              <Col lg={24} xs={12}>
                <Text style={styleSaldoContent}>
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  }).format(store.authentication.profileData?.wallet || 0)}
                </Text>
              </Col>
              <Col></Col>
            </Row>
          </Col>
          {store.authentication.userData.role != "Admin Partner" &&
            store.authentication.userData.role != "Retail" && (
              <Col span={store.ui.mediaQuery.isMobile ? 24 : 5}>
                <Row justify={"center"}>
                  <Col lg={12} xs={12}>
                    <Title strong level={3} style={styleSaldoTitle}>
                      Profit
                    </Title>
                  </Col>
                  <Col lg={24} xs={12}>
                    <Text style={styleSaldoContent}>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      }).format(store.authentication.profileData?.profit || 0)}
                    </Text>
                  </Col>
                  <Col></Col>
                </Row>
              </Col>
            )}
          {store.authentication.userData.role === "Retail" && (
            <Button
              onClick={() => {
                console.log(store.authentication.profileData?.id, "id nya");
                console.log(
                  store.authentication.profileData?.userDetail.id,
                  "id member"
                );
                setVisible(true);
              }}
            >
              Ganti Password
            </Button>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Riwayat Top Up" key="1">
                <Button
                  style={{ marginBottom: "1rem", marginLeft: 5 }}
                  onClick={() => {
                    store.transaction.visibleModalFilterTransaction = true;
                    store.transaction.page = 0;
                    setAction(true);
                  }}
                >
                  <FilterOutlined />
                  Filter
                </Button>
                {store.ui.mediaQuery.isDesktop && (
                  <Table
                    key="1"
                    hasEmpty
                    columns={column}
                    dataSource={store.transaction.dataHistoryTopUpProfile}
                    bordered
                    pagination={{
                      pageSize: store.transaction.pageSizeHistoryTopUpProfile,
                      total: store.transaction.total_dataHistoryTopUpProfile,
                      current: store.transaction.pageHistoryTopUpProfile + 1,
                      showSizeChanger: true,
                      simple: false,
                    }}
                    onChange={async (page) => {
                      let pageNumber = page.current;
                      store.transaction.pageSizeHistoryTopUpProfile = page.pageSize;
                      store.transaction.pageHistoryTopUpProfile = pageNumber - 1;
                      modalLoader.setLoading(true);
                      await store.transaction.getDataHistoryTopUpProfile(
                        store.authentication.profileData?.id
                      );
                      modalLoader.setLoading(false);
                    }}
                  />
                )}
                {store.ui.mediaQuery.isMobile && (
                  <div>
                    <List
                      itemLayout="horizontal"
                      position={"top"}
                      dataSource={store.transaction.dataHistoryTopUpProfile}
                      pagination={{
                        onChange: async (page, pageSize) => {
                          console.log(page, "Page");
                          console.log(pageSize, "Page size");
                          store.transaction.pageSizeHistoryTopUpProfile = pageSize;
                          store.transaction.pageHistoryTopUpProfile = page - 1;
                          modalLoader.setLoading(true);
                          await store.transaction.getDataHistoryTopUpProfile(
                            store.authentication.profileData?.id
                          );
                          modalLoader.setLoading(false);
                        },
                        pageSize: store.transaction.pageSizeHistoryTopUpProfile,
                        total: store.transaction.total_dataHistoryTopUpProfile,
                        current: store.transaction.pageHistoryTopUpProfile + 1,
                        style: { marginBottom: "1rem", marginRight: "1rem" },
                      }}
                      style={{ padding: 0 }}
                      renderItem={(item) => {
                        return (
                          <div>
                            <List.Item
                              key={item.id}
                              style={{
                                backgroundColor: "#ffffff",
                                paddingTop: 0,
                                paddingBottom: 0,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <List.Item.Meta
                                className={["cariparkir-container"].join(" ")}
                                title={item.id}
                                description={
                                  <div style={{}}>
                                    <p>
                                      <small>
                                        Pengirim : {item.sender_name}
                                      </small>{" "}
                                      <br />
                                      <small>
                                        Amount :{" "}
                                        {new Intl.NumberFormat("id-ID", {
                                          style: "currency",
                                          currency: "IDR",
                                        }).format(item.amount || 0)}
                                      </small>{" "}
                                      <br />
                                      <small>
                                        Transaction Date :{" "}
                                          {moment(new Date (item.transaction_date).toISOString().slice(0, -1)).format("DD MMMM YYYY")}
                                      </small>{" "}
                                      <br />
                                    </p>
                                  </div>
                                }
                              />
                            </List.Item>
                            <Divider plain style={{ margin: 0 }} />
                          </div>
                        );
                      }}
                    />
                  </div>
                )}
              </TabPane>
              <TabPane tab="Riwayat Transaksi" key="2">
                <Button
                  style={{ marginBottom: "1rem", marginLeft: 5 }}
                  onClick={() => {
                    store.transaction.visibleModalFilterTransaction = true;
                    store.transaction.page = 0;
                  }}
                >
                  <FilterOutlined />
                  Filter
                </Button>

                <Button
                    style={{ marginBottom: "1rem", marginLeft: 5 }}
                    onClick={() => {
                      store.transaction.visibleModalFilterTransactionByCode = true;
                      store.transaction.page = 0;
                      setActionFilterByCode(true);
                    }}
                >
                  <FilterOutlined />
                  Filter : kode transaksi/ IDTrx Mitra
                </Button>
                {store.ui.mediaQuery.isDesktop && (
                  <Table
                    columns={columns}
                    bordered
                    scroll={{ x: 1300 }}
                    //scroll={{ x: 1500, y: 300 }}
                    dataSource={store.transaction.dataHistoryTransaction}
                    pagination={{
                      pageSize: store.transaction.pageSize,
                      total: store.transaction.total_dataHistoryTransaction,
                      current: store.transaction.page + 1,
                      showSizeChanger: true,
                      simple: false,
                    }}
                    onChange={async (page) => {
                      let pageNumber = page.current;
                      store.transaction.pageSize = page.pageSize;
                      store.transaction.page = pageNumber - 1;
                      modalLoader.setLoading(true);
                      await store.transaction.getDataHistoryTransaction();
                      modalLoader.setLoading(false);
                    }}
                  />
                )}
                {store.ui.mediaQuery.isMobile && (
                  <div>
                    <List
                      itemLayout="horizontal"
                      position={"top"}
                      dataSource={store.transaction.dataHistoryTransaction}
                      pagination={{
                        onChange: async (page, pageSize) => {
                          store.transaction.page = pageSize;
                          store.transaction.page = page - 1;
                          modalLoader.setLoading(true);
                          await store.transaction.getDataHistoryTransaction();
                          modalLoader.setLoading(false);
                        },
                        pageSize: store.transaction.pageSize,
                        total: store.transaction.total_dataHistoryTransaction,
                        current: store.transaction.page + 1,
                        style: { marginBottom: "1rem", marginRight: "1rem" },
                      }}
                      style={{ padding: 0 }}
                      renderItem={(item) => {
                        return (
                          <div>
                            <List.Item
                              key={item.id}
                              style={{
                                backgroundColor: "#ffffff",
                                paddingTop: 0,
                                paddingBottom: 0,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <List.Item.Meta
                                className={["cariparkir-container"].join(" ")}
                                title={item.buyer}
                                description={
                                  <div style={{}}>
                                    <p>
                                      <small>Price : {item.price}</small> <br />
                                      <small>
                                        Tujuan : {item.transaction_destination}
                                      </small>{" "}
                                      <br />
                                      <small>
                                        Kode Transaksi : {item.transaction_code}
                                      </small>{" "}
                                      <br />
                                      <small>
                                        Status :{" "}
                                        {
                                          <Tag
                                            color={
                                              item.status === 1
                                                ? "success"
                                                : item.status === 0
                                                  ? "warning"
                                                  : "processing"
                                            }
                                          >
                                            {item.status === 1
                                              ? "Sukses"
                                              : item.status === 0
                                                ? "Dalam Proses"
                                                : "Gagal"}
                                          </Tag>
                                        }
                                      </small>{" "}
                                      <br />
                                      <small>
                                        No.Seri : {item.seri_number}
                                      </small>{" "}
                                      <br />
                                      <small>
                                        Alasan Gagal : {item.failed_reason}
                                      </small>{" "}
                                      <br />
                                      <small>
                                        IDTrx Mitra :{" "}
                                        {item.partner_transaction_code}
                                      </small>{" "}
                                      <br />
                                      <small>
                                        Transaction Date :{" "}
                                        {moment(new Date (item.created_at).toISOString().slice(0, -1)).format("MM-DD-YYYY HH:mm:ss")}
                                      </small>{" "}
                                      <br />
                                    </p>
                                  </div>
                                }
                              />
                            </List.Item>
                            <Divider plain style={{ margin: 0 }} />
                          </div>
                        );
                      }}
                    />
                  </div>
                )}
              </TabPane>
              <TabPane tab="Riwayat Check Bill" key="3">
                <Button
                  style={{ marginBottom: "1rem", marginLeft: 5 }}
                  onClick={() => {
                    store.transaction.visibleModalFilterTransaction = true;
                    store.transaction.page = 0;
                    setAction(true);
                  }}
                >
                  <FilterOutlined />
                  Filter
                </Button>
                {store.ui.mediaQuery.isDesktop && (
                  <Table
                    key="1"
                    hasEmpty
                    columns={columnBill}
                    dataSource={store.transaction.dataHistorybillProfile}
                    bordered
                    pagination={{
                      pageSize: store.transaction.pageSizeHistorybillProfile,
                      total: store.transaction.total_dataHistorybillProfile,
                      current: store.transaction.pageHistorybillProfile + 1,
                      showSizeChanger: true,
                      simple: false,
                    }}
                    onChange={async (page) => {
                      let pageNumber = page.current;
                      store.transaction.pageSizeHistorybillProfile = page.pageSize;
                      store.transaction.pageHistorybillProfile = pageNumber - 1;
                      modalLoader.setLoading(true);
                      await store.transaction.getDataHistoryCheckBill();
                      modalLoader.setLoading(false);
                    }}
                  />
                )}
                {store.ui.mediaQuery.isMobile && (
                  <div>
                    <List
                      itemLayout="horizontal"
                      position={"top"}
                      dataSource={store.transaction.dataHistorybillProfile}
                      pagination={{
                        onChange: async (page, pageSize) => {
                          console.log(page, "Page");
                          console.log(pageSize, "Page size");
                          store.transaction.pageSizeHistorybillProfile = pageSize;
                          store.transaction.pageHistorybillProfile = page - 1;
                          modalLoader.setLoading(true);
                          await store.transaction.getDataHistoryCheckBill();
                          modalLoader.setLoading(false);
                        },
                        pageSize: store.transaction.pageSizeHistorybillProfile,
                        total: store.transaction.total_dataHistorybillProfile,
                        current: store.transaction.pageHistorybillProfile + 1,
                        style: { marginBottom: "1rem", marginRight: "1rem" },
                      }}
                      style={{ padding: 0 }}
                      renderItem={(item) => {
                        return (
                          <div>
                            <List.Item
                              key={item.id}
                              style={{
                                backgroundColor: "#ffffff",
                                paddingTop: 0,
                                paddingBottom: 0,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <List.Item.Meta
                                className={["cariparkir-container"].join(" ")}
                                title={item.id}
                                description={
                                  <div style={{}}>
                                    <p>
                                      <small>
                                        Pengirim : {item.sender_name}
                                      </small>{" "}
                                      <br />
                                      <small>
                                        Amount :{" "}
                                        {new Intl.NumberFormat("id-ID", {
                                          style: "currency",
                                          currency: "IDR",
                                        }).format(item.amount || 0)}
                                      </small>{" "}
                                      <br />
                                      <small>
                                        Transaction Date :{" "}
                                        { moment(new Date (item.created_at).toISOString().slice(0, -1)).format("MM-DD-YYYY dd-MM-yyyy")  }
                                      </small>{" "}
                                      <br />
                                    </p>
                                  </div>
                                }
                              />
                            </List.Item>
                            <Divider plain style={{ margin: 0 }} />
                          </div>
                        );
                      }}
                    />
                  </div>
                )}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Card>
      <Modal
        visible={store.transaction.visibleModalFilterTransaction}
        title={"Filter"}
        footer={footerLayoutFilter}
        onCancel={async () => {
          //form.resetFields();
          store.transaction.filterStart = null;
          store.transaction.filterEnd = null;
          store.transaction.visibleModalFilterTransaction = false;
          actionFilter === true
            ? await store.transaction.getDataHistoryTopUpProfile(
              store.authentication.profileData?.id
            )
            : await store.transaction.getDataHistoryTransaction();
        }}
      >
        <Row>
          <Col span={24}>
            <Form layout="vertical" name="filter" form={form}>
              <Form.Item
                name="start_date"
                label="Dari"
                rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="end_date"
                label="Sampai"
                rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
          visible={store.transaction.visibleModalFilterTransactionByCode}
          title={"Filter : kode transaksi/ IDTrx Mitra"}
          footer={footerLayoutFilterByTrxId}
          onCancel={async () => {
            //form.resetFields();
            store.transaction.filterStart = null;
            store.transaction.filterEnd = null;
            store.transaction.filterTrxId = null;
            store.transaction.filterPartnerTrxId = null;
            store.transaction.visibleModalFilterTransactionByCode = false;
            actionFilterByCode === true
                ? await store.transaction.getDataHistoryTopUpProfile(
                    store.authentication.profileData?.id
                )
                : await store.transaction.getDataHistoryTransaction();
          }}
      >
        <Row>
          <Col span={24}>
            <Form layout="vertical" name="filter" form={formFilterByTrxCode}>
              <Form.Item
                  name="trx_id"
                  label="Kode Transaksi"
              >
                <Input />
              </Form.Item>
                <Form.Item
                    name="partner_trx_id"
                    label="IDTrx Mitra"
                >
                <Input />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={visible}
        title={"Ganti Password"}
        okText={"Ganti"}
        cancelText="Batal"
        onCancel={() => {
          form.resetFields();
          //handleCancelTransaction();
          setVisible(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(store.authentication.profileData?.id, "id pass");
              console.log(values, "id data");
              console.log(store.membership.dataDetail.id, "id member");
              handleSubmit(store.authentication.profileData?.id, values);
              form.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ id: store.authentication.profileData?.id }}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: false, message: "Please input password!" }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
       <Modal
        visible={modalExport}
        title={"Export"}
				onOk={handleSubmitExport}
        onCancel={() => {
          setModalExport(false);
        }}
      >
        <Row>
          <Col span={24}>
            <Form layout="vertical" name="filter" form={formExport}>
              <Form.Item
                  name="start_date"
                  label="Dari"
                  rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                  name="end_date"
                  label="Sampai"
                  rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
