import React from "react";
import {PageHeader} from "antd";

export const About = () => {
    return <div className={["ppob-container"].join(" ")}>
        <PageHeader
            style={{
                padding: 0,
                margin: 0,
                height: 40,
                backgroundColor: "transparent",
            }}
            title={"About"}
        >
        </PageHeader>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aut recusandae velit! Consequatur corporis,
        eum fuga, harum incidunt laboriosam minus necessitatibus neque non nostrum pariatur tempore. Dignissimos impedit
        rem tempora!
    </div>
};
