import {makeAutoObservable} from "mobx";
import {http} from "../utils/http";

export class Approval {
    page = 0;
    pageSize = 10
    data = [];
    listImage=[];
    total_data = 0;
    
    constructor(ctx) {
        this.ctx = ctx;
        makeAutoObservable(this);
    }

    async getData() {
        try {
            const response = await http.get(`/users/find-by-approval?page=${this.page}&pageSize=${this.pageSize}`);
            console.log(response,"data dari store")
            this.data = response.body.data ?? []
            this.listImage= this.data.user_detail?.image_store ? JSON.parse(this.data.user_detail?.image_store) : []
            this.total_data = response.body.count ?? 0
        } catch (e) {
            console.error(e);
        }
    }

    async approveUser(id) {
        try {
            const response = await http.put(`/users/approve-user/${id}`);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }
    async rejectUser(id) {
        try {
            const response = await http.put(`/users/reject-user/${id}`);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async resendUser(id) {
        try {
            const response = await http.put(`/users/resend-user/${id}`);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }
    // async delete(id) {
    //     try {
    //         const response = await http.del(`/product/${id}`);
    //         await this.getData();
    //         return response;
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }
}


