import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useStore} from "../utils/useStore";

export const PublicRoute = ({component: Component, restricted, ...rest}) => {
  const store = useStore();

  return (
      // restricted = false meaning public route
      // restricted = true meaning restricted route
      <Route {...rest} render={props => (
          store.authentication.isLoggedIn && restricted ?
              <Redirect to="/app"/>
              : <Component {...props} />
      )}/>
  );
};

