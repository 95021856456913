import {makeAutoObservable} from "mobx";
import {http} from "../utils/http";

export class Membership {
    page = 0;
    pageSize = 10
    data = [];
    dataFilterMembership = [];
    total_data = 0;
    dataTotal=0;

    dataDetail = {};
    dataMember=[];


    //filter
    visibleModalFilterMembership = false;
    filterMembership = null;
    filterPartner = null;
    constructor(ctx) {
        this.ctx = ctx;
        makeAutoObservable(this);
    }

    async getData() {
        try {
            const response = await http.get(`/users?page=${this.page}&pageSize=${this.pageSize}&superior=${this.filterMembership}&type=${this.filterPartner}`);
             console.log(this.filterMembership)
            // console.log(this.filterPartner)
             console.log(response)
             this.dataMember = response.body.data ?? []
            // this.dataMember = response.body.data.map((item, idx) => {
            //     item.key = idx;
            //     item.name = item?.user_detail?.name;
            //     item.phone_number = item?.user_detail?.phone_number;
            //     item.roleId = item?.roles.id;
            //     item.roleName = item?.roles.name;
            //     return item
            // }) ?? []

            this.dataTotal = response?.body?.count ?? 0
            console.log(this.dataTotal)
        } catch (e) {
            console.error(e);
        }
    }

    async getDataFilter() {
        try {
            const response = await http.get(`/users?page=${this.page}&pageSize=1000&superior=${this.filterMembership}&type=${this.filterPartner}`);
            this.dataFilterMembership = response.body.data ?? []
            this.dataFilterMembership = response.body.data.map((item, idx) => {
                item.key = idx;
                item.name = item?.user_detail?.name;
                item.username = item?.username
                item.phone_number = item?.user_detail?.phone_number;
                item.roleId = item?.roles.id;
                item.roleName = item?.roles.name;
                return item
            }) ?? []

            console.log(this.dataTotal)
        } catch (e) {
            console.error(e);
        }
    }

    async getDetail(id) {
        try {
            const response = await http.get(`/users/`+id);
            console.log(response,'Data Detail')
            this.dataDetail = response.body.data
        } catch (e) {
            console.error(e);
        }
    }

    async getDataBySuperior() {
        try {
            const response = await http.get(`/users/find-by-supperior?page=${this.page}&pageSize=${this.pageSize}`);
            console.log(response)
            this.data = response.body.data.map((item, idx) => {
                item.key = idx;
                item.name = item?.user_detail?.name;
                item.phone_number = item?.user_detail?.phone_number;
                item.roleId = item?.roles.id;
                item.roleName = item?.roles?.name;
                return item
            }) ?? []

            this.total_data = response?.body?.count ?? 0
            console.log(this.total_data)
        } catch (e) {
            console.error(e);
        }
    }

    async create(data) {
        try {
            const response = await http.post('/users').send(data);
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async update(id, data) {
        try {
            const response = await http.put('/users/' + id).send(data);
            console.log(data,"data dari store")
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async changePassword(id, data) {
        try {
            const response = await http.put('/users/change-password/' + id).send(data);
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async delete(id) {
        try {
            const response = await http.del('/users/' + id);
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async changeStatus(id, status) {
        try {
            const response = await http.get(`/users/${id}/${status}`);
            console.log(status,"change status")
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async withdrawProfit(id) {
        try {
            const response = await http.put(`/transaction/withdraw/${id}`);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }
}


