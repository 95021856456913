import {makeAutoObservable} from "mobx";
import {http} from "../utils/http";

export class Category {
    page = 0;
    pageSize = 10
    data = [];
    total_data = 0;
    filterCategory = null;
    visibleModalCategory = false;

    pageSubCategories = 0;
    pageSizeSubCategories = 10
    dataSubCategories = [];
    total_dataSubCategories = 0;

    constructor(ctx) {
        this.ctx = ctx;
        makeAutoObservable(this);
    }

    async getData() {
        try {
            const response = await http.get(`/product/categories?page=${this.page}&pageSize=${this.pageSize}`);
            //console.log(response)
            this.data = response.body.data.map((item, idx) => {
                item.key = idx;
                return item
            }) ?? []
            this.total_data = response?.body?.count ?? 0
        } catch (e) {
            console.log(e);
        }
    }

    async getDataSubCategories() {
        try {
            const response = await http.get(`/product/sub-categories?page=${this.pageSubCategories}&pageSize=${this.pageSizeSubCategories}`);
            this.dataSubCategories = response.body.data.map((item, idx) => {
            item.key = idx;
            return item
        }) ?? []

            this.total_dataSubCategories = response.body.count ?? 0
        } catch (e) {
            console.log(e);
        }
    }

    async getDataCategories() {
        try {
            const response = await http.get(`/product/categories?page=${this.pageCategories}&pageSize=${this.pageSizeCategories}`);

            this.dataCategories = response.body.data.map((item, idx) => {
                item.key = idx;
                return item
            }) ?? []

            this.total_dataCategories = response?.body?.count ?? 0
            if (this.dataCategories.length > 0) {
                this.filterCategory = this.dataCategories[0].id
            }
        } catch (e) {
            console.error(e);
        }
    }

    async create(data) {
        try {
            const response = await http.post('/product/categories').send(data);
            await this.getData();
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    async update(id, data) {
        try {
            const response = await http.put(`/product/categories/${id}`).send(data);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async delete(id) {
        try {
            const response = await http.del(`/product/${id}`);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }
}


