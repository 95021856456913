import {Redirect, Route, Switch} from "react-router-dom";
import {Home} from "../pages/Home/Home";
import {About} from "../pages/About/About";
import {Membership} from "../pages/Membership/Membership";
import {DetailUser} from "../pages/Membership/DetailUser";
import {Product} from "../pages/Product/Product";
import {ProductDetail} from "../pages/Product/ProductDetail";
import {Transaction} from "../pages/Transaction/Transaction";
import {Profile} from "../pages/Profile/Profile";
import {Commission} from "../pages/Config/Commission";
import {Partner} from "../pages/Config/Partner";
import {Supplier} from "../pages/Config/Supplier";
import {Category} from "../pages/Product/Category";
import {Payback} from "../pages/Payback/Payback";
import {PaybackCreated} from "../pages/Payback/PaybackCreated";
import {Subcategory} from "../pages/Product/Subcategory";
import {Konfirmasi} from "../pages/Membership/Konfirmasi";


export const LINKS = {
    HOME: "/app/home",
    ABOUT: "/app/about",
    MEMBERSHIP: "/app/membership",
    PRODUCT: "/app/product",
    TRANSACTION: "/app/transaction",
    PROFILE: "/app/profile",
    PARTNER: "/app/partner",
    COMMISSION: "/app/commission",
    SUPPLIER: "/app/supplier",
    CATEGORY: "/app/category",
    PAYBACK: "/app/payback",
    KONFIRMASI: "/app/konfirmasi",
    PAYBACK_CREATED: "/app/payback-created",
    SUBCATEGORY: "/app/subcategory",
    USER_DETAIL: "/app/user-detail/:id",
    PRODUCT_DETAIL: "/app/product-detail/:id",
};

export const AppRoute = () => {
    return <Switch>
        <Route path={LINKS.HOME}>
            <Home/>
        </Route>
        <Route path={LINKS.USER_DETAIL}>
            <DetailUser/>
        </Route>
        <Route path={LINKS.KONFIRMASI}>
            <Konfirmasi/>
        </Route>
        <Route path={LINKS.PRODUCT_DETAIL}>
            <ProductDetail/>
        </Route>
        <Route path={LINKS.COMMISSION}>
            <Commission/>
        </Route>
        <Route path={LINKS.PAYBACK_CREATED}>
            <PaybackCreated/>
        </Route>
        <Route path={LINKS.CATEGORY}>
            <Category/>
        </Route>
        <Route path={LINKS.SUBCATEGORY}>
            <Subcategory/>
        </Route>
        <Route path={LINKS.SUPPLIER}>
            <Supplier/>
        </Route>
        <Route path={LINKS.MEMBERSHIP}>
            <Membership/>
        </Route>
        <Route path={LINKS.PRODUCT}>
            <Product/>
        </Route>
        <Route path={LINKS.PARTNER}>
            <Partner/>
        </Route>
        <Route path={LINKS.TRANSACTION}>
            <Transaction/>
        </Route>
        <Route path={LINKS.PAYBACK}>
            <Payback/>
        </Route>
        <Route path={LINKS.ABOUT}>
            <About/>
        </Route>
        <Route path={LINKS.PROFILE}>
            <Profile/>
        </Route>
        <Route path="/app" exact>
            <Redirect to={LINKS.HOME}/>
        </Route>
    </Switch>
}
