import { makeAutoObservable } from "mobx";
import { http } from "../utils/http";
import { binaryStringToArrayBuffer, arrayBufferToBlob } from 'blob-util'
import axios from "axios";
import {appConfig} from "../config/app";
import fileDownload from "react-file-download";
import {TokenUtil} from "../utils/token";
import {message} from "antd";

export class Transaction {
  page = 0;
  pageSize = 10;
  data = [];
  total_data = 0;


  filterSubCategory = null;
  visibleModalProduct = false;
  visibleModalTransaction = false;
  pageSizeDetail = 10;
  pageDetail = 0

  pageCategories = 0;
  pageSizeCategories = 100;
  dataCategories = [];
  total_dataCategories = 0;

  pageSubCategories = 0;
  pageSizeSubCategories = 100;
  dataSubCategories = [];
  total_dataSubCategories = 0;
  //filterSubCategory = null;

  pageHistoryTransaction = 0;
  pageSizeHistoryTransaction = 10;
  dataHistoryTransaction = [];
  total_dataHistoryTransaction = 0;

  pageHistoryTransactionDetailUser = 0;
  pageSizeHistoryTransactionDetailUser = 10;
  dataHistoryTransactionDetailUser = [];
  total_dataHistoryTransactionDetailUser = 0;

  pageHistoryTopUp = 0;
  pageSizeHistoryTopUp = 10;
  dataHistoryTopUp = [];
  total_dataHistoryTopUp = 0;

  pageHistorybillProfile = 0;
  pageSizeHistorybillProfile = 10;
  dataHistorybillProfile = [];
  total_dataHistorybillProfile = 0;

  pageHistoryTopUpProfile = 0;
  pageSizeHistoryTopUpProfile = 10;
  dataHistoryTopUpProfile = [];
  total_dataHistoryTopUpProfile = 0;

  dataTransaction = [];
  dataTransactionB2B = [];
  dataTransactionPartner = [];
  total_dataDetailHistoryTransactionDetailUser = 0;


  //filter
  visibleModalFilterTransaction = false;
  visibleModalFilterTransactionByCode = false;
  filterStart = null;
  filterEnd = null;
  filterTrxId = null;
  filterPartnerTrxId = null;
  filterStartDetailUser = null;
  filterEndDetailUser = null;
  constructor(ctx) {
    this.ctx = ctx;
    makeAutoObservable(this);
  }


  async getData() {
    try {
      const response = await http.get(
        `/product/by-categories-all?sub-category=${this.filterSubCategory}&page=${this.page}&pageSize=${this.pageSize}`
      );
      this.data = response.body.data ?? [];
      this.total_data = response?.body?.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async getDataTransaction() {
    try {
      const response = await http.get(`/transaction/total-order`);
      //console.log(response)
      this.dataTransaction = response.body.data;
    } catch (e) {
      console.error(e);
    }
  }

  async getDataTransactionB2B() {
    try {
      const response = await http.get(`/transaction/total-order-b2b`);
      //console.log(response)
      this.dataTransactionB2B = response.body.data;
    } catch (e) {
      console.error(e);
    }
  }

  async getDataTransactionPartner() {
    try {
      const response = await http.get(`/transaction/total-order-partner`);
      //console.log(response)
      this.dataTransactionPartner = response.body.data;
    } catch (e) {
      console.error(e);
    }
  }

  async getDataSubCategories() {
    try {
      const response = await http.get(
        `/product/sub-categories?category=${this.filterCategory}&page=${this.pageSubCategories}&pageSize=${this.pageSizeSubCategories}`
      );
      this.dataSubCategories = response.body.data ?? [];
      this.total_dataSubCategories = response.body.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async getDataCategories() {
    try {
      const response = await http.get(
        `/product/categories?page=${this.pageCategories}&pageSize=${this.pageSizeCategories}`
      );
      this.dataCategories = response.body.data ?? [];
      this.total_dataCategories = response?.body?.count ?? 0;
      if (this.dataCategories.length > 0) {
        this.filterCategory = this.dataCategories[0].id;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getDataHistoryCheckBill() {
    try {
      // const response = await http.get(
      //   `/transaction/check-bill-history`
      // );
      const response = await http.get(
        `/transaction/check-bill-history?page=${this.pageHistorybillProfile}&pageSize=${this.pageSizeHistorybillProfile}`
      );
      console.log("bill", response);
      this.dataHistorybillProfile = response.body.data ?? [];
      this.total_dataHistorybillProfile = response?.body?.count ?? 0;
      console.log(this.total_dataHistorybillProfile)
    } catch (e) {
      console.error(e);
    }
  }

  async getDataHistoryTransaction() {
    try {
      const response = await http.get(
        `/transaction/history?page=${this.page}&pageSize=${this.pageSize}&start=${this.filterStart}&end=${this.filterEnd}&trxId=${this.filterTrxId}&partnerTrxId=${this.filterPartnerTrxId}`
      );
      console.log(response);
      this.dataHistoryTransaction = response.body.data ?? [];
      this.total_dataHistoryTransaction = response?.body?.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async getDetailHistoryTransaction(id) {
    try {
      const response = await http.get(`/transaction/history-user/${id}?page=${this.page}&pageSize=${this.pageSize}&start=${this.filterStart}&end=${this.filterEnd}&trxId=${this.filterTrxId}&partnerTrxId=${this.filterPartnerTrxId}`);
      console.log(response, 'Data Trans');
      this.dataDetailHistoryTransactionDetailUser = response.body.data ?? [];
      this.total_data = response?.body?.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async getDataHistoryTopUp(id) {
    try {
      const response = await http.get(
        `/transaction/history-deposit?page=${this.pageHistoryTopUp}&pageSize=${this.pageSizeHistoryTopUp}&user-destination=${id}&start=${this.filterStart}&end=${this.filterEnd}`
      );
      console.log(response, 'get data history')
      this.dataHistoryTopUp = response.body.data ?? [];
      this.total_dataHistoryTopUp = response?.body?.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async getDataHistoryTopUpProfile(id) {
    try {
      const response = await http.get(
        `/transaction/history-deposit-profile?page=${this.pageHistoryTopUpProfile}&pageSize=${this.pageSizeHistoryTopUpProfile}&user-destination=${id}&start=${this.filterStart}&end=${this.filterEnd}`
      );
      console.log(response, 'get data history Profile')
      this.dataHistoryTopUpProfile = response.body.data ?? [];
      this.total_dataHistoryTopUpProfile = response?.body?.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async create(data) {
    try {
      const response = await http.post("/product").send(data);
      await this.getData();
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async buyProduct(data) {
    const response = await http.post("/transaction/order").send(data);
    console.log(response, 'dari store')
    return response;
  }

  async buyProd(data) {
    const response = await http.post("/transaction/order-prod").send(data);
    console.log(response)
    return response;
  }

  async checkBill(data) {
    const response = await http.post("/transaction/check-bill").send(data);
    console.log(response)
    return response;
  }

  async update(id, data) {
    try {
      const response = await http.put(`/product/${id}`).send(data);
      await this.getData();
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async delete(id) {
    try {
      const response = await http.del(`/product/${id}`);
      await this.getData();
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async distribute(data) {
    try {
      const response = await http.post("/transaction/distribute").send(data);
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async distributeAdmin(data) {
    try {
      const response = await http
        .post("/transaction/distribute-admin")
        .send(data);
      return response;
    } catch (e) {
      console.error(e);
    }
  }

    exportTransaction(data,date) {
    try {
    axios({
      url: appConfig.apiUrl + `/excel/history-user/export/${data.id}`,
      headers: {"Authorization" : `Bearer ${TokenUtil.accessToken}`},
      method: 'POST',
      data: date,
      responseType: 'blob'
    }).then((response) => {
        var fileDownload = require('react-file-download');
        message.success("Success export");
        fileDownload(response.data, `Mutasi Transaki ${data.userDetail.name} ${date.dateStart} - ${date.dateEnd}.xlsx`);
        return fileDownload;
      }).catch(function (error) {
        message.error("No data to export");
        return error;
    });

    } catch (e) {
      console.error(e,':)')
    }
  }

  exportTransactionAll(data) {
    try {
      axios({
        url: appConfig.apiUrl + `/excel/history-user/export-all`,
        headers: {"Authorization" : `Bearer ${TokenUtil.accessToken}`},
        method: 'POST',
        data: data,
        responseType: 'blob'
      }).then((response) => {
        var fileDownload = require('react-file-download');
        message.success("Success export");
        fileDownload(response.data, `Mutasi Transaksi ${data.dateStart} - ${data.dateEnd}.xlsx`);
        return fileDownload;
      }).catch(function (error) {
        message.error("No data to export");
        return error;
      });

    } catch (e) {
      console.error(e,':)')
    }
  }
}
