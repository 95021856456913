import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Input, Row, Tabs, message } from "antd";
import { FilterOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { PartnerComponent } from "../../component/PartnerComponent";
import { LINKS } from "../../routes/app";
import { ModalLoaderContext } from "../../utils/modal";

const { Search } = Input;

export const Partner = observer(() => {
  const store = useStore();
  const modalLoader = useContext(ModalLoaderContext);

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        await store.partner.getData();
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.PARTNER,
      name: <span style={{ fontWeight: "bold" }}>Rekanan</span>,
    },
  ];

  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent data={routeData} />
      <Card>
        <Row style={{ marginBottom: 20 }}>
          {/* <Col span={12}>
            <Button>
                            <FilterOutlined/>
                            Filter
                        </Button>
          </Col> */}
          <Col span={24} style={{ textAlign: "right" }}>
            {/* <Search
              placeholder="input search text"
              style={{
                width: store.ui.mediaQuery.isMobile ? 160 : 200,
                marginRight: store.ui.mediaQuery.isMobile ? 0 : 10,
                marginBottom: store.ui.mediaQuery.isMobile ? 10 : 0,
              }}
            /> */}
            {store.authentication.userData.role !== "Customer Service" && (
              <Button
                onClick={() => (store.partner.visibleModalPartner = true)}
              >
                <PlusSquareOutlined /> New
              </Button>
            )}
          </Col>
        </Row>
        <PartnerComponent />
      </Card>
    </div>
  );
});
