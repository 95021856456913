import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Table,
  List,
  Divider,
} from "antd";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useStore } from "../utils/useStore";
import { LINKS } from "../routes/app";
import { ModalLoaderContext } from "../utils/modal";

export const CategoryComponent = observer((props) => {
  const store = useStore();
  const [form] = Form.useForm();
  const history = useHistory();
  const [idData, setIdData] = useState("");
  const modalLoader = useContext(ModalLoaderContext);

  const handleEditButton = (data) => {
    console.log(data, "isi data");
    form.setFieldsValue({
      name: data.name,
      code: data.code,
    });
    store.category.visibleModalCategory = true;
    setIdData(data.id);
  };

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Kategori",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tindakan",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEditButton(record)}>Edit</Button>
        </Space>
      ),
    },
  ];

  if (store.authentication.userData.role === "Customer Service") columns.pop();

  const deleteData = async (id) => {
    try {
      console.log(id);
      modalLoader.setLoading(true);
      await store.category.delete(id);
      modalLoader.setLoading(true);
      message.success("Data Berhasil Dihapus");
      history.push(LINKS.PRODUCT);
    } catch (err) {
      console.log("error", err);
      message.error("Gagal menghapus");
    }
  };

  const handleCancel = () => {
    setIdData("");
    store.category.visibleModalCategory = false;
  };

  const handleSubmit = async (data) => {
    console.log(data, "isi data2");
    if (idData !== "") {
      modalLoader.setLoading(true);
      try {
        const response = await store.category.update(idData, data);
        response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(
              response?.body?.message || "Berhasil Ubah Data Kategori"
            )
          : message.error(
              response?.body?.message || "Gagal Ubah Data Kategori"
            );
      } catch (e) {
        message.error(e.response?.body?.message || "Gagal Ubah Data Kategori");
      }
      modalLoader.setLoading(false);
      store.category.visibleModalCategory = false;
      setIdData("");
      form.resetFields();
    } else {
      modalLoader.setLoading(true);
      try {
        const response = await store.category.create(data);
        response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(
              response?.body?.message || "Berhasil Tambah Kategori"
            )
          : message.error(response?.body?.message || "Gagal Tambah Kategori");
      } catch (e) {
        console.log(e, "apa errornya");
        message.error(e.response?.body?.message || "Gagal Tambah Kategori");
      }
      modalLoader.setLoading(false);
      store.category.visibleModalCategory = false;
      setIdData("");
      form.resetFields();
    }
  };

  return (
    <div>
      {store.ui.mediaQuery.isDesktop && (
        <Table
          style={{ textAlign: "center" }}
          columns={columns}
          dataSource={store.category.data}
          bordered
          pagination={{
            pageSize: store.category.pageSize,
            total: store.category.total_data,
            current: store.category.page + 1,
            showSizeChanger: true,
            simple: false,
          }}
          onChange={async (page) => {
            let pageNumber = page.current;
            store.category.pageSize = page.pageSize;
            store.category.page = pageNumber - 1;
            modalLoader.setLoading(true);
            await store.category.getData();
            modalLoader.setLoading(false);
          }}
        />
      )}
      {store.ui.mediaQuery.isMobile && (
        <List
          itemLayout="horizontal"
          position={"top"}
          pagination={{
            onChange: async (page, pageSize) => {
              store.category.pageSize = pageSize;
              store.category.page = page - 1;
              modalLoader.setLoading(true);
              await store.category.getData();
              modalLoader.setLoading(false);
            },
            pageSize: store.category.pageSize,
            total: store.category.total_data,
            current: store.category.page + 1,
            style: { marginBottom: "1rem", marginRight: "1rem" },
          }}
          dataSource={store.category.data}
          style={{ padding: 0 }}
          renderItem={(item) => {
            return (
              <div>
                <List.Item
                  key={item.id}
                  style={{
                    backgroundColor: "#ffffff",
                    paddingTop: 0,
                    paddingBottom: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <List.Item.Meta
                    className={[""].join(" ")}
                    title={item.code}
                    description={
                      <div style={{}}>
                        <p>
                          <small>Category : {item.name}</small>
                          <br />
                        </p>
                        <p></p>
                      </div>
                    }
                  />
                  <div style={{ marginRight: 16 }}>
                    <p
                      style={{
                        fontSize: 9,
                        margin: 0,
                      }}
                    >
                      {store.authentication.userData.role === "Admin" && (
                        <Button onClick={() => handleEditButton(item)}>
                          Edit
                        </Button>
                      )}
                    </p>
                  </div>
                </List.Item>
                <Divider plain style={{ margin: 0 }} />
              </div>
            );
          }}
        />
      )}
      <Modal
        visible={store.category.visibleModalCategory}
        title={idData ? "Edit Kategori" : "Buat Kategori Baru"}
        okText={idData ? "Edit" : "Buat"}
        cancelText="Batal"
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values, "isi form");
              handleSubmit(values);
              form.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="code"
            label="Code"
            rules={[{ required: true, message: "Please input name code!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input name category!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
