import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Tabs,
  Form,
  message,
  Modal,
  Select,
} from "antd";
import {
  FilterOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { SupplierComponent } from "../../component/SupplierComponent";
import { LINKS } from "../../routes/app";
import { ModalLoaderContext } from "../../utils/modal";

const { Search } = Input;

export const Supplier = observer(() => {
  const store = useStore();
  const modalLoader = useContext(ModalLoaderContext);

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        await store.supplier.getData();
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.SUPPLIER,
      name: <span style={{ fontWeight: "bold" }}>Supplier</span>,
    },
  ];

  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent data={routeData} />
      <Card>
        <Row style={{ marginBottom: 20 }}>
          {/* <Col span={12}>
            <Button>
              <FilterOutlined />
              Filter
            </Button>
          </Col> */}
          <Col span={24} style={{ textAlign: "right" }}>
            {/* <Search
              placeholder="input search text"
              style={{
                width: store.ui.mediaQuery.isMobile ? 160 : 200,
                marginRight: store.ui.mediaQuery.isMobile ? 10 : 10,
                marginBottom: store.ui.mediaQuery.isMobile ? 10 : 0,
              }}
              onSearch={(value) => console.log(value)}
            /> */}
            {store.authentication.userData.role !== "Customer Service" && (
              <Button
                onClick={() => (store.supplier.visibleModalSupplier = true)}
              >
                <PlusSquareOutlined /> New
              </Button>
            )}
          </Col>
        </Row>
        <SupplierComponent />
      </Card>
    </div>
  );
});
