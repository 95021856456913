import React, { useContext, useEffect } from "react";
import { Button, Card, Col, Input, message, Row } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { LINKS } from "../../routes/app";
import { SubcategoryComponent } from "../../component/SubcategoryComponent";
import { ModalLoaderContext } from "../../utils/modal";

const { Search } = Input;

export const Subcategory = observer(() => {
  const store = useStore();
  const modalLoader = useContext(ModalLoaderContext);

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        await getData();
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const getData = async () => {
    await store.category.getData();
    await store.subcategory.getData();
  };

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.SUBCATEGORY,
      name: <span style={{ fontWeight: "bold" }}>Sub Kategori</span>,
    },
  ];

  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent data={routeData} />
      <Card>
        <Row style={{ marginBottom: 20 }}>
          <Col span={24} style={{ textAlign: "right" }}>
            {store.authentication.userData.role !== "Customer Service" && (
              <Button
                onClick={() =>
                  (store.subcategory.visibleModalSubcategory = true)
                }
              >
                <PlusSquareOutlined /> New
              </Button>
            )}
          </Col>
        </Row>
        <SubcategoryComponent />
      </Card>
    </div>
  );
});
