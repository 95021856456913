import {Redirect, Route, Switch} from "react-router-dom";
import {Login} from "../pages/Login/Login";
import {PublicRoute} from "../component/PublicRoute";
import {App} from "../pages/App/App";
import {PrivateRoute} from "../component/PrivateRoute";

export const MainRoutes = (props) => {
    return (
        <Switch>
            <Route path="/" exact>
                <Redirect to={"/login"}/>
            </Route>
            <PublicRoute restricted={true} component={Login} path="/login" exact/>
            <PrivateRoute component={App} path="/app"/>
        </Switch>
    );
};
