import React, { useContext, useState } from "react";
import { Form, InputNumber, message, Modal, Upload } from "antd";
import { useStore } from "../../utils/useStore";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ModalLoaderContext } from "../../utils/modal";
import { appConfig } from "../../config/app";

export const PaybackModal = ({ initialData }) => {
  const [form] = Form.useForm();
  const store = useStore();
  const [image, setImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [responseFilename, setResponseFilename] = useState("");
  const [loading, setLoading] = useState(false);
  const modalLoader = useContext(ModalLoaderContext);

  const beforeUpload = (file) => {
    let isLt2M;
    let allowedFile = ["image/jpeg", "image/png"];
    let isValid = allowedFile.includes(file.type);
    if (!isValid) {
      message.error("You can only upload Image file!");
    }
    isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    return isValid && isLt2M ? true : Upload.LIST_IGNORE;
  };

  const uploadHandler = async (args) => {
    const file = args.file;
    const res = await store.payback.uploadImages(file);
    setImage(`${appConfig.apiUrl}/config/image/${res.body.filename}`);
    setResponseFilename(res.body.filename);
    setFileList([
      {
        uid: "-1",
        name: res.body.filename,
        status: "done",
        url: `${appConfig.apiUrl}/config/image/${res.body.filename}`,
      },
    ]);
    setLoading(false);
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Click to Upload</div>
    </div>
  );

  const handleSubmit = async (data) => {
    modalLoader.setLoading(true);
    try {
      const request = {
        ...data,
        destination: store.authentication.profileData.superior?.id,
        image_prove: responseFilename,
      };
      const response = await store.payback.create(request);
      message.success(response?.body?.message || "Success Add Created");
    } catch (e) {
      if (e.response?.body?.message) {
        message.error(e.response.body.message);
        return;
      }
      message.error(e.message);
    }
    modalLoader.setLoading(false);
    store.payback.visibleModalPayback = false;
    form.resetFields();
    setImage("");
    setFileList([]);
    setPreviewImage("");
    setResponseFilename("");
  };

  const handleCancel = () => {
    form.resetFields();
    setImage("");
    setFileList([]);
    setPreviewImage("");
    setResponseFilename("");
    store.payback.visibleModalPayback = false;
  };

  return (
    <Modal
      visible={store.payback.visibleModalPayback}
      title={"Buat Pembayaran Baru"}
      okText={"Buat"}
      cancelText="Batal"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={initialData}
      >
        <Form.Item label="Upload Gambar" name="image_prove">
          <div>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={(file) => {
                setPreviewImage(file.url || file.filename);
              }}
              showUploadList={true}
              onChange={handleChange}
              beforeUpload={(file) => beforeUpload(file)}
              customRequest={(args) => uploadHandler(args)}
              onRemove={(file) => {
                setImage("");
                setLoading(false);
                setFileList([]);
              }}
            >
              {image === "" ? uploadButton : null}
            </Upload>
            <h5
              style={{
                marginTop: 12,
                color: "rgba(0, 0, 0, 0.45)",
              }}
            >
              Max size of file 2 MB
            </h5>
          </div>
        </Form.Item>
        <Form.Item
          name="amount"
          label="amount"
          rules={[{ required: true, message: "Please input Amount!" }]}
        >
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) =>
              `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\Rp.\s?|(,*)/g, "")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
