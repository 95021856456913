import {makeAutoObservable} from "mobx";
import {http} from "../utils/http";

export class Commission {
    page = 0;
    pageSize = 10
    data = [];
    total_data = 0;
    filterCategory = null;
    visibleModalCommission = false;

    pageCategories = 0;
    pageSizeCategories = 10
    dataCategories = [];
    total_dataCategories = 0;

    pageSubCategories = 0;
    pageSizeSubCategories = 10
    dataSubCategories = [];
    total_dataSubCategories = 0;

    constructor(ctx) {
        this.ctx = ctx;
        makeAutoObservable(this);
    }

    async getData() {
        try {
            const response = await http.get(`/config/commission?page=${this.page}&pageSize=${this.pageSize}`);
            this.data = response.body.data.map((item, idx) => {
                item.key = idx;
                return item
            }) ?? []

            this.total_data = response?.body?.count ?? 0
        } catch (e) {
            console.error(e);
        }
    }

    async update(id, data) {
        try {
            const response = await http.put(`/config/commission/${id}`).send(data);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async delete(id) {
        try {
            const response = await http.del(`/product/${id}`);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }
}


