import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  List,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Select,
  Typography,
  DatePicker,
  Form,
} from "antd";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { LINKS } from "../../routes/app";
import { ModalLoaderContext } from "../../utils/modal";
import { appConfig } from "../../config/app";
import { capitalize } from "lodash";
import { PAYBACK_STATUS } from "../../constants/payback";
import moment from "moment";

export const Payback = observer(() => {
  const { Option } = Select;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const store = useStore();
  const modalLoader = useContext(ModalLoaderContext);
  const [filterMembership, setFilterMembership] = useState([]);
  const [filterSubCategories, setFilterSubCategories] = useState([]);

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        await store.membership.getData();
        await store.payback.getDataConfirmation();
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const columns = [
    {
      title: "Nama",
      dataIndex: "userData_name",
      key: "userData_name",
    },
    {
      title: "Gambar",
      dataIndex: "image_prove",
      key: "image_prove",
      render: (text, record) => (
        <Image
          src={`${appConfig.apiUrl}/config/image/${text}`}
          style={{ width: "5vw" }}
          alt={record.id}
        />
      ),
    },
    {
      title: "Nominal",
      dataIndex: "amount",
      key: "amount",
      width: "20%",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Tindakan",
      dataIndex: "amount",
      key: "action",
      width: "10%",
      render: (text, record) =>
        PAYBACK_STATUS[record.status] === PAYBACK_STATUS[0] ? (
          <Space size="middle">
            <Button
              onClick={async () => {
                Modal.confirm({
                  title: `Are you sure Accept this submission?`,
                  icon: <CheckOutlined />,
                  okText: "Accept",
                  cancelText: "Batal",
                  okType: "primary",
                  onOk() {
                    handleAction(record.id, "accept");
                  },
                  onCancel() {
                    console.log("Batal");
                  },
                });
              }}
              icon={<CheckCircleOutlined />}
              style={{
                backgroundColor: "#1bb91d",
                color: "#fff",
                borderColor: "#1bb91d",
              }}
            >
              Accept
            </Button>
            <Button
              onClick={async () => {
                Modal.confirm({
                  title: `Are you sure Reject this submission?`,
                  icon: <StopOutlined />,
                  okText: "Reject",
                  cancelText: "Batal",
                  okType: "primary",
                  onOk() {
                    handleAction(record.id, "reject");
                  },
                  onCancel() {
                    console.log("Batal");
                  },
                });
              }}
              icon={<CloseOutlined />}
              style={{
                backgroundColor: "#ff1c1c",
                color: "#fff",
                borderColor: "#ff1c1c",
              }}
            >
              Reject
            </Button>
          </Space>
        ) : (
          <Tag
            color={
              PAYBACK_STATUS[record.status] === PAYBACK_STATUS[3]
                ? "cyan"
                : "red"
            }
            style={{ color: "#4F566B" }}
          >
            {PAYBACK_STATUS[record.status]}
          </Tag>
        ),
    },
  ];

  if (store.authentication.userData.role === "Retail") columns.pop();

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.PAYBACK,
      name: <span style={{ fontWeight: "bold" }}>Konfirmasi Pembayaran</span>,
    },
  ];

  const dataRoute = [
    {
      route: LINKS.PAYBACK,
      name: "Konfirmasi Pembayaran",
    },
  ];

  const handleAction = async (id, type) => {
    modalLoader.setLoading(true);
    try {
      const response = await store.payback.confirmPayback(id, type);
      console.log(response);
      response.body.statusCode !== 201 && response.body.statusCode !== 200
        ? message.error(
            response?.body?.message || `Failed ${capitalize(type)} Payback`
          )
        : message.success(
            response?.body?.message || `Success ${capitalize(type)} Payback`
          );
    } catch (e) {
      console.error(e, "apa errornya");
      message.error(
        e.response?.body?.message || "Failed Handler Action Payback"
      );
    }
    modalLoader.setLoading(false);
  };

  const handleRemoveFilter = async () => {
    store.payback.filterMembership = null;
    store.payback.filterStart = null;
    store.payback.filterEnd = null;
    setFilterMembership([]);
    form.resetFields();
    await store.payback.getDataConfirmation();
    store.payback.visibleModalFilterPayback = false;
  };

  const handleCancelFilter = async () => {
    //setFilterMembership([]);
    //form.resetFields();
    store.payback.filterMembership = null;
    store.payback.filterStart = null;
    store.payback.filterEnd = null;
    store.payback.visibleModalFilterPayback = false;
    await store.payback.getDataConfirmation();
  };

  const handleSubmitFilter = async () => {
    const data = form.getFieldsValue();
    //console.log(data);
    store.payback.filterMembership = filterMembership;
    store.payback.filterStart = data.start_date
      ? moment(data.start_date).format("YYYY-MM-DD") + " 00:00:00"
      : null;
    store.payback.filterEnd = data.end_date
      ? moment(data.end_date).format("YYYY-MM-DD") + " 23:59:59"
      : null;
    modalLoader.setLoading(true);
    await store.payback.getDataConfirmation();
    modalLoader.setLoading(false);
    store.payback.visibleModalFilterPayback = false;
  };

  const footerLayoutFilter = [
    <Button
      key={"remove"}
      onClick={handleRemoveFilter}
      style={{
        backgroundColor: "#e74e5e",
        color: "#fff",
      }}
    >
      Hapus Filter
    </Button>,
    <Button key={"cancel"} onClick={handleCancelFilter}>
      Batal
    </Button>,
    <Button
      key={"submit"}
      onClick={handleSubmitFilter}
      style={{
        backgroundColor: "#4e79e7",
        color: "#fff",
      }}
    >
      Terapkan
    </Button>,
  ];
  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent
        data={
          store.authentication.userData.role === "Admin" ||
          store.authentication.userData.role === "Customer Service"
            ? routeData
            : dataRoute
        }
      />
      <Card>
        <div>
          <Row style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Button
                onClick={() => {
                  store.payback.visibleModalFilterPayback = true;
                }}
              >
                <FilterOutlined />
                Filter
              </Button>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              {/* <Search
                placeholder="input search text"
                style={{
                  width: store.ui.mediaQuery.isMobile ? 160 : 200,
                  marginRight: store.ui.mediaQuery.isMobile ? 0 : 10,
                  marginBottom: store.ui.mediaQuery.isMobile ? 10 : 0,
                }}
              /> */}
            </Col>
          </Row>
          {store.ui.mediaQuery.isDesktop && (
            <Table
              key="1"
              hasEmpty
              columns={columns}
              dataSource={store.payback.dataConfirmation}
              bordered
              pagination={{
                pageSize: store.payback.pageSizeConfirmation,
                total: store.payback.totalDataConfirmation,
                current: store.payback.pageSizeConfirmation + 1,
                showSizeChanger: true,
                simple: false,
              }}
              onChange={async (page) => {
                let pageNumber = page.current;
                store.payback.pageSizeConfirmation = page.pageSize;
                store.payback.pageConfirmation = pageNumber - 1;
                modalLoader.setLoading(true);
                await store.payback.getDataConfirmation();
                modalLoader.setLoading(false);
              }}
            />
          )}

          {store.ui.mediaQuery.isMobile && (
            <List
              itemLayout="horizontal"
              position={"top"}
              pagination={{
                onChange: async (page) => {
                  store.payback.pageSizeConfirmation = page.pageSize;
                  store.payback.pageConfirmation = page.current - 1;
                  modalLoader.setLoading(true);
                  await store.payback.getDataConfirmation();
                  modalLoader.setLoading(false);
                },
                pageSize: store.payback.pageSizeConfirmation,
                total: store.payback.totalDataConfirmation,
                current: store.payback.pageConfirmation + 1,
                style: { marginBottom: "1rem", marginRight: "1rem" },
              }}
              dataSource={store.payback.dataConfirmation}
              style={{ padding: 0 }}
              renderItem={(item) => {
                return (
                  <div>
                    <List.Item
                      key={item.id}
                      style={{
                        backgroundColor: "#ffffff",
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <List.Item.Meta
                        className={[""].join(" ")}
                        title={item.userData_name}
                        description={
                          <div style={{}}>
                            <p>
                              <small>Amount: {item.amount}</small>
                              <br />
                              {PAYBACK_STATUS[item.status] ===
                              PAYBACK_STATUS[0] ? (
                                <Space size="middle">
                                  <Button
                                    onClick={async () => {
                                      Modal.confirm({
                                        title: `Are you sure Accept this submission?`,
                                        icon: <CheckOutlined />,
                                        okText: "Accept",
                                        cancelText: "Batal",
                                        okType: "primary",
                                        onOk() {
                                          handleAction(item.id, "accept");
                                        },
                                        onCancel() {
                                          console.log("Batal");
                                        },
                                      });
                                    }}
                                    icon={<CheckCircleOutlined />}
                                    style={{
                                      backgroundColor: "#1bb91d",
                                      color: "#fff",
                                      borderColor: "#1bb91d",
                                    }}
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    onClick={async () => {
                                      Modal.confirm({
                                        title: `Are you sure Reject this submission?`,
                                        icon: <StopOutlined />,
                                        okText: "Reject",
                                        cancelText: "Batal",
                                        okType: "primary",
                                        onOk() {
                                          handleAction(item.id, "reject");
                                        },
                                        onCancel() {
                                          console.log("Batal");
                                        },
                                      });
                                    }}
                                    icon={<CloseOutlined />}
                                    style={{
                                      backgroundColor: "#ff1c1c",
                                      color: "#fff",
                                      borderColor: "#ff1c1c",
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </Space>
                              ) : (
                                <Tag
                                  color={
                                    PAYBACK_STATUS[item.status] ===
                                    PAYBACK_STATUS[3]
                                      ? "cyan"
                                      : "red"
                                  }
                                  style={{ color: "#4F566B" }}
                                >
                                  {PAYBACK_STATUS[item.status]}
                                </Tag>
                              )}
                            </p>
                          </div>
                        }
                      />
                      <div style={{ marginRight: 16 }}>
                        <p
                          style={{
                            fontSize: 9,
                            margin: 0,
                          }}
                        >
                          <Image
                            src={`${appConfig.apiUrl}/config/image/${item.image_prove}`}
                            style={{ width: "10vw" }}
                          />
                        </p>
                      </div>
                    </List.Item>
                    <Divider plain style={{ margin: 0 }} />
                  </div>
                );
              }}
            />
          )}
        </div>
      </Card>
      <Modal
        visible={store.payback.visibleModalFilterPayback}
        title={"Filter"}
        footer={footerLayoutFilter}
        onCancel={async () => {
          //form.resetFields();
          //setFilterMembership([]);
          store.payback.filterMembership = null;
          store.payback.filterStart = null;
          store.payback.filterEnd = null;
          store.payback.visibleModalFilterPayback = false;
          await store.payback.getDataConfirmation();
        }}
      >
        <Row>
          <Col span={24}>
            <Title level={5} type={"secondary"} strong>
              Pengirim
            </Title>
            <Form layout="vertical" name="filter" form={form}>
              <Select
                mode={"multiple"}
                placeholder="Pilih Pengirim"
                onChange={(val) => {
                  setFilterMembership(val);
                }}
                style={{ marginBottom: "20px", width: "100%" }}
                value={filterMembership}
              >
                {store.payback.dataConfirmation.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.userData_name}
                  </Option>
                ))}
              </Select>
              <Form.Item
                name="start_date"
                label="Dari Tanggal"
                rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="end_date"
                label="Sampai Tanggal"
                rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
