import {makeAutoObservable} from "mobx";
import {http} from "../utils/http";

export class User {
    data = [];

    constructor(ctx) {
        this.ctx = ctx;
        makeAutoObservable(this);
    }

    async getData() {
        try {
            this.data = (await http.get('/user')).body.data;
        } catch (e) {
            console.error(e);
        }
    }
}


