import React, { useContext, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Space,
  Table,
  Tag,
  InputNumber,
} from "antd";
import { observer } from "mobx-react-lite";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useStore } from "../utils/useStore";
import { LINKS } from "../routes/app";
import { TopupsaldoModal } from "./TopupsaldoModal";
import { ModalLoaderContext } from "../utils/modal";

export const SupplierComponent = observer((props) => {
  const store = useStore();
  const [form] = Form.useForm();
  const history = useHistory();
  const [idData, setIdData] = useState("");
  const modalLoader = useContext(ModalLoaderContext);

  const handleEditButton = (data) => {
    console.log(data, "isi data");
    form.setFieldsValue({
      name: data.name,
      code: data.code,
      status: data.status,
    });
    store.supplier.visibleModalSupplier = true;
    setIdData(data.id);
  };

  const handleTopup = (data) => {
    console.log(data, "isi data");
    form.setFieldsValue({
      supplier: data.code,
    });
    store.supplier.visibleModalTransaction = true;
    store.supplier.code = data.code;
  };

  const changeStatus = async (id, isActive) => {
    const status = isActive ? "inactive" : "active";
    const status2 = isActive ? "Inactivating" : "Activating";
    try {
      modalLoader.setLoading(true);
      const response = await store.supplier.changeStatus(id, status);
      modalLoader.setLoading(false);
      response?.body?.statusCode === 201
          ? message.success(`Success ${status2} Supplier`)
          : message.error(`Failed ${status2} Supplier`);
    } catch (err) {
      modalLoader.setLoading(false);
      console.log("error", err);
      message.error(`Failed ${status2} Supplier`);
    }
  };

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
      width: "5%",
    },
    {
      title: "Saldo di Supplier",
      dataIndex: ["coa", "amount"],
      key: ["coa", "amount"],
      width: "20%",
      render: (text, record) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          }).format(text),
    },
    {
      title: "Saldo di System",
      dataIndex: ["coa_undistribute", "amount"],
      key: ["coa_undistribute", "amount"],
      width: "20%",
      render: (text, record) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          }).format(text),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "5%",
      render: (text, record) => (
          <Tag
              color={record?.status === true ? "processing" : "#E3E8EE"}
              style={{ color: "#4F566B" }}
          >
            {record?.status === true ? " ACTIVE" : "INACTIVE"}
          </Tag>
      ),
    },
    {
      title: "Tindakan",
      key: "action",
      width: "20%",
      render: (text, record) => (
          <Space size="middle">
            <Button
                type={record?.status === true ? "danger" : "primary"}
                onClick={() => changeStatus(record?.id, record?.status)}
            >
              {record?.status === true ? "INACTIVE" : "ACTIVE"}
            </Button>
            <Button onClick={() => handleTopup(record)}> Top Up Saldo</Button>
            <Button onClick={() => handleEditButton(record)}>Edit</Button>
          </Space>
      ),
    },
  ];

  if (store.authentication.userData.role === "Customer Service") columns.pop();

  const deleteData = async (id) => {
    try {
      console.log(id);
      modalLoader.setLoading(true);
      await store.supplier.delete(id);
      modalLoader.setLoading(false);
      message.success("Data Berhasil Dihapus");
      history.push(LINKS.PRODUCT);
    } catch (err) {
      modalLoader.setLoading(false);
      console.log("error", err);
      message.error("Gagal menghapus");
    }
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure delete this record?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "Batal",
      onOk() {
        return deleteData(id);
      },
      onCancel() {
        console.log("Batal");
      },
    });
  };

  const handleCancelTransaction = () => {
    store.supplier.visibleModalTransaction = false;
  };

  const handleSubmitTransaction = async (data) => {
    console.log(data, ':)')
    try {
      modalLoader.setLoading(true);
      const response = await store.supplier.createTransaction(data);
      message.success(response?.body?.message || "Success Top Up");
    } catch (e) {
      console.log(e, "apa errornya");
      message.error(e.response?.body?.message || "Failed Top Up");
    }

    modalLoader.setLoading(false);
    store.supplier.visibleModalTransaction = false;
    form.resetFields();
  };

  const handleCancel = () => {
    setIdData("");
    store.supplier.visibleModalSupplier = false;
  };

  const handleSubmit = async (data) => {
    console.log(data, "isi data2");
    if (idData !== "") {
      try {
        modalLoader.setLoading(true);
        const response = await store.supplier.update(idData, data);
        response?.body?.statusCode === 201 || response?.body?.statusCode === 200
            ? message.success(
                response?.body?.message || "Berhasil Ubah Data Supplier"
            )
            : message.error(
                response?.body?.message || "Gagal Ubah Data Supplier"
            );
      } catch (e) {
        modalLoader.setLoading(false);
        message.error(e.response?.body?.message || "Gagal Ubah Data Supplier");
      }

      modalLoader.setLoading(false);
      store.supplier.visibleModalSupplier = false;
      setIdData("");
      form.resetFields();
    } else {
      try {
        const response = await store.supplier.create(data);
        if (response.status === 201) {
          message.success(
              response?.body?.message || "Berhasil Tambah Supplier"
          );
          await store.supplier.getData();
        } else {
          message.error(response?.body?.error || "Gagal Tambah Supplier", 3);
        }
      } catch (e) {
        console.log("testingan");
        console.log(e.response, "testingan");
        console.log(e.result, "testingan1");
        if (e.response?.body?.error) {
          message.error(
              e.response.body.error
                  ? "Supplier Tersebut Sudah Terdaftar"
                  : e.response.body.error
          );
          modalLoader.setLoading(false);
          store.supplier.visibleModalSupplier = false;
          return;
        }
        console.log(e, "apa errornya");
        message.error("Gagal Tambah Supplier");
      }

      modalLoader.setLoading(false);
      store.supplier.visibleModalSupplier = false;
      setIdData("");
      form.resetFields();
      await store.supplier.getData();
    }
  };

  return (
      <div>
        {store.ui.mediaQuery.isDesktop && (
            <Table
                style={{ textAlign: "center" }}
                columns={columns}
                dataSource={store.supplier.data}
                bordered
                pagination={{
                  pageSize: store.supplier.pageSize,
                  total: store.supplier.total_data,
                  current: store.supplier.page + 1,
                  showSizeChanger: true,
                  simple: false,
                }}
                onChange={async (page) => {
                  let pageNumber = page.current;
                  store.supplier.pageSize = page.pageSize;
                  store.supplier.page = pageNumber - 1;
                  modalLoader.setLoading(true);
                  await store.supplier.getData();
                  modalLoader.setLoading(false);
                }}
            />
        )}
        {store.ui.mediaQuery.isMobile && (
            <List
                itemLayout="horizontal"
                position={"top"}
                pagination={{
                  onChange: async (page, pageSize) => {
                    store.supplier.pageSize = pageSize;
                    store.supplier.page = page - 1;
                    modalLoader.setLoading(true);
                    await store.supplier.getData();
                    modalLoader.setLoading(false);
                  },
                  pageSize: store.supplier.pageSize,
                  total: store.supplier.total_data,
                  current: store.supplier.page + 1,
                  style: { marginBottom: "1rem", marginRight: "1rem" },
                }}
                dataSource={store.supplier.data}
                style={{ padding: 0 }}
                renderItem={(item) => {
                  return (
                      <div>
                        <List.Item
                            key={item.id}
                            style={{
                              backgroundColor: "#ffffff",
                              paddingTop: 0,
                              paddingBottom: 0,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                        >
                          <List.Item.Meta
                              className={[""].join(" ")}
                              title={item.code}
                              description={
                                <div style={{}}>
                                  <p>
                                    <small>Name : {item.name}</small> <br />
                                    <small>Saldo Supplier : {item.coa.amount}</small>
                                    <br />
                                    <small>
                                      Saldo System : {item.coa_undistribute.amount}
                                    </small>
                                    <br />
                                    <Button
                                        style={{
                                          marginRight: 10,
                                        }}
                                        type={item?.status === true ? "danger" : "primary"}
                                        onClick={() => changeStatus(item?.id, item?.status)}
                                    >
                                      {item?.status === true ? "INACTIVE" : "ACTIVE"}
                                    </Button>
                                    <Button
                                        onClick={() => handleTopup(item)}
                                        style={{
                                          marginRight: 10,
                                        }}
                                    >
                                      Top Up Saldo
                                    </Button>
                                    <Button
                                        onClick={() => handleEditButton(item)}
                                        style={{
                                          marginBottom: 5,
                                        }}
                                    >
                                      Edit
                                    </Button>
                                    <Tag
                                        color={
                                          item?.status === true ? "processing" : "#E3E8EE"
                                        }
                                        style={{ color: "#4F566B" }}
                                    >
                                      {item?.status === true ? " ACTIVE" : "INACTIVE"}
                                    </Tag>
                                  </p>
                                  <p></p>
                                </div>
                              }
                          />
                        </List.Item>
                        <Divider plain style={{ margin: 0 }} />
                      </div>
                  );
                }}
            />
        )}
        <Modal
            visible={store.supplier.visibleModalSupplier}
            title={idData ? "Edit Supplier" : "Buat Supplier Baru"}
            okText={idData ? "Edit" : "Buat"}
            cancelText="Batal"
            onCancel={() => {
              form.resetFields();
              handleCancel();
            }}
            onOk={() => {
              form
                  .validateFields()
                  .then((values) => {
                    console.log(values, "isi form");
                    handleSubmit(values);
                    form.resetFields();
                  })
                  .catch((info) => {
                    console.error("Validate Failed:", info);
                  });
            }}
        >
          <Form form={form} layout="vertical">
            <Form.Item
                name="irs_id"
                label="Id"
                rules={[{ required: true, message: "Please input name!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please input name!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                name="code"
                label="Code"
                rules={[{ required: true, message: "Please input code!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                name="url"
                label="Url"
                rules={[{ required: true, message: "Please input url!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                name="irs_pin"
                label="Pin"
                rules={[{ required: true, message: "Please input pin!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                name="irs_user"
                label="Username"
                rules={[{ required: true, message: "Please input username!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                name="irs_pass"
                label="Password"
                rules={[{ required: true, message: "Please input password!" }]}
            >
              <Input />
            </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={store.supplier.visibleModalTransaction}
        title="Top Up Saldo"
        okText="Top Up"
        cancelText="Batal"
        onCancel={() => {
          form.resetFields();
          handleCancelTransaction();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values, "isi form");
              handleSubmitTransaction(values);
              form.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="supplier"
            label="Supplier"
            rules={[{ required: true, message: "Please input supplier!" }]}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Nominal"
            rules={[{ required: true, message: "Please input amount!" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\Rp.\s?|(,*)/g, "")}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
