import {makeAutoObservable} from "mobx";
import {http} from "../utils/http";

export class Subcategory {
    page = 0;
    pageSize = 10
    data = [];
    total_data = 0;
    filterCategory = null;
    visibleModalSubcategory = false;

    pageCategories = 0;
    pageSizeCategories = 10
    dataCategories = [];
    total_dataCategories = 0;

    pageSubCategories = 0;
    pageSizeSubCategories = 10
    dataSubCategories = [];
    total_dataSubCategories = 0;

    constructor(ctx) {
        this.ctx = ctx;
        makeAutoObservable(this);
    }

    async getData() {
        try {
            const response = await http.get(`/product/sub-categories?page=${this.page}&pageSize=${this.pageSize}`);
            console.log(response)
            this.data = response.body.data.map((item, idx) => {
            item.key = idx;
            item.categoryName = item.category.name;
            return item
        }) ?? []

            this.total_data = response?.body?.count ?? 0
        } catch (e) {
            console.error(e);
        }
    }

    async getDataSubCategories() {
        try {
            const response = await http.get(`/product/sub-categories?page=${this.pageSubCategories}&pageSize=${this.pageSizeSubCategories}`);
            this.dataSubCategories = response.body.data.map((item, idx) => {
            item.key = idx;
            return item
        }) ?? []

            this.total_dataSubCategories = response.body.count ?? 0
        } catch (e) {
            console.error(e);
        }
    }

    async create(data) {
        try {
            const response = await http.post('/product/sub-categories').send(data);
            //await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async update(id, data) {
        try {
            const response = await http.put(`/product/sub-categories/${id}`).send(data);
            //await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async delete(id) {
        try {
            const response = await http.del(`/product/${id}`);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }
}


