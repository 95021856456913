import React, { useContext, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Typography,
  Tag,
  List,
  Button,
  Divider,
} from "antd";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { LINKS } from "../../routes/app";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { format, parseISO } from "date-fns";
import { ModalLoaderContext } from "../../utils/modal";
import { useParams } from "react-router-dom";

const { Title, Text } = Typography;

export const ProductDetail = observer(() => {
  const store = useStore();
  const { id } = useParams();
  const modalLoader = useContext(ModalLoaderContext);

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.PRODUCT,
      name: <span style={{ fontWeight: "bold" }}>Produk</span>,
    },
    {
      route: LINKS.PRODUCT_DETAIL.replace(":id", `${id}`),
      name: <span style={{ fontWeight: "bold" }}>Detail Produk</span>,
    },
  ];

  useEffect(() => {
    (async () => {
      modalLoader.setLoading(true);
      await Promise.allSettled([
        store.product.getPriceHistoryByProduct(id),
        store.product.getDetailProduct(id),
      ]);
      modalLoader.setLoading(false);
    })();
  }, []);

  const columns = [
    {
      title: "Markup Price",
      dataIndex: "mark_up_price",
      key: "mark_up_price",
      render: (text) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          }).format(text),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          }).format(text),
    },
    {
      title: "Biaya Admin",
      dataIndex: "admin_price",
      key: "admin_price",
      render: (text) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          }).format(text),
    }, {
      title: "Partner fee",
      dataIndex: "partner_fee",
      key: "partner_fee",
      render: (text) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          }).format(text),
    },
    {
      title: "Tanggal Berlaku",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => {
        return (
            <Text>{text ? format(parseISO(text), "dd MMMM yyyy") : "-"}</Text>
        );
      },
    },
    {
      title: "Tanggal Berakhir",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => {
        return (
            <Text>
              {text ? format(parseISO(text), "dd MMMM yyyy") : "Sampai Sekarang"}
            </Text>
        );
      },
    },
  ];

  if (store.authentication.userData.role === "Admin Partner") delete columns[0];
  if (store.authentication.userData.role === "Admin Partner") delete columns[3];

  const styleSaldoTitle = store.ui.mediaQuery.isDesktop
      ? {
        display: "flex",
        justifyContent: "center",
      }
      : { fontSize: "0.75rem" };
  const styleSaldoContent = store.ui.mediaQuery.isDesktop
      ? {
        fontSize: "1.25rem",
        display: "flex",
        justifyContent: "center",
      }
      : null;

  return (
      <div className={["ppob-container"].join(" ")}>
        <BreadcumbComponent data={routeData} />
        <Card>
          <Title strong>Product Detail</Title>
          <Row style={{ marginBottom: 20 }}>
            <Col lg={12} xs={24}>
              <Row>
                <Col span={12}>
                  <Text strong>Kode</Text>
                </Col>
                <Col span={12}>
                  <Text>{store.product?.dataDetailProduct?.code}</Text>
                </Col>
                <Col span={12}>
                  <Text strong>Nama Produk</Text>
                </Col>
                <Col span={10}>
                  <Text>{store.product?.dataDetailProduct?.name}</Text>
                </Col>
                <Col span={12}>
                  <Text strong>Supplier</Text>
                </Col>
                <Col span={12}>
                  <Text>{store.product?.dataDetailProduct?.supplier?.name}</Text>
                </Col>
                <Col span={12}>
                  <Text strong>Status</Text>
                </Col>
                <Col span={12}>
                  <Text>{store.product?.dataDetailProduct?.status}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div>
                <Title strong level={3}>
                  Product Price History
                </Title>
                {store.ui.mediaQuery.isDesktop && (
                    <Table
                        columns={columns}
                        dataSource={store.product.dataPriceHistory}
                        bordered
                        pagination={{
                          pageSize: store.product.pageSizePriceHistory,
                          total: store.product.totalDataPriceHistory,
                          current: store.product.pagePriceHistory + 1,
                          showSizeChanger: true,
                          simple: false,
                        }}
                        onChange={async (page) => {
                          let pageNumber = page.current;
                          store.product.pageSizePriceHistory = page.pageSize;
                          store.product.pagePriceHistory = pageNumber - 1;
                          modalLoader.setLoading(true);
                          await store.product.getPriceHistoryByProduct(id);
                          modalLoader.setLoading(false);
                        }}
                    />
                )}
                {store.ui.mediaQuery.isMobile && (
                    <List
                        itemLayout="horizontal"
                        position={"top"}
                        pagination={{
                          onChange: async (page, pageSize) => {
                            store.product.pageSizePriceHistory = pageSize;
                            store.product.pagePriceHistory = page - 1;
                            modalLoader.setLoading(true);
                            await store.product.getPriceHistoryByProduct(id);
                            modalLoader.setLoading(false);
                          },
                          pageSize: store.product.pageSizePriceHistory,
                          total: store.product.totalDataPriceHistory,
                          current: store.product.pagePriceHistory + 1,
                          style: { marginBottom: "1rem", marginRight: "1rem" },
                        }}
                        dataSource={store.product.dataPriceHistory}
                        style={{ padding: 0 }}
                        renderItem={(item) => {
                          return (
                              <div>
                                <List.Item
                                    key={item.id}
                                    style={{
                                      backgroundColor: "#ffffff",
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                >
                                  <List.Item.Meta
                                      className={[""].join(" ")}
                                      //title={item.mark_up_price}
                                      description={
                                        <div style={{}}>
                                          <p>
                                            {store.authentication.userData.role === "Admin" ?
                                                `<small>
                                    Markup Price :{" "}
                                    {new Intl.NumberFormat("id-ID", {
                                      style: "currency",
                                      currency: "IDR",
                                    }).format(item.mark_up_price)}
                                  </small>{" "}` : ''}
                                            <br />
                                            <small>
                                              Price:{" "}
                                              {new Intl.NumberFormat("id-ID", {
                                                style: "currency",
                                                currency: "IDR",
                                              }).format(item.price)}
                                            </small>{" "}
                                            <br />
                                            <small>
                                              {" "}
                                              Tanggal Berlaku :
                                              {item.startDate
                                                  ? format(
                                                      parseISO(item.startDate),
                                                      "dd MMMM yyyy"
                                                  )
                                                  : "-"}
                                            </small>{" "}
                                            <br />
                                            <small>
                                              Tanggal Berakhir :{" "}
                                              {item.endDate
                                                  ? format(
                                                      parseISO(item.endDate),
                                                      "dd MMMM yyyy"
                                                  )
                                                  : "Sampai Sekarang"}
                                            </small>{" "}
                                            <br />
                                          </p>
                                          <p></p>
                                        </div>
                                      }
                                  />
                                  {/* <div style={{ marginRight: 16 }}>
                            <p
                              style={{
                                fontSize: 9,
                                margin: 0,
                              }}
                            >
                              <Tag
                                color={
                                  item?.product_status === "ACTIVE"
                                    ? "blue"
                                    : "#E3E8EE"
                                }
                                style={{ color: "#4F566B" }}
                              >
                                {item?.product_status === "ACTIVE"
                                  ? " Tersedia"
                                  : "Tidak"}
                              </Tag>
                            </p>
                          </div> */}
                                </List.Item>
                                <Divider plain style={{ margin: 0 }} />
                              </div>
                          );
                        }}
                    />
                )}
              </div>
            </Col>
          </Row>
          <div />
        </Card>
      </div>
  );
});
