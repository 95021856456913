import React, {useEffect, useState} from "react";
import {DesktopLayout} from "./DesktopLayout";
import {useMediaQuery} from 'react-responsive';
import {useStore} from "../../utils/useStore";
import {ModalLoader} from "../../component/Modal/ModalLoader";
import {ModalLoaderContext} from "../../utils/modal";

export const App = () => {
  const store = useStore();
  const mediaQuery = {
    isDesktop: useMediaQuery({minWidth: 1024}),
    isTablet: useMediaQuery({minWidth: 768, maxWidth: 1023}),
    isMobile: useMediaQuery({maxWidth: 767}),
    isNotMobile: useMediaQuery({minWidth: 768}),
  };

  const [modalLoading, setModalLoading] = useState(false);
  const [modalText, setModalText] = useState(undefined);
  const modalContextValue = {
    setLoading: setModalLoading,
    setText: setModalText,
  };

  useEffect(() => {
    store.ui.setMediaQuery(mediaQuery);
  });

  return (
      <>
        <ModalLoader isOpen={modalLoading} text={modalText}/>
        <ModalLoaderContext.Provider value={modalContextValue}>
          <DesktopLayout/>
        </ModalLoaderContext.Provider>
      </>
  );
};
