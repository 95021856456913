import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Select,
  InputNumber,
  Row,
  Col,
  Typography,
  Upload,
  message,
} from "antd";
import { useStore } from "../../utils/useStore";
import { appConfig } from "../../config/app";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

export const MembershipModal = ({
  visible,
  onCreate,
  onCancel,
  initialData,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const store = useStore();
  const [value, setValue] = useState();
  const [image, setImage] = useState("");
  const [imageStore, setImageStore] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileStore, setFileStore] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewImageStore, setPreviewImageStore] = useState("");
  const [responseFilename, setResponseFilename] = useState("");
  const [responseFilenameStore, setResponseFilenameStore] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStore, setLoadingStore] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage1, setPreviewImage1] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    if (initialData.id) {
      setFileList([
        {
          url: `${appConfig.apiUrl}/config/image/${initialData.image_identity}`,
        },
      ]);
      setFileStore([
        {
          url: `${appConfig.apiUrl}/config/image/${initialData.image_store}`,
        },
      ]);
      setImage(
        `${appConfig.apiUrl}/config/image/${initialData.image_identity}`
      );
      setImageStore(
        `${appConfig.apiUrl}/config/image/${initialData.image_store}`
      );
    }
    return () => {};
  }, [initialData]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreviewData = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage1(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel1 = () => {
    setPreviewVisible(false);
  };

  const beforeUpload = (file) => {
    let isLt2M;
    let allowedFile = ["image/jpeg", "image/png"];
    let isValid = allowedFile.includes(file.type);
    if (!isValid) {
      message.error("You can only upload Image file!");
    }
    isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    return isValid && isLt2M ? true : Upload.LIST_IGNORE;
  };

  const beforeUploadStore = (file) => {
    let isLt2M;
    let allowedFile = ["image/jpeg", "image/png"];
    let isValid = allowedFile.includes(file.type);
    if (!isValid) {
      message.error("You can only upload Image file!");
    }
    isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    return isValid && isLt2M ? true : Upload.LIST_IGNORE;
  };

  const uploadHandler = async (args) => {
    const file = args.file;
    const res = await store.payback.uploadImages(file);
    console.log(res, "ini respon 1");
    setImage(`${appConfig.apiUrl}/config/image/${res.body.filename}`);
    console.log(initialData.image_identity);
    initialData.image_identity !== ""
      ? file === ""
        ? setResponseFilename(initialData.image_identity)
        : setResponseFilename(res.body.filename)
      : setResponseFilename(res.body.filename);
    setFileList([
      {
        uid: "-1",
        name: res.body.filename,
        status: "done",
        url: `${appConfig.apiUrl}/config/image/${res.body.filename}`,
      },
    ]);
    setLoading(false);
  };

  const uploadHandlerStore = async (args) => {
    const file = args.file;
    const res = await store.payback.uploadImages(file);
    console.log(res, "ini respon 2");
    setImageStore(`${appConfig.apiUrl}/config/image/${res.body.filename}`);
    setResponseFilenameStore([...responseFilenameStore, res.body.filename]);
    setFileStore([
      ...fileStore,
      {
        uid: "-1",
        name: res.body.filename,
        status: "done",
        url: `${appConfig.apiUrl}/config/image/${res.body.filename}`,
      },
    ]);

    setLoadingStore(false);
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const handleChangeStore = (info) => {
    if (info.file.status === "uploading") {
      setLoadingStore(true);
    } else {
      setLoadingStore(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Click to Upload</div>
    </div>
  );

  const uploadButtonStore = (
    <div>
      {loadingStore ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Click to Upload</div>
    </div>
  );

  return (
    <Modal
      visible={visible}
      title={
        initialData.isChangePassword
          ? "Ganti Password Anggota"
          : initialData.id
          ? "Edit Anggota"
          : "Buat Anggota Baru"
      }
      okText={initialData.id ? "Edit" : "Create"}
      cancelText="Batal"
      onCancel={() => {
        form.resetFields();
        onCancel();
        setImage("");
        setFileList([]);
        setPreviewImage("");
        setResponseFilename("");
        setImageStore("");
        setFileStore([]);
        setPreviewImageStore("");
        setResponseFilenameStore("");
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            console.log(values, "apa valuesanya");
            values.image_identity = responseFilename;
            values.image_store = JSON.stringify(responseFilenameStore);
            onCreate(values, responseFilename, responseFilenameStore);
            form.resetFields();
            setFileStore([]);
            setImage("");
            setFileList([]);
            setPreviewImage("");
            setResponseFilename("");
            setImageStore("");
            setFileStore([]);
            setPreviewImageStore("");
            setResponseFilenameStore("");
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={initialData}
      >
        {((initialData.id && !initialData.isChangePassword) ||
          !initialData.id) && (
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input Name!" }]}
          >
            <Input />
          </Form.Item>
        )}
        {!initialData.id && (
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Please input Username!" }]}
          >
            <Input />
          </Form.Item>
        )}
        {((initialData.id && initialData.isChangePassword) ||
          !initialData.id) && (
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: false, message: "Please input password!" }]}
          >
            <Input.Password />
          </Form.Item>
        )}
        {((initialData.id && !initialData.isChangePassword) ||
          !initialData.id) && (
          <Form.Item
            name="phone_number"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input Phone Number!",
              },
              {
                pattern: /^(?:\d*)$/,
                message: "Phone number should contain just number",
              },
              {
                //pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                pattern: /^[\d]{10,12}$/,
                message: "Phone number should be 10 - 12 character",
              },
            ]}
          >
            <Input
              onChange={(value) => {
                setValue(value);
              }}
            />
          </Form.Item>
        )}
        {((initialData.id && !initialData.isChangePassword) ||
          !initialData.id) &&
          store.authentication.userData.role === "Admin" && (
            <div>
              <Form.Item
                name="identity_number"
                label="Identity Number"
                rules={[
                  {
                    required: true,
                    message: "Please input identity number!",
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Phone number should contain just number",
                  },
                ]}
              >
                <Input
                  onChange={(value) => {
                    setValue(value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Upload identity image"
                name="image_identity"
                rules={[
                  { required: true, message: "Please insert image identity" },
                ]}
              >
                <div>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    showUploadList={true}
                    onPreview={handlePreviewData}
                    onChange={handleChange}
                    beforeUpload={(file) => beforeUpload(file)}
                    customRequest={(args) => uploadHandler(args)}
                    maxCount={1}
                    onRemove={(file) => {
                      setImage("");
                      setLoading(false);
                      setFileList([]);
                    }}
                  >
                    {image === "" ? uploadButton : null}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel1}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage1}
                    />
                  </Modal>
                  <h5
                    style={{
                      marginTop: 12,
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  >
                    Max size of file 2 MB
                  </h5>
                </div>
              </Form.Item>
              <Form.Item
                name="roleId"
                label="Role"
                rules={[{ required: true, message: "Please input role id!" }]}
              >
                <Select>
                  {store.role.data.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
        {((initialData.id && !initialData.isChangePassword) ||
          !initialData.id) &&
          store.authentication.userData.role === "Supervisor" && (
            <div>
              <Form.Item
                name="identity_number"
                label="Identity Number"
                rules={[
                  {
                    required: true,
                    message: "Please input identity number!",
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Phone number should contain just number",
                  },
                ]}
              >
                <Input
                  onChange={(value) => {
                    setValue(value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Upload identity image"
                name="image_identity"
                rules={[
                  { required: true, message: "Please insert image identity" },
                ]}
              >
                <div>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    // onPreview={(file) => {
                    //   setPreviewImage(file.url || file.filename);
                    // }}
                    showUploadList={true}
                    onPreview={handlePreviewData}
                    onChange={handleChange}
                    beforeUpload={(file) => beforeUpload(file)}
                    customRequest={(args) => uploadHandler(args)}
                    onRemove={(file) => {
                      setImage("");
                      setLoading(false);
                      setFileList([]);
                    }}
                  >
                    {image === "" ? uploadButton : null}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel1}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage1}
                    />
                  </Modal>
                  <h5
                    style={{
                      marginTop: 12,
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  >
                    Max size of file 2 MB
                  </h5>
                </div>
              </Form.Item>
              <Form.Item
                name="roleId"
                label="Role"
                rules={[{ required: true, message: "Please input role id!" }]}
              >
                <Select>
                  <Option
                    key="e4dfb6a3-2348-464a-8fb8-5cbc089d4209"
                    value="e4dfb6a3-2348-464a-8fb8-5cbc089d4209"
                  >
                    Sales
                  </Option>
                </Select>
              </Form.Item>
            </div>
          )}
        {((initialData.id && !initialData.isChangePassword) ||
          !initialData.id) &&
          store.authentication.userData.role === "Sales" && (
            <div>
              <Form.Item
                name="identity_number"
                label="Identity Number"
                rules={[
                  {
                    required: true,
                    message: "Please input identity number!",
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: "Phone number should contain just number",
                  },
                ]}
              >
                <Input
                  onChange={(value) => {
                    setValue(value);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Upload identity image"
                name="image_identity"
                rules={[
                  { required: true, message: "Please insert image identity" },
                ]}
              >
                <div>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    // onPreview={(file) => {
                    //   setPreviewImage(file.url || file.filename);
                    // }}
                    showUploadList={true}
                    onPreview={handlePreviewData}
                    onChange={handleChange}
                    beforeUpload={(file) => beforeUpload(file)}
                    customRequest={(args) => uploadHandler(args)}
                    onRemove={(file) => {
                      setImage("");
                      setLoading(false);
                      setFileList([]);
                    }}
                  >
                    {image === "" ? uploadButton : null}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel1}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage1}
                    />
                  </Modal>
                  <h5
                    style={{
                      marginTop: 12,
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  >
                    Max size of file 2 MB
                  </h5>
                </div>
              </Form.Item>
              <Form.Item
                label="Upload foto toko tampak samping kanan,kiri dan depan"
                name="image_store"
                rules={[
                  { required: true, message: "Please insert image store" },
                ]}
              >
                <div>
                  <Upload
                    listType="picture-card"
                    fileList={fileStore}
                    // onPreview={(file) => {
                    //   setPreviewImageStore(file.url || file.filename);
                    // }}
                    showUploadList={true}
                    onPreview={handlePreviewData}
                    onChange={handleChangeStore}
                    beforeUpload={(file) => beforeUploadStore(file)}
                    customRequest={(args) => uploadHandlerStore(args)}
                    maxCount={3}
                    onRemove={(file) => {
                      setImageStore("");
                      setLoadingStore(false);
                      setFileStore([]);
                    }}
                  >
                    {fileStore.length >= 3 ? null : uploadButtonStore}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel1}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage1}
                    />
                  </Modal>
                  <h5
                    style={{
                      marginTop: 12,
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  >
                    Max size of file 2 MB
                  </h5>
                </div>
              </Form.Item>
              <Form.Item
                name="roleId"
                label="Role"
                rules={[{ required: true, message: "Please input role id!" }]}
              >
                <Select>
                  <Option
                    key="e4dfb6a3-2338-464a-8fb8-5cbc089d4209"
                    value="e4dfb6a3-2338-464a-8fb8-5cbc089d4209"
                  >
                    Retail
                  </Option>
                </Select>
              </Form.Item>
            </div>
          )}
      </Form>
    </Modal>
  );
};
