import { makeAutoObservable } from "mobx";
import { http } from "../utils/http";

export class Supplier {
  page = 0;
  pageSize = 10;
  data = [];
  total_data = 0;
  filterCategory = null;
  visibleModalSupplier = false;
  visibleModalTransaction = false;
  code = "";

  pageCategories = 0;
  pageSizeCategories = 10;
  dataCategories = [];
  total_dataCategories = 0;

  pageSubCategories = 0;
  pageSizeSubCategories = 10;
  dataSubCategories = [];
  total_dataSubCategories = 0;

  constructor(ctx) {
    this.ctx = ctx;
    makeAutoObservable(this);
  }

  async getData() {
    try {
      const response = await http.get(
        `/users/supplier?page=${this.page}&pageSize=${this.pageSize}`
      );
      //console.log(response)
      this.data = response.body.data ?? [];
      this.total_data = response.body.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async create(data) {
    const response = await http.post("/users/supplier").send(data);
    return response;
  }
  async createTransaction(data) {
    try {
      const response = await http
        .post("/transaction/add-saldo-supplier")
        .send(data);
      await this.getData();
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async update(id, data) {
    try {
      const response = await http.put(`/users/supplier/${id}`).send(data);
      await this.getData();
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async delete(id) {
    try {
      const response = await http.del(`/product/${id}`);
      await this.getData();
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async changeStatus(id, status) {
    try {
      const response = await http.get(`/users/supplier/${id}/${status}`);
      await this.getData();
      return response;
    } catch (e) {
      console.error(e);
    }
  }
}
