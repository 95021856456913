import React, { useContext } from "react";
import { Form, Input, message, Modal, InputNumber } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../utils/useStore";
import { ModalLoaderContext } from "../utils/modal";

export const TopupsaldoModal = observer((props) => {
  const store = useStore();
  const [form] = Form.useForm();
  const modalLoader = useContext(ModalLoaderContext);

  const handleCancelTransaction = () => {
    store.supplier.visibleModalTransaction = false;
  };

  const handleSubmitTransaction = async (data) => {
    try {
      modalLoader.setLoading(true);
      const response = await store.supplier.createTransaction(data);
      message.success(response?.body?.message || "Success Top Up");
    } catch (e) {
      console.log(e, "apa errornya");
      message.error(e.response?.body?.message || "Failed Top Up");
    }

    modalLoader.setLoading(false);
    store.supplier.visibleModalTransaction = false;
    form.resetFields();
  };

  return (
    <div>
      <Modal
        visible={store.supplier.visibleModalTransaction}
        title="Top Up Saldo"
        okText="Top Up"
        cancelText="Batal"
        onCancel={() => {
          form.resetFields();
          handleCancelTransaction();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values, "isi form");
              handleSubmitTransaction(values);
              form.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ supplier: store.supplier.code }}
        >
          <Form.Item
            name="supplier"
            label="Supplier"
            rules={[{ required: true, message: "Please input supplier!" }]}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Nominal"
            rules={[{ required: true, message: "Please input amount!" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\Rp.\s?|(,*)/g, "")}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
