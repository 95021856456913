import { UI } from "./ui";
import { Authentication } from "./authentication";
import { User } from "./user";
import { Membership } from "./membership";
import { Product } from "./product";
import { Partner } from "./partner";
import { Supplier } from "./supplier";
import { Commission } from "./commission";
import { Transaction } from "./transaction";
import { TokenUtil } from "../utils/token";
import { Category } from "./category";
import { Subcategory } from "./subcategory";
import { Payback } from "./payback";
import { Role } from "./role";
import { Approval } from "./approval";


export class Store {
    ui = new UI(this);
    authentication = new Authentication(this);
    user = new User(this);
    membership = new Membership(this);
    product = new Product(this);
    partner = new Partner(this);
    supplier = new Supplier(this);
    commission = new Commission(this);
    category = new Category(this);
    payback = new Payback(this);
    transaction = new Transaction(this);
    subcategory = new Subcategory(this);
    role = new Role(this);
    approval = new Approval(this);

    constructor() {
        TokenUtil.loadToken();
        if (TokenUtil.accessToken) {
            this.authentication.isLoggedIn = true;
        }
    }
}
