import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../utils/useStore";
import { Button, Card, Col, Form, Input, message, Row, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { LINKS } from "../../routes/app";

export const Login = observer(() => {
  const store = useStore();
  let history = useHistory();
  const [form] = Form.useForm();

  const handleLogin = async (params) => {
    try {
      await store.authentication.login({
        username: params.username,
        password: params.password,
      });
    } catch (e) {
      if (e.response?.body?.message) {
        message.error(e.response.body.message);
        return;
      }
      message.error(e.message);
    }
    
    if (
      store.authentication.userData.role === "Sales" ||
      store.authentication.userData.role === "Supervisor"
    ) {
      history.push(LINKS.MEMBERSHIP);
    } else if (store.authentication.userData.role === "Retail") {
      history.push(LINKS.TRANSACTION);
    } else {
      history.push(LINKS.HOME);
    }
  };

  return (
    <div style={{ width: "100vw", display: "flex", justifyContent: "center" }}>
      <Row justify={"center"}>
        <Col>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "5vh",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <Typography.Paragraph
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 20,
                  marginLeft: 5,
                  fontWeight: 600,
                  color: "#413d3e",
                }}
              >
                PPOB
              </Typography.Paragraph>
            </div>
            <Card
              style={{ width: 320, textAlign: "center" }}
              headStyle={{ fontSize: 13, fontWeight: 200 }}
              className={"shadow"}
              bordered={true}
              title={"Sign in to your account"}
            >
              <Form
                layout={"vertical"}
                form={form}
                onFinish={handleLogin}
                className={"w-9/12"}
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <div className={"flex flex-row justify-between content-center"}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={store.authentication.isLoginLoading}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
});
