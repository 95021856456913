import React, { useContext, useEffect, useState } from "react";
import { useStore } from "../../utils/useStore";
import {
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Button,
} from "antd";
import { observer } from "mobx-react-lite";
import { ModalLoaderContext } from "../../utils/modal";

const { Search } = Input;
const { Option } = Select;

export const Product = observer(() => {
  const store = useStore();
  const modalLoader = useContext(ModalLoaderContext);
  const [form] = Form.useForm();
  const [formBuyPostPaid] = Form.useForm();
  const [formBill] = Form.useForm();
  const [visibleModalBuy, setVisibleModalBuy] = useState(false);
  const [visibleModalBill, setVisibleModalBill] = useState(false);
  const [visibleModalBuyPostpaid, setVisibleModalBuyPostpaid] = useState(false);
  const [barang, setBarang] = useState({});

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        await Promise.allSettled([
          store.transaction.getDataSubCategories(),
          store.transaction.getDataCategories(),
        ]);
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const handleChangeSubcategory = async (item) => {
    store.transaction.filterSubCategory = item;
    modalLoader.setLoading(true);
    await store.transaction.getData();
    modalLoader.setLoading(false);
  };

  const handleBuyProduct = async (data, productCode, productId, productType, billTrxId) => {
    modalLoader.setLoading(true);
    if (productType == "prepaid") {
      try {
        const response = await store.transaction.buyProd({
          ...data,
          productCode: productCode,
          productId: productId
        });

        if (response.status === 201) {
          message.success(response?.body?.message || "Berhasil Beli Produk");
        } else {
          message.error(response?.body?.error || "Gagal Beli Produk", 3);
        }
      } catch (e) {
        console.log("testingan");
        console.log(e.response, "testingan");
        console.log(e.result, "testingan1");
        if (e.response?.body?.error) {
          message.error(e.response.body.error);
          setVisibleModalBuy(false);
          modalLoader.setLoading(false);
          return;
        }
        console.log(e, "apa errornya");
        message.error("Gagal Beli Product");
      }
    } else {
      try {
        const response = await store.transaction.buyProd({
          ...data,
          productCode: productCode,
          productId: productId,
            billTrxId: billTrxId
        });

        if (response.status === 201) {
          message.success(response?.body?.message || "Berhasil Bayar Produk");
        } else {
          message.error(response?.body?.error || "Gagal Bayar Produk", 3);
        }
      } catch (e) {
        console.log("testingan");
        console.log(e.response, "testingan");
        console.log(e.result, "testingan1");
        if (e.response?.body?.error) {
          message.error(e.response.body.error);
          setVisibleModalBuy(false);
          modalLoader.setLoading(false);
          return;
        }
        console.log(e, "apa errornya");
        message.error("Gagal Bayar Product");
      }
    }
    setVisibleModalBuyPostpaid(false)
    setVisibleModalBuy(false);
    modalLoader.setLoading(false);
  };

  const handleCheckBill = async (data, productCode, productId) => {
    modalLoader.setLoading(true);
    try {
      const response = await store.transaction.checkBill({
        ...data,
        productCode: productCode,
        productId: productId
      });

      if (response.status === 201) {
        message.success(response?.body?.message || "Berhasil Cek Produk");
      } else {
        message.error(response?.body?.error || "Gagal Cek Produk", 3);
      }
    } catch (e) {
      console.log("testingan");
      console.log(e.response, "testingan");
      console.log(e.result, "testingan1");
      if (e.response?.body?.error) {
        message.error(e.response.body.error);
        setVisibleModalBuy(false);
        modalLoader.setLoading(false);
        return;
      }
      console.log(e, "apa errornya");
      message.error("Gagal Cek Product");
    }
    setVisibleModalBill(false);
    modalLoader.setLoading(false);
  };

  const handleBuyStag = async (data, productCode, productId) => {
    modalLoader.setLoading(true);
    try {
      const response = await store.transaction.buyProduct({
        ...data,
        productCode: productCode,
        productId: productId
      });
      if (response.status === 201) {
        message.success(response?.body?.message || "Berhasil Beli Produk");
      } else {
        message.error(response?.body?.error || "Gagal Beli Produk", 3);
      }
    } catch (e) {
      console.log("testingan");
      console.log(e.response, "testingan");
      console.log(e.result, "testingan1");
      if (e.response?.body?.error) {
        message.error(e.response.body.error);
        setVisibleModalBuy(false);
        modalLoader.setLoading(false);
        return;
      }
      console.log(e, "apa errornya");
      message.error("Gagal Beli Product");
    }
    // setDataProd(false);
    // setDataStag(false);
    setVisibleModalBuy(false);
    modalLoader.setLoading(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setVisibleModalBuy(false);
  };

  return (
    <div>
      <Row>
        <span style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Jenis Produk
        </span>
      </Row>
      <Row>
        <Col span={24}>
          <Select
            placeholder={"Pilih Kategori"}
            allowClear={true}
            onChange={(val) => handleChangeSubcategory(val)}
            style={{ marginBottom: "10px", width: "100%" }}
            value={store.transaction.filterSubCategory}
          >
            {store.transaction.dataSubCategories.map((item, index) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row justify={"center"} align={"center"} style={{ marginBottom: "1rem" }}>
        <Col
          span={12}
          style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
        >
          Produk & Nominal
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          {/* <Search
            placeholder="input search text"
            style={{ width: 200, marginRight: 10 }}
          /> */}
        </Col>
      </Row>
      {store.transaction.data.length != 0 && (
        <Row>
          {store.transaction.data.map((item, index) => (
            <Col key={index} xs={24} md={16} lg={8}>
              <Card
                onClick={() => {
                    if (item.product_type == "prepaid") {
                        setVisibleModalBuy(true);
                    } else {
                        setVisibleModalBuyPostpaid(true)
                    }
                  setBarang(item);
                }}
                hoverable
                style={{
                  cursor: "pointer",
                  marginLeft: 10,
                  borderColor: "salmon",
                  height: 100,
                  marginBottom: 10,
                }}
              >
                <span style={{ color: "black" }}>{item?.product_name}</span>
                <br />
                <span style={{ color: "grey", fontSize: 10 }}>
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  }).format(item?.price)}
                </span>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      <Modal
        visible={visibleModalBuy}
        title={`Apakah anda yakin membeli ${barang?.product_name}?`}
        okText={"Confirm"}
        onCancel={() => {
          form.resetFields();
          setVisibleModalBuy(false);
        }}
        // footer={footerLayoutFilter}
        footer={[
          <Button
            key="back"
            style={{
              backgroundColor: "#e74e5e",
              color: "#fff",
            }}
            onClick={() => {
              form.resetFields();
              handleCancel();
            }}
          >
            Batal
          </Button>,
          <Button
            key="Beli"
            style={{
              backgroundColor: "#4e79e7",
              color: "#fff",
              marginRight:10
            }}
            onClick={() => {
                form
                    .validateFields()
                    .then((values) => {
                      console.log(values, "isi form");
                      handleBuyProduct(values, barang.product_code, barang.product_id, barang.product_type, "");
                      form.resetFields();
                    })
                    .catch((info) => {
                      console.error("Validate Failed:", info);
                    });
            }}
          >
            Beli
          </Button>,
          // <Button
          //   key="Beli Stag"
          //   type="primary"
          //   onClick={() => {
          //     form
          //       .validateFields()
          //       .then((values) => {
          //         console.log(values, "isi form");
          //         handleBuyStag(values, barang.product_code);
          //         form.resetFields();
          //       })
          //       .catch((info) => {
          //         console.error("Validate Failed:", info);
          //       });
          //   }}
          // >
          //   Beli Staging
          // </Button>,
        ]}
      >

        <Form form={form} layout="vertical">
          <Form.Item
            name="destination"
            label="Nomor Tujuan"
            rules={[
              {
                required: true,
                message: "Tolong masukan nomor tujuan!",
              },
              // {
              //   pattern: /^(?:\d*)$/,
              //   message: "Value should contain just number",
              // },
              // {
              //   pattern: /^[\d]{1,12}$/,
              //   message: "Value should be 1 - 12 character",
              // },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>


      <Modal
          visible={visibleModalBuyPostpaid}
          title={`Apakah anda yakin membeli ${barang?.product_name}?`}
          okText={"Confirm"}
          onCancel={() => {
            formBuyPostPaid.resetFields();
            setVisibleModalBuyPostpaid(false);
          }}
          // footer={footerLayoutFilter}
          footer={[
            <Button
                key="back"
                style={{
                  backgroundColor: "#e74e5e",
                  color: "#fff",
                }}
                onClick={() => {
                  formBuyPostPaid.resetFields();
                  setVisibleModalBuyPostpaid(false);
                }}
            >
              Batal
            </Button>,
            <Button
                key="Beli"
                style={{
                  backgroundColor: "#4e79e7",
                  color: "#fff",
                  marginRight:10
                }}
                onClick={() => {
                    formBuyPostPaid
                        .validateFields()
                        .then((destination, billTrxId) => {
                          console.log(destination, "isi form");
                          handleBuyProduct(destination, barang.product_code, barang.product_id, barang.product_type, billTrxId);
                          formBuyPostPaid.resetFields();
                            setVisibleModalBuyPostpaid(false);
                        })
                        .catch((info) => {
                          console.error("Validate Failed:", info);
                        });

                }}
            >
              Beli
            </Button>,
            // <Button
            //   key="Beli Stag"
            //   type="primary"
            //   onClick={() => {
            //     form
            //       .validateFields()
            //       .then((values) => {
            //         console.log(values, "isi form");
            //         handleBuyStag(values, barang.product_code);
            //         form.resetFields();
            //       })
            //       .catch((info) => {
            //         console.error("Validate Failed:", info);
            //       });
            //   }}
            // >
            //   Beli Staging
            // </Button>,
          ]}
      >
        <Row
            justify={"end"}
            align={"end"}
        >
          <Button
              onClick={() => {
                setVisibleModalBill(true);
                setVisibleModalBuyPostpaid(false)
              }}
              style={{
                backgroundColor: "#4e79e7",
                color: "#fff",
              }}
          >
            Cek Bill Postpaid
          </Button>
        </Row>
        <Form form={formBuyPostPaid} layout="vertical">
          <Form.Item
              name="destination"
              label="Nomor Tujuan"
              rules={[
                {
                  required: true,
                  message: "Tolong masukan nomor tujuan!",
                },
                // {
                //   pattern: /^(?:\d*)$/,
                //   message: "Value should contain just number",
                // },
                // {
                //   pattern: /^[\d]{1,12}$/,
                //   message: "Value should be 1 - 12 character",
                // },
              ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
              name="bill_trx_id"
              label="Id Transaksi"
              rules={[
                {
                  required: true,
                  message: "Tolong masukan Id Transaksi!",
                }
              ]}
          >
            <Input />
          </Form.Item>
        </Form>

      </Modal>

      <Modal
          visible={visibleModalBill}
          title={`Cek Bill ${barang?.product_name}`}
          okText={"Confirm"}
          onCancel={() => {
            formBill.resetFields();
            setVisibleModalBill(false);
          }}
          // footer={footerLayoutFilter}
          footer={[
            <Button
                key="back"
                style={{
                  backgroundColor: "#e74e5e",
                  color: "#fff",
                }}
                onClick={() => {
                  formBill.resetFields();
                  setVisibleModalBill(false);
                }}
            >
              Batal
            </Button>,
            <Button
                key="Cek"
                style={{
                  backgroundColor: "#4e79e7",
                  color: "#fff",
                  marginRight:10
                }}
                onClick={() => {
                  formBill
                      .validateFields()
                      .then((values) => {
                        console.log(values, "isi form");
                        handleCheckBill(values, barang.product_code, barang.product_id)
                        formBill.resetFields();
                          setVisibleModalBill(false);
                      })
                      .catch((info) => {
                        console.error("Validate Failed:", info);
                      });
                }}
            >
              Cek Bill
            </Button>
          ]}
      >
        <Form form={formBill} layout="vertical">
          <Form.Item
              name="destination"
              label="Nomor Tujuan"
              rules={[
                {
                  required: true,
                  message: "Tolong masukan nomor tujuan!",
                },
                // {
                //   pattern: /^(?:\d*)$/,
                //   message: "Value should contain just number",
                // },
                // {
                //   pattern: /^[\d]{1,12}$/,
                //   message: "Value should be 1 - 12 character",
                // },
              ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

    </div>
  );
});
