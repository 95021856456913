import React, { useContext, useEffect } from "react";
import { Button, PageHeader, Card, Row, Col, message, Table } from "antd";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import {
  DropboxOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { ModalLoaderContext } from "../../utils/modal";
import Title from "antd/lib/skeleton/Title";

export const Home = observer(() => {
  const modalLoader = useContext(ModalLoaderContext);
  const store = useStore();
  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        const isAdmin = store.authentication.userData.role === "Admin";
        await getData();
        await store.membership.getDataBySuperior();
        await store.partner.getData();
        if (isAdmin) {
          await store.transaction.getDataTransaction();
          await store.transaction.getDataTransactionB2B();
        } else {
          await store.transaction.getDataTransactionPartner();
        }

        await store.role.getData(isAdmin);
        modalLoader.setLoading(false);
      } catch (e) {
        console.error(e);
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);
  const getData = async () => {
    store.authentication.userData.role === "Admin"
      ? await store.membership.getData()
      : await store.membership.getDataBySuperior();
  };

  return (
    <div>
      {store.ui.mediaQuery.isDesktop &&
        (store.authentication.userData.role === "Admin" ||
          store.authentication.userData.role === "Customer Service") && (
          <Row
            style={{
              marginTop: 30,
              marginLeft: 30,
            }}
          >
            <Card
              className={"shadow"}
              hoverable
              title={
                <span
                  style={{
                    fontSize: 20,
                    fontStyle: "bold",
                    textAlign: "center",
                  }}
                >
                  {store.transaction.dataTransaction.total_transaction}{" "}
                  Transaksi B2C
                </span>
              }
              style={{
                marginLeft: 20,
                height: 200,
                marginBottom: 10,
                borderColor: "salmon",
                width: "47%",
              }}
            >
              <Row>
                <Row>
                  <Col span={8}>
                    <p>
                      <h4>
                        Total Penjualan :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransaction.total_amount
                        )}
                      </h4>

                      <h4>
                        Total Modal :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransaction.total_modal
                        )}
                      </h4>
                    </p>
                  </Col>
                  <Col span={8} offset={8}>
                    <p>
                      <h4>
                        Total Profit :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransaction.total_profit
                        )}
                      </h4>

                      <h4>
                        Total Komisi :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransaction.total_commission
                        )}
                      </h4>
                    </p>
                  </Col>
                </Row>
              </Row>
            </Card>
            <Card
              className={"shadow"}
              hoverable
              title={
                <span
                  style={{
                    fontSize: 20,
                    fontStyle: "bold",
                    textAlign: "center",
                  }}
                >
                  {store.transaction.dataTransactionB2B.total_transaction}{" "}
                  Transaksi B2B
                </span>
              }
              style={{
                marginLeft: 20,
                height: 200,
                marginBottom: 10,
                borderColor: "salmon",
                width: "47%",
              }}
            >
              <Row>
                <Row>
                  <Col span={8}>
                    <p>
                      <h4>
                        Total Penjualan :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransactionB2B.total_amount
                        )}
                      </h4>

                      <h4>
                        Total Modal :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransactionB2B.total_modal
                        )}
                      </h4>
                    </p>
                  </Col>
                  <Col span={8} offset={8}>
                    <p>
                      <h4>
                        Total Profit :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransactionB2B.total_profit
                        )}
                      </h4>
                    </p>
                  </Col>
                </Row>
              </Row>
            </Card>
            {/*<Card*/}
            {/*  className={"shadow"}*/}
            {/*  hoverable*/}
            {/*  style={{*/}
            {/*    marginLeft: 20,*/}
            {/*    height: 200,*/}
            {/*    marginBottom: 10,*/}
            {/*    borderColor: "salmon",*/}
            {/*    width: "30%",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Row>*/}
            {/*    <PageHeader title={<span>Total Keuntungan B2C</span>}>*/}
            {/*      <span>*/}
            {/*        {new Intl.NumberFormat("id-ID", {*/}
            {/*          style: "currency",*/}
            {/*          currency: "IDR",*/}
            {/*        }).format(store.authentication.profileData?.wallet || 0)}*/}
            {/*      </span>*/}
            {/*    </PageHeader>*/}
            {/*  </Row>*/}
            {/*</Card>*/}
          </Row>
        )}
      {store.ui.mediaQuery.isDesktop &&
        store.authentication.userData.role === "Admin Partner" && (
          <Row
            style={{
              marginTop: 30,
              marginLeft: 30,
            }}
          >
            <Card
              className={"shadow"}
              hoverable
              style={{
                marginLeft: 20,
                height: 200,
                marginBottom: 10,
                borderColor: "salmon",
                width: "47%",
              }}
            >
              <Row>
                <PageHeader title={<span>Total Transaksi</span>}>
                  <p>
                    <h4>
                      {
                        store.transaction.dataTransactionPartner
                          .total_transaction
                      }
                    </h4>
                  </p>
                </PageHeader>
              </Row>
            </Card>
            <Card
              className={"shadow"}
              hoverable
              style={{
                marginLeft: 20,
                height: 200,
                marginBottom: 10,
                borderColor: "salmon",
                width: "47%",
              }}
            >
              <Row>
                <PageHeader title={<span>Total Penjualan</span>}>
                  <p>
                    <h4>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      }).format(
                        store.transaction.dataTransactionPartner.total_amount
                      )}
                    </h4>
                  </p>
                </PageHeader>
              </Row>
            </Card>
          </Row>
        )}
      {store.ui.mediaQuery.isMobile &&
        (store.authentication.userData.role === "Admin" ||
          store.authentication.userData.role === "Customer Service") && (
          <Row
            style={{
              marginTop: 20,
              marginLeft: 10,
            }}
          >
            <Card
              className={"shadow"}
              hoverable
              title={
                <span
                  style={{
                    fontSize: 20,
                    fontStyle: "bold",
                    textAlign: "center",
                  }}
                >
                  {store.transaction.dataTransaction.total_transaction}{" "}
                  Transaksi B2C
                </span>
              }
              style={{
                marginLeft: 10,
                height: 220,
                marginBottom: 10,
                borderColor: "salmon",
                width: "90%",
              }}
            >
              <Row>
                <Row>
                  <Col style={{ marginRight: 40 }}>
                    <p>
                      <h4>
                        Total Penjualan :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransaction.total_amount
                        )}
                      </h4>

                      <h4>
                        Total Modal :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransaction.total_modal
                        )}
                      </h4>
                    </p>
                  </Col>
                  <Col>
                    <p>
                      <h4>
                        Total Profit :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransaction.total_profit
                        )}
                      </h4>

                      <h4>
                        Total Komisi :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransaction.total_commission
                        )}
                      </h4>
                    </p>
                  </Col>
                </Row>
              </Row>
            </Card>
            <Card
              className={"shadow"}
              hoverable
              title={
                <span
                  style={{
                    fontSize: 20,
                    fontStyle: "bold",
                    textAlign: "center",
                  }}
                >
                  {store.transaction.dataTransactionB2B.total_transaction}{" "}
                  Transaksi B2B
                </span>
              }
              style={{
                marginLeft: 10,
                height: 200,
                borderColor: "salmon",
                width: "90%",
              }}
            >
              <Row>
                <Row>
                  <Col style={{ marginRight: 40 }}>
                    <p>
                      <h4>
                        Total Penjualan :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransactionB2B.total_amount
                        )}
                      </h4>

                      <h4>
                        Total Modal :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransactionB2B.total_modal
                        )}
                      </h4>
                    </p>
                  </Col>
                  <Col>
                    <p>
                      <h4>
                        Total Profit :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(
                          store.transaction.dataTransactionB2B.total_profit
                        )}
                      </h4>
                    </p>
                  </Col>
                </Row>
              </Row>
            </Card>
          </Row>
        )}
      {store.ui.mediaQuery.isMobile &&
        store.authentication.userData.role === "Admin Partner" && (
          <Row
            style={{
              marginTop: 20,
              marginLeft: 10,
            }}
          >
            <Card
              className={"shadow"}
              hoverable
              style={{
                marginLeft: 10,
                height: 200,
                marginBottom: 10,
                borderColor: "salmon",
                width: "90%",
              }}
            >
              <Row>
                <PageHeader title={<span>Total Transaksi</span>}>
                  <p>
                    <h4>
                      {
                        store.transaction.dataTransactionPartner
                          .total_transaction
                      }
                    </h4>
                  </p>
                </PageHeader>
              </Row>
            </Card>
            <Card
              className={"shadow"}
              hoverable
              style={{
                marginLeft: 10,
                height: 200,
                marginBottom: 10,
                borderColor: "salmon",
                width: "90%",
              }}
            >
              <Row>
                <PageHeader title={<span>Total Penjualan</span>}>
                  <p>
                    <h4>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      }).format(
                        store.transaction.dataTransactionPartner.total_amount
                      )}
                    </h4>
                  </p>
                </PageHeader>
              </Row>
            </Card>
          </Row>
        )}
    </div>
  );
});
