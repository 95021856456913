import React, {useContext, useEffect, useState} from "react";
import { Button, Card, Col, Input, Row, Tabs ,message} from "antd";
import { FilterOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { CommissionComponent } from "../../component/CommissionComponent";
import { LINKS } from "../../routes/app";
import {ModalLoaderContext} from "../../utils/modal";

const { TabPane } = Tabs;
const { Search } = Input;

export const Commission = observer(() => {
  const store = useStore();
  const modalLoader = useContext(ModalLoaderContext);

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        //await store.commission.getDataCategories();
        await store.commission.getData();
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.COMMISSION,
      name: <span style={{ fontWeight: "bold" }}>Commission</span>,
    },
  ];

  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent data={routeData} />
      <Card>
        {/* <Row style={{ marginBottom: 20 }}> */}
          {/* <Col span={12}>
            <Button>
              <FilterOutlined />
              Filter
            </Button>
          </Col> */}
          {/* <Col span={24} style={{ textAlign: "right" }}>
            <Search
              placeholder="input search text"
              style={{
                width: store.ui.mediaQuery.isMobile ? 160 : 200,
                marginRight: store.ui.mediaQuery.isMobile ? 0 : 10,
                marginBottom: store.ui.mediaQuery.isMobile ? 10 : 0,
              }}
            />
            <Button onClick={() => (store.commission.visibleModalCommission = true)}>
              <PlusSquareOutlined /> New
            </Button>
          </Col>
        </Row> */}
        <div style={{marginTop:20}}> <CommissionComponent/></div>

      </Card>
    </div>
  );
});
