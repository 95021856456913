import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  message,
  Row,
  Space,
  Table,
  Typography,
  Tabs,
  List,
  Tag,
  Divider,
  Image,
  Modal,
  Form,
  DatePicker, Input,
} from "antd";
import { UserOutlined, FilterOutlined,ExportOutlined } from "@ant-design/icons";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { LINKS } from "../../routes/app";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { format, parseISO } from "date-fns";
import { ModalLoaderContext } from "../../utils/modal";
import { useParams } from "react-router-dom";
import { appConfig } from "../../config/app";
import { MembershipModal } from "./MembershipModal";
import moment from "moment";
import FileSaver from "file-saver";

const { Title, Text } = Typography;
const { TabPane } = Tabs;
export const DetailUser = observer(() => {
  const store = useStore();
  const [form] = Form.useForm();
  const [formExport] = Form.useForm();
  const [formFilterByTrxCode] = Form.useForm();
  const modalLoader = useContext(ModalLoaderContext);
  const { id } = useParams();
  const [visibleModal, setVisibleModal] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [filterStart, setFilterStart] = useState([]);
  const [filterEnd, setFilterEnd] = useState([]);
  const [filterTrxId, setFilterTrxId] = useState([]);
  const [filterPartnerTrxId, setFilterPartnerTrxId] = useState([]);
  const [visibleHis, setVisibleHis] = useState(true);
  const [actionFilter, setAction] = useState(false);
  const [actionFilterByCode, setActionFilterByCode] = useState(false);
  const [modalExport, setModalExport] = useState(false);

  useEffect(() => {
    (async () => {
      modalLoader.setLoading(true);
      await getData();
      modalLoader.setLoading(false);
    })();

    return () => {
      store.membership.dataDetail = {};
      store.transaction.dataHistoryTopUp = [];
      store.authentication.dataProfit = [];
      store.transaction.dataDetailHistoryTransaction = [];
    };
  }, []);

  const getData = async () => {
    const isAdmin = store.authentication.userData.role === "Admin";
    await Promise.allSettled([
      store.transaction.getDataHistoryTopUp(id),
      store.authentication.getProfit(id),
      store.authentication.getProfile(),
      store.transaction.getDetailHistoryTransaction(id),
      store.membership.getDetail(id),
      store.role.getData(isAdmin),
    ]);
  };

  const changeStatus = async (id, is_active) => {
    let status = is_active ? "inactive" : "active";
    let status2 = is_active ? "Inactivating" : "Activating";
    console.log(status, "status terbaru");
    try {
      modalLoader.setLoading(true);
      const response = await store.membership.changeStatus(id, status);
      modalLoader.setLoading(false);
      response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(`Success ${status2} Membership`)
          : message.error(`Failed ${status2} Membership`);
      await getData();
    } catch (err) {
      modalLoader.setLoading(false);
      message.error(`Failed ${status2} Membership`);
    }
  };

  const withdrawProfit = async (id) => {
    try {
      modalLoader.setLoading(true);
      const response = await store.membership.withdrawProfit(id);
      modalLoader.setLoading(false);

      response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(`Success Withdraw Profit`)
          : message.error(`Failed Withdraw Profit`);
      await getData();
    } catch (err) {
      modalLoader.setLoading(false);
      message.error(`Failed Withdraw Profit`);
    }
  };

  const onSubmit = async (data) => {
    data.superior = true;
    setConfirmLoading(true);
    modalLoader.setLoading(true);
    try {
      let response;
      if (initialData.isChangePassword) {
        response = await store.membership.changePassword(initialData.id, data);
      } else {
        response = await store.membership.update(initialData.id, data);
      }

      setVisibleModal(false);

      if (response?.body?.statusCode === 200) {
        message.success(
            initialData.isChangePassword
                ? "Success Change Member Password"
                : "Success Update Data Member"
        );
      } else {
        message.error(
            initialData.isChangePassword
                ? "Failed Change Member Password"
                : "Failed Update Data Member"
        );
      }
      await getData();
    } catch (e) {
      modalLoader.setLoading(false);
      message.error(
          initialData.isChangePassword
              ? "Failed Update Member Password"
              : "Failed Update Data Member"
      );
    }
    modalLoader.setLoading(false);
    setConfirmLoading(false);
  };

  const handleResend = async (id) => {
    modalLoader.setLoading(true);
    try {
      const response = await store.approval.resendUser(id);
      console.log(response);
      response.body.statusCode !== 201 && response.body.statusCode !== 200
          ? message.error(response?.body?.message || `Failed Approve`)
          : message.success(response?.body?.message || `Success Approve`);
      await getData();
    } catch (e) {
      console.error(e, "apa errornya");
      message.error(e.response?.body?.message || "Fail Approve");
    }
    modalLoader.setLoading(false);
  };

  const handleSubmitExport = async () => {
    const date = formExport.getFieldsValue();
    const dataDate ={
      dateStart: moment(date.start_date).format("YYYY-MM-DD"),
      dateEnd: moment(date.end_date).format("YYYY-MM-DD")
    }
    modalLoader.setLoading(true);
    try {
      await store.transaction.exportTransaction(store.membership.dataDetail, dataDate)
      // console.log('responseiniyak', response);
      // message.success("Success export");
      setModalExport(false);
      modalLoader.setLoading(false);
    } catch (e) {
      setModalExport(false);
      console.error(e, "apa errornya");
      message.error(e.response?.body?.message || "Failed to export");
      modalLoader.setLoading(false);
    }
  };

  const columns = [
    {
      title: "Pengirim",
      dataIndex: "sender_name",
      key: "sender_name",
    },
    {
      title: "Nominal",
      dataIndex: "amount",
      key: "amount",
      render: (text) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          }).format(text),
    },
    {
      title: "Tanggal Transaksi",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text, record) => {
        return (
            <Text>
              {moment(new Date (record.transaction_date).toISOString().slice(0, -1)).format("DD MMMM YYYY")}
            </Text>
        );
      },
    },
  ];

  const column = [
    {
      title: "Nama Produk",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
    },
    {
      title: "Profit Produk",
      dataIndex: "mark_up_price",
      key: "mark_up_price",
    },
    {
      title: "Harga",
      dataIndex: "price",
      key: "price",
      render: (text) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          }).format(text),
    },
    {
      title: "Sisa Saldo",
      dataIndex: "balance_remaining",
      key: "balance_remaining",
      render: (text) =>
          new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
          }).format(text),
    },
    {
      title: "Pembeli",
      dataIndex: "buyer",
      key: "buyer",
    },
    {
      title: "Tujuan",
      dataIndex: "transaction_destination",
      key: "transaction_destination",
    },
    {
      title: "Kode Transaksi",
      dataIndex: "transaction_code",
      key: "transaction_code",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
            <Tag
                color={
                  record.status === 1
                      ? "success"
                      : record.status === 0
                          ? "warning"
                          : "processing"
                }
            >
              {record.status === 1
                  ? "Success"
                  : record.status === 0
                      ? "Pending"
                      : "Failed"}
            </Tag>
        );
      },
    },
    {
      title: "No Seri",
      dataIndex: "seri_number",
      key: "seri_number",
    },
    {
      title: "IDTrx Mitra",
      dataIndex: "partner_transaction_code",
      key: "partner_transaction_code",
    },
    {
      title: "Tanggal Transaksi",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
      render: (text, record) => {
        return (
            <Text>
              {
                moment(new Date (record.created_at).toISOString().slice(0, -1)).format("MM-DD-YYYY HH:mm:ss")
              }
            </Text>
        );
      },
    },
    {
      title: "Alasan Gagal",
      dataIndex: "failed_reason",
      key: "failed_reason",
    },
  ];
  if (store.authentication.userData.role !== "Admin") delete column[1];
  if (store.authentication.userData.role !== "Admin") delete column[2];
  if (store.authentication.userData.role !== "Admin") delete column[4];

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.MEMBERSHIP,
      name: <span style={{ fontWeight: "bold" }}>Keanggotaan</span>,
    },
    {
      route: LINKS.USER_DETAIL.replace(":id", id),
      name: <span style={{ fontWeight: "bold" }}>Detail User</span>,
    },
  ];

  const dataRoute = [
    {
      route: LINKS.MEMBERSHIP,
      name: "Keanggotaan",
    },
    {
      route: LINKS.USER_DETAIL.replace(":id", id),
      name: <span style={{ fontWeight: "bold" }}>Detail User</span>,
    },
  ];

  const styleSaldoTitle = store.ui.mediaQuery.isDesktop
      ? {
        display: "flex",
        justifyContent: "center",
      }
      : { fontSize: "0.75rem" };
  const styleSaldoContent = store.ui.mediaQuery.isDesktop
      ? {
        fontSize: "1.25rem",
        display: "flex",
        justifyContent: "center",
      }
      : null;
  const data = store.authentication.listImage;
  console.log(data, "ini data");
  console.log(
      store.authentication.dataProfit.userDetail?.image_identity,
      "detail"
  );

  const handleRemoveFilter = async () => {
    store.transaction.filterStart = null;
    store.transaction.filterEnd = null;
    form.resetFields();
    setFilterStart([]);
    setFilterEnd([]);
    actionFilter === true
        ? await store.transaction.getDataHistoryTopUp(id)
        : await store.transaction.getDetailHistoryTransaction(
            store.authentication.dataProfit.id
        );
    // await store.transaction.getDetailHistoryTransaction(
    //   store.authentication.dataProfit.id
    // );
    store.transaction.visibleModalFilterTransaction = false;
    setAction(false);
  };

  const handleRemoveFilterByTrxId = async () => {
    store.transaction.filterStart = null;
    store.transaction.filterEnd = null;
    store.transaction.filterTrxId = null;
    store.transaction.filterPartnerTrxId = null;
    formFilterByTrxCode.resetFields();
    setFilterStart([]);
    setFilterEnd([]);
    setFilterTrxId([]);
    setFilterPartnerTrxId([]);
    store.transaction.visibleModalFilterTransactionByCode = false;
    setActionFilterByCode(false);
    await store.transaction.getDetailHistoryTransaction(
        store.authentication.dataProfit.id
    );
  };

  const handleCancelFilter = async () => {
    store.transaction.filterStart = null;
    store.transaction.filterEnd = null;
    store.transaction.visibleModalFilterTransaction = false;
    actionFilter === true
        ? await store.transaction.getDataHistoryTopUp(id)
        : await store.transaction.getDetailHistoryTransaction(
            store.authentication.dataProfit.id
        );
    // await store.transaction.getDetailHistoryTransaction(
    //   store.authentication.dataProfit.id
    // );
    setAction(false);
  };

  const handleCancelFilterByTrxId = async () => {
    store.transaction.filterStart = null;
    store.transaction.filterEnd = null;
    store.transaction.filterTrxId = null;
    store.transaction.filterPartnerTrxId = null;
    store.transaction.visibleModalFilterTransactionByCode = false;

    setActionFilterByCode(false);
  };

  const handleSubmitFilter = async () => {
    const data = form.getFieldsValue();
    store.transaction.filterStart = moment(data.start_date).format(
        "YYYY-MM-DD"
    ) + " 00:00:00";
    store.transaction.filterEnd = moment(data.end_date).format(
        "YYYY-MM-DD"
    ) + " 23:59:59";
    modalLoader.setLoading(true);
    actionFilter === true
        ? await store.transaction.getDataHistoryTopUp(id)
        : await store.transaction.getDetailHistoryTransaction(
            store.authentication.dataProfit.id
        );
    modalLoader.setLoading(false);
    store.transaction.filterStart = null;
    store.transaction.filterEnd = null;
    store.transaction.visibleModalFilterTransaction = false;
    setAction(false);
  };

  const handleSubmitFilterByTrxId = async (data) => {
    store.transaction.filterStart = null
    store.transaction.filterEnd = null
    store.transaction.filterTrxId = data.trx_id == undefined || data.trx_id == '' ? null : data.trx_id
    store.transaction.filterPartnerTrxId = data.partner_trx_id == undefined || data.partner_trx_id == '' ? null : data.partner_trx_id
    modalLoader.setLoading(true);

    modalLoader.setLoading(false);
    store.transaction.visibleModalFilterTransactionByCode = false;
    setActionFilterByCode(false);
    await store.transaction.getDetailHistoryTransaction(
        store.authentication.dataProfit.id
    );
  };
  const footerLayoutFilter = [
    <Button
        key={"remove"}
        onClick={handleRemoveFilter}
        style={{
          backgroundColor: "#e74e5e",
          color: "#fff",
        }}
    >
      Hapus Filter
    </Button>,
    <Button key={"cancel"} onClick={handleCancelFilter}>
      Batal
    </Button>,
    <Button
        key={"submit"}
        onClick={handleSubmitFilter}
        style={{
          backgroundColor: "#4e79e7",
          color: "#fff",
        }}
    >
      Terapkan
    </Button>,
  ];

  const footerLayoutFilterByTrxId = [
    <Button
        key={"remove"}
        onClick={handleRemoveFilterByTrxId}
        style={{
          backgroundColor: "#e74e5e",
          color: "#fff",
        }}
    >
      Hapus Filter
    </Button>,
    <Button key={"cancel"} onClick={handleCancelFilterByTrxId}>
      Batal
    </Button>,
    <Button
        key={"submit"}
        onClick={() => {
          formFilterByTrxCode.validateFields().then((data) => {
            handleSubmitFilterByTrxId(data)
          }).catch((info) => {
            console.error("Validate Failed:", info);
          });
        }}
        style={{
          backgroundColor: "#4e79e7",
          color: "#fff",
        }}
    >
      Terapkan
    </Button>,
  ];

  return (
      <div className={["ppob-container"].join(" ")}>
        <BreadcumbComponent
            data={
              store.authentication.userData.role === "Admin" ? routeData : dataRoute
            }
        />
        <Card>
          <Row style={{ marginBottom: 10 }}>
            <Title strong level={2}>
              Detail User
            </Title>
            <Col
                lg={store.authentication.userData.role === "Admin" ? 18 : 17}
                xs={store.authentication.userData.role === "Admin" ? 18 : 17}
                style={{ textAlign: "right" }}
            >
              <Space
                  size="small"
                  align={"center"}
                  wrap={true}
                  style={{ textAlign: "center" }}
              >
                {store.authentication.userData.role === "Admin" && (
                    <Space>
                      <Button
                          type={
                            store.membership.dataDetail.is_active === true
                                ? "danger"
                                : "primary"
                          }
                          onClick={() =>
                              changeStatus(
                                  store.membership.dataDetail.id,
                                  store.membership.dataDetail.is_active
                              )
                          }
                      >
                        {store.membership.dataDetail.is_active === true
                            ? "Inactive"
                            : "Active"}
                      </Button>
                      <Button
                          onClick={() => {
                            setInitialData({
                              id: store.membership.dataDetail.id,
                              code: store.membership.dataDetail.partner.code,
                              name: store.membership.dataDetail.userDetail.name,
                              username: store.membership.dataDetail.username,
                              identity_number:
                              store.membership.dataDetail.userDetail
                                  .identity_number,
                              image_identity:
                              store.membership.dataDetail?.userDetail
                                  .image_identity,
                              image_store:
                              store.membership.dataDetail?.userDetail.image_store,
                              phone_number:
                              store.membership.dataDetail.userDetail.phone_number,
                              roleId: store.membership.dataDetail.roles.id,
                              isChangePassword: false,
                            });
                            console.log(
                                store.membership.dataDetail.userDetail.identity_number
                            );
                            console.log(store.membership.dataDetail.userDetail.id);
                            console.log(
                                store.membership.dataDetail.userDetail.image_identity
                            );
                            console.log(
                                store.membership.dataDetail.userDetail.image_store,
                                "ini store"
                            );
                            console.log(store.membership.dataDetail.username);
                            setVisibleModal(true);
                          }}
                      >
                        Edit
                      </Button>
                      <Button
                          onClick={() => {
                            setInitialData({
                              id: store.membership.dataDetail.id,
                              name: store.membership.dataDetail.userDetail.name,
                              username: store.membership.dataDetail.username,
                              phone_number:
                              store.membership.dataDetail.userDetail.phone_number,
                              roleId: store.membership.dataDetail.roles.id,
                              isChangePassword: true,
                            });
                            setVisibleModal(true);
                          }}
                      >
                        Ganti Password
                      </Button>
                    </Space>
                )}
                {((store.authentication.userData.role === "Sales" &&
                        store.membership.dataDetail.is_rejected === true &&
                        store.membership.dataDetail.is_active === false) ||
                    (store.authentication.userData.role === "Supervisor" &&
                        store.membership.dataDetail.is_rejected === true &&
                        store.membership.dataDetail.is_active === false)) && (
                    <Button
                        style={{
                          backgroundColor: "#1bb91d",
                          color: "#fff",
                        }}
                        onClick={() => handleResend(store.membership.dataDetail.id)}
                    >
                      Resend
                    </Button>
                )}
                {store.authentication.userData.role === "Admin" && (
                    <>
                      <Button
                          onClick={() => withdrawProfit(store.membership.dataDetail.id)}
                      >
                        Withdraw Profit
                      </Button>
                      <Button
                          onClick={() => setModalExport(true)}
                      >
                        <ExportOutlined /> Export
                      </Button>
                    </>
                )}
              </Space>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col lg={12} xs={24}>
              <Row>
                <Col span={12}>
                  <Text strong>Name</Text>
                </Col>
                <Col span={12}>
                  <Text>{store.authentication.dataProfit.userDetail?.name}</Text>
                </Col>
                <Col span={12}>
                  <Text strong>Username</Text>
                </Col>
                <Col span={12}>
                  <Text>{store.authentication.dataProfit.username}</Text>
                </Col>
                {/* <Col span={12}>
                <Text strong>Role</Text>
              </Col>
              <Col span={12}>
                <Text>{store.authentication.dataProfit.roles?.name}</Text>
              </Col> */}
                <Col span={12}>
                  <Text strong>Phone Number</Text>
                </Col>
                <Col span={12}>
                  <Text>
                    {store.authentication.dataProfit.userDetail?.phone_number}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text strong>Id Number</Text>
                </Col>
                <Col span={12}>
                  <Text>
                    {store.authentication.dataProfit.userDetail?.identity_number}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text strong>Code</Text>
                </Col>
                <Col span={12}>
                  <Text>
                    {store.authentication.dataProfit.partner?.code}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text strong>Status</Text>
                </Col>
                <Col span={12}>
                  <Text>
                    {store.authentication.dataProfit.is_active === true
                        ? "Aktif"
                        : "Inaktif"}
                  </Text>
                </Col>
                {store.authentication.userData.role === "Admin" &&
                    store.authentication.dataProfit.roles?.name !==
                    "Admin Partner" && (
                        <Row>
                          <Col span={12}>
                            <Text strong>Foto Identitas</Text>
                          </Col>
                          <Col span={12}></Col>
                          <Col span={12}>
                            <Text>
                              <Image
                                  src={
                                    store.authentication.dataProfit.userDetail
                                        ?.image_identity
                                        ? `${appConfig.apiUrl}/config/image/${store.authentication.dataProfit.userDetail?.image_identity}`
                                        : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                                  }
                                  style={{ width: "8vw" }}
                              />
                            </Text>
                          </Col>
                          {store.authentication.dataProfit.roles?.name ===
                              "Retail" && (
                                  <Col span={24}>
                                    <Text strong>Foto Toko</Text>
                                    <Text>
                                      <Row>
                                        {store.authentication.listImage
                                            ? store.authentication.listImage.map(
                                                (item, index) => (
                                                    <Image
                                                        key={index}
                                                        src={
                                                          item
                                                              ? `${appConfig.apiUrl}/config/image/${item}`
                                                              : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                                                        }
                                                        style={{ width: "8vw", marginRight: 10 }}
                                                    />
                                                )
                                            )
                                            : ""}
                                      </Row>
                                    </Text>
                                  </Col>
                              )}
                        </Row>
                    )}
                {store.authentication.userData.role === "Sales" && (
                    <Row>
                      <Col span={12}>
                        <Text strong>Foto Identitas</Text>
                      </Col>
                      <Col span={12}></Col>
                      <Col span={12}>
                        <Text>
                          <Image
                              src={
                                store.authentication.dataProfit.userDetail
                                    ?.image_identity
                                    ? `${appConfig.apiUrl}/config/image/${store.authentication.dataProfit.userDetail?.image_identity}`
                                    : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                              }
                              style={{ width: "10vw" }}
                          />
                        </Text>
                      </Col>
                      <Col span={24}>
                        <Text strong>Foto Toko</Text>
                        <Text>
                          <Row>
                            {store.authentication.listImage
                                ? store.authentication.listImage.map(
                                    (item, index) => (
                                        <Image
                                            key={index}
                                            src={
                                              item
                                                  ? `${appConfig.apiUrl}/config/image/${item}`
                                                  : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                                            }
                                            style={{ width: "10vw", marginRight: 15 }}
                                        />
                                    )
                                )
                                : ""}
                          </Row>
                        </Text>
                      </Col>
                    </Row>
                )}
                {store.authentication.userData.role === "Supervisor" && (
                    <Row>
                      <Col span={12}>
                        <Text strong>Foto Identitas</Text>
                      </Col>
                      <Col span={12}></Col>
                      <Col span={12}>
                        <Text>
                          <Image
                              src={
                                store.authentication.dataProfit.userDetail
                                    ?.image_identity
                                    ? `${appConfig.apiUrl}/config/image/${store.authentication.dataProfit.userDetail?.image_identity}`
                                    : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                              }
                              style={{ width: "10vw" }}
                          />
                        </Text>
                      </Col>
                    </Row>
                )}
              </Row>
            </Col>
            <Col lg={12} xs={24}>
              <Row>
                <Col lg={24} xs={24}>
                  <Row>
                    <Col span={store.ui.mediaQuery.isMobile ? 24 : 10}>
                      <Row justify={"center"}>
                        <Col lg={12} xs={12}>
                          <Title strong level={3} style={styleSaldoTitle}>
                            Saldo
                          </Title>
                        </Col>
                        <Col lg={24} xs={12}>
                          <Text style={styleSaldoContent}>
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            }).format(
                                store.authentication.dataProfit.wallet || 0
                            )}
                          </Text>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Col>
                    <Col span={store.ui.mediaQuery.isMobile ? 24 : 10}>
                      <Row justify={"center"}>
                        <Col lg={12} xs={12}>
                          <Title strong level={3} style={styleSaldoTitle}>
                            Keuntungan
                          </Title>
                        </Col>
                        <Col lg={24} xs={12}>
                          <Text style={styleSaldoContent}>
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            }).format(
                                store.authentication.dataProfit?.profit || 0
                            )}
                          </Text>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Riwayat Top Up" key="1">
                  {store.ui.mediaQuery.isDesktop && (
                      <div>
                        <Button
                            style={{ marginBottom: "1rem", marginLeft: 5 }}
                            onClick={() => {
                              store.transaction.visibleModalFilterTransaction = true;
                              store.transaction.page = 0;
                              setAction(true);
                            }}
                        >
                          <FilterOutlined />
                          Filter
                        </Button>
                        <Table
                            key="1"
                            hasEmpty
                            columns={columns}
                            dataSource={store.transaction.dataHistoryTopUp}
                            bordered
                            pagination={{
                              pageSize: store.transaction.pageSizeHistoryTopUp,
                              total: store.transaction.total_dataHistoryTopUp,
                              current: store.transaction.pageHistoryTopUp + 1,
                              showSizeChanger: true,
                              simple: false,
                            }}
                            onChange={async (page) => {
                              let pageNumber = page.current;
                              store.transaction.pageSizeHistoryTopUp = page.pageSize;
                              store.transaction.pageHistoryTopUp = pageNumber - 1;
                              modalLoader.setLoading(true);
                              await getData();
                              modalLoader.setLoading(false);
                            }}
                        />
                      </div>
                  )}
                  {store.ui.mediaQuery.isMobile && (
                      <div>
                        <Button
                            style={{ marginBottom: "1rem" }}
                            onClick={() => {
                              store.transaction.visibleModalFilterTransaction = true;
                              store.transaction.page = 0;
                              setAction(true);
                            }}
                        >
                          <FilterOutlined />
                          Filter
                        </Button>
                        <List
                            itemLayout="horizontal"
                            position={"top"}
                            pagination={{
                              onChange: async (page, pageSize) => {
                                store.transaction.pageSizeHistoryTopUp = pageSize;
                                store.transaction.pageHistoryTopUp = page - 1;
                                modalLoader.setLoading(true);
                                await getData();
                                modalLoader.setLoading(false);
                              },
                              pageSize: store.transaction.pageSizeHistoryTopUp,
                              total: store.transaction.total_dataHistoryTopUp,
                              current: store.transaction.pageHistoryTopUp + 1,
                              style: { marginBottom: "1rem", marginRight: "1rem" },
                            }}
                            dataSource={store.transaction.dataHistoryTopUp}
                            style={{ padding: 0 }}
                            renderItem={(item) => {
                              return (
                                  <div>
                                    <List.Item
                                        key={item.id}
                                        style={{
                                          backgroundColor: "#ffffff",
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                    >
                                      <List.Item.Meta
                                          className={["cariparkir-container"].join(" ")}
                                          title={item.sender_name}
                                          description={
                                            <div style={{}}>
                                              <p>
                                                <small>
                                                  Amount :{" "}
                                                  {new Intl.NumberFormat("id-ID", {
                                                    style: "currency",
                                                    currency: "IDR",
                                                  }).format(item.amount || 0)}
                                                </small>{" "}
                                                <br />
                                                <small>
                                                  Transaction Date :{" "}
                                                  {moment(new Date (item.transaction_date).toISOString().slice(0, -1)).format("DD MMMM YYYY hh:mm:ss")}
                                                </small>{" "}
                                                <br />
                                              </p>
                                            </div>
                                          }
                                      />
                                    </List.Item>
                                    <Divider plain style={{ margin: 0 }} />
                                  </div>
                              );
                            }}
                        />
                      </div>
                  )}
                </TabPane>
                <TabPane tab="Riwayat Transaksi" key="2">
                  {store.ui.mediaQuery.isDesktop && (
                      <div>
                        <Button
                            style={{ marginBottom: "1rem", marginLeft: 5 }}
                            onClick={() => {
                              store.transaction.visibleModalFilterTransaction = true;
                              store.transaction.page = 0;
                            }}
                        >
                          <FilterOutlined />
                          Filter
                        </Button>

                        <Button
                            style={{ marginBottom: "1rem", marginLeft: 5 }}
                            onClick={() => {
                              store.transaction.visibleModalFilterTransactionByCode = true;
                              store.transaction.page = 0;
                            }}
                        >
                          <FilterOutlined />
                          Filter : kode transaksi/ IDTrx Mitra
                        </Button>
                        <Table
                            key="1"
                            hasEmpty
                            scroll={{ x: 1300 }}
                            columns={column}
                            dataSource={
                              store.transaction.dataDetailHistoryTransactionDetailUser
                            }
                            bordered
                            pagination={store.transaction.total_data > 1000 ? {
                                  pageSizeOptions : [10, 20, 50, 100, 1000],
                                  pageSize: store.transaction.pageSize,
                                  total: store.transaction.total_data,
                                  current: store.transaction.page + 1,
                                  showSizeChanger: true,
                                  simple: false,
                                } :
                                {
                                  pageSize: store.transaction.pageSize,
                                  total: store.transaction.total_data,
                                  current: store.transaction.page + 1,
                                  showSizeChanger: true,
                                  simple: false,
                                }

                            }
                            onChange={async (page) => {
                              let pageNumber = page.current;
                              store.transaction.pageSize = page.pageSize;
                              store.transaction.page = pageNumber - 1;
                              modalLoader.setLoading(true);
                              await getData();
                              modalLoader.setLoading(false);
                            }}
                        />
                      </div>
                  )}

                  {store.ui.mediaQuery.isMobile && (
                      <div>
                        <Button
                            style={{ marginBottom: "1rem" }}
                            onClick={() => {
                              store.transaction.visibleModalFilterTransaction = true;
                              store.transaction.page = 0;
                            }}
                        >
                          <FilterOutlined />
                          Filter
                        </Button>
                        <List
                            itemLayout="horizontal"
                            position={"top"}
                            pagination={{
                              onChange: async (page, pageSize) => {
                                store.transaction.pageSize = pageSize;
                                store.transaction.page = page - 1;
                                modalLoader.setLoading(true);
                                await getData();
                                modalLoader.setLoading(false);
                              },
                              pageSize: store.transaction.pageSize,
                              total: store.transaction.total_data,
                              current: store.transaction.page + 1,
                              style: { marginBottom: "1rem", marginRight: "1rem" },
                            }}
                            dataSource={
                              store.transaction.dataDetailHistoryTransactionDetailUser
                            }
                            style={{ padding: 0 }}
                            renderItem={(item) => {
                              return (
                                  <div>
                                    <List.Item
                                        key={item.id}
                                        style={{
                                          backgroundColor: "#ffffff",
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                    >
                                      <List.Item.Meta
                                          className={[""].join(" ")}
                                          title={item.name}
                                          description={
                                            <div style={{}}>
                                              <p>
                                                <small>Pembeli : {item.buyer}</small>{" "}
                                                <br />
                                                <small>Price : {item.price}</small> <br />
                                                <small>
                                                  Tujuan : {item.transaction_destination}
                                                </small>{" "}
                                                <br />
                                                <small>
                                                  Kode Transaksi : {item.transaction_code}
                                                </small>{" "}
                                                <br />
                                                <small>
                                                  Status :{" "}
                                                  {
                                                    <Tag
                                                        color={
                                                          item.status === 1
                                                              ? "success"
                                                              : item.status === 0
                                                                  ? "warning"
                                                                  : "processing"
                                                        }
                                                    >
                                                      {item.status === 1
                                                          ? "Success"
                                                          : item.status === 0
                                                              ? "Pending"
                                                              : "Failed"}
                                                    </Tag>
                                                  }
                                                </small>{" "}
                                                <br />
                                                <small>
                                                  No.Seri : {item.seri_number}
                                                </small>{" "}
                                                <br />
                                                <small>
                                                  IDTrx Mitra :{" "}
                                                  {item.partner_transaction_code}
                                                </small>{" "}
                                                <br />
                                                <small>
                                                  Transaction Date :{" "}
                                                  {
                                                    moment(new Date (item.created_at).toISOString().slice(0, -1)).format("MM-DD-YYYY HH:mm:ss")  }
                                                </small>{" "}
                                                <br />
                                              </p>
                                            </div>
                                          }
                                      />
                                    </List.Item>
                                    <Divider plain style={{ margin: 0 }} />
                                  </div>
                              );
                            }}
                        />
                      </div>
                  )}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
          <div />
        </Card>
        <MembershipModal
            visible={visibleModal}
            confirmLoading={confirmLoading}
            initialData={initialData}
            onCreate={async (data) => {
              onSubmit(data);
            }}
            onCancel={() => {
              setInitialData({});
              setVisibleModal(false);
            }}
        />
        <Modal
            visible={store.transaction.visibleModalFilterTransaction}
            title={"Filter"}
            footer={footerLayoutFilter}
            onCancel={async () => {
              //form.resetFields();
              store.transaction.filterStart = null;
              store.transaction.filterEnd = null;
              store.transaction.visibleModalFilterTransaction = false;
              actionFilter === true
                  ? await store.transaction.getDataHistoryTopUp(id)
                  : await store.transaction.getDetailHistoryTransaction(
                      store.authentication.dataProfit.id
                  );
              //await store.transaction.getDetailHistoryTransaction();
            }}
        >
          <Row>
            <Col span={24}>
              <Form layout="vertical" name="filter" form={form}>
                <Form.Item
                    name="start_date"
                    label="Dari"
                    rules={[{ required: true, message: "Please input Date!" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    name="end_date"
                    label="Sampai"
                    rules={[{ required: true, message: "Please input Date!" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>

        <Modal
            visible={store.transaction.visibleModalFilterTransactionByCode}
            title={"Filter : kode transaksi/ IDTrx Mitra"}
            footer={footerLayoutFilterByTrxId}
            onCancel={async () => {
              store.transaction.filterStart = null;
              store.transaction.filterEnd = null;
              store.transaction.filterTrxId = null;
              store.transaction.filterPartnerTrxId = null;
              store.transaction.visibleModalFilterTransactionByCode = false;
              actionFilter === true
                  ? await store.transaction.getDataHistoryTopUp(id)
                  : await store.transaction.getDetailHistoryTransaction(
                      store.authentication.dataProfit.id
                  );
              //await store.transaction.getDetailHistoryTransaction();
            }}
        >
          <Row>
            <Col span={24}>
              <Form layout="vertical" name="filter" form={formFilterByTrxCode}>
                <Form.Item
                    name="trx_id"
                    label="Kode Transaksi"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                    name="partner_trx_id"
                    label="IDTrx Mitra"
                >
                  <Input />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>

        <Modal
            visible={modalExport}
            title={"Export"}
            onOk={handleSubmitExport}
            onCancel={() => {
              setModalExport(false);
            }}
        >
          <Row>
            <Col span={24}>
              <Form layout="vertical" name="filter" form={formExport}>
                <Form.Item
                    name="start_date"
                    label="Dari"
                    rules={[{ required: true, message: "Please input Date!" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    name="end_date"
                    label="Sampai"
                    rules={[{ required: true, message: "Please input Date!" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
  );
});
