import React from "react";
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";

export const BreadcumbComponent = (props) => {
    return (
        <div>
            <Breadcrumb>
                {props.data.map((e, index) => (
                    <Breadcrumb.Item key={index}>
                        <Link to={e.route}>
                            <span>{e.name}</span>
                        </Link>
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </div>
    );
};
