import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Input, Row, Tabs, message } from "antd";
import { FilterOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import { LINKS } from "../../routes/app";
import { CategoryComponent } from "../../component/CategoryComponent";
import { ModalLoaderContext } from "../../utils/modal";

export const Category = observer(() => {
  const store = useStore();
  const modalLoader = useContext(ModalLoaderContext);

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        await store.category.getData();
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.CATEGORY,
      name: <span style={{ fontWeight: "bold" }}>Kategori</span>,
    },
  ];

  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent data={routeData} />
      <Card>
        <Row style={{ marginBottom: 20 }}>
          <Col span={24} style={{ textAlign: "right" }}>
            {store.authentication.userData.role !== "Customer Service" && (
              <Button
                onClick={() => (store.category.visibleModalCategory = true)}
              >
                <PlusSquareOutlined /> New
              </Button>
            )}
          </Col>
        </Row>

        <CategoryComponent />
      </Card>
    </div>
  );
});
