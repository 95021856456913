export class TokenUtil {
    static accessToken = null;

    static loadToken() {
        const accessToken = localStorage.getItem('access_token');

        if (accessToken) {
            TokenUtil.setAccessToken(accessToken);
        }
    }

    static persistToken() {
        if (TokenUtil.accessToken != null) {
            localStorage.setItem('access_token', TokenUtil.accessToken);
        } else {
            localStorage.removeItem('access_token');
        }

    }

    static setAccessToken(accessToken) {
        TokenUtil.accessToken = accessToken;
    }

    static clearAccessToken() {
        TokenUtil.accessToken = null;
    }
}
