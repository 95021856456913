import { makeAutoObservable } from "mobx";
import { http } from "../utils/http";

export class Payback {
  pageCreated = 0;
  pageSizeCreated = 10;
  dataCreated = [];
  totalDataCreated = 0;
  filterCategory = null;
  visibleModalPayback = false;

  pageConfirmation = 0;
  pageSizeConfirmation = 10;
  dataConfirmation = [];
  totalDataConfirmation = 0;
  //data=[]

  //filter
  visibleModalFilterPayback = false;
  filterMembership = null;
  filterStart = null;
  filterEnd = null;

  filterStartConfirmation = null;
  filterEndConfirmation = null;


  //filter created
  visibleModalFilterCreate = false;
  constructor(ctx) {
    this.ctx = ctx;
    makeAutoObservable(this);
  }

  async getDataCreated() {
    try {
      const response = await http.get(
        `/transaction/deposit-return?page=${this.pageCreated}&pageSize=${this.pageSizeCreated}&start=${this.filterStart}&end=${this.filterEnd}`
      );
      console.log(response)
      this.dataCreated =
        response.body.data.map((item, idx) => {
          item.key = idx;
          return item;
        }) ?? [];

      this.totalDataCreated = response.body.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async getDataConfirmation() {
    try {
      const response = await http.get(
        `/transaction/deposit-return/confirmation?page=${this.pageConfirmation}&pageSize=${this.pageSizeConfirmation}&start=${this.filterStart}&end=${this.filterEnd}&sender=${this.filterMembership}`
      );
      console.log(response);
      this.dataConfirmation =
        response.body.data.map((item, idx) => {
          item.key = idx;
          return item;
        }) ?? [];

      this.totalDataConfirmation = response.body.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async update(id, data) {
    try {
      const response = await http.put(`/config/commission/${id}`).send(data);
      await this.getDataCreated();
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async delete(id) {
    try {
      const response = await http.del(`/product/${id}`);
      await this.getDataCreated();
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async create(data) {
    try {
      const response = await http
        .post("/transaction/deposit-return")
        .send(data);
      await this.getDataCreated();
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async confirmPayback(id, data) {
    try {
      const response = await http.put(
        `/transaction/deposit-return/confirmation/${id}/${data}`
      );
      // console.log(response)
      // this.data=response.body.data
      await Promise.all([this.getDataConfirmation(), this.getDataCreated()]);
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async uploadImages(data) {
    try {
      const response = await http.upload(data);
      return response;
    } catch (e) {
      console.error(e);
    }
  }
}
