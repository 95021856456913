import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  Select,
  Space,
  Table,
} from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../utils/useStore";
import { ModalLoaderContext } from "../utils/modal";

export const SubcategoryComponent = observer((props) => {
  const store = useStore();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [idData, setIdData] = useState("");
  const modalLoader = useContext(ModalLoaderContext);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    await store.category.getData();
    await store.subcategory.getData();
  };

  const handleEditButton = (data) => {
    console.log(data, "isi data");
    form.setFieldsValue({
      code: data.code,
      name: data.name,
      categoryId: data.categoryId,
    });
    store.subcategory.visibleModalSubcategory = true;
    setIdData(data.id);
  };

  const columns = [
    {
      title: "Kode",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Sub Kategori",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Kategori",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Tindakan",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEditButton(record)}>Edit</Button>
        </Space>
      ),
    },
  ];

  if (store.authentication.userData.role === "Customer Service") columns.pop();

  const handleCancel = () => {
    setIdData("");
    store.subcategory.visibleModalSubcategory = false;
  };

  const handleSubmit = async (data) => {
    console.log(data, "isi data2");
    if (idData !== "") {
      modalLoader.setLoading(true);
      try {
        const response = await store.subcategory.update(idData, data);
        response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(
              response?.body?.message || "Berhasil Ubah Data Sub Kategori"
            )
          : message.error(
              response?.body?.message || "Gagal Ubah Data Sub Kategori"
            );
        await getData();
      } catch (e) {
        message.error(
          e.response?.body?.message || "Gagal Ubah Data Sub Kategori"
        );
      }
      modalLoader.setLoading(false);
      store.subcategory.visibleModalSubcategory = false;
      setIdData("");
      form.resetFields();
    } else {
      modalLoader.setLoading(true);
      try {
        const response = await store.subcategory.create(data);
        response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(
              response?.body?.message || "Berhasil Tambah Sub Kategori"
            )
          : message.error(
              response?.body?.message || "Gagal Tambah Sub Kategori"
            );
        await getData();
      } catch (e) {
        console.log(e, "apa errornya");
        message.error(e.response?.body?.message || "Gagal Tambah Sub Kategori");
      }
      modalLoader.setLoading(false);
      store.subcategory.visibleModalSubcategory = false;
      setIdData("");
      form.resetFields();
    }
  };

  return (
    <div>
      {store.ui.mediaQuery.isDesktop && (
        <Table
          style={{ textAlign: "center" }}
          columns={columns}
          dataSource={store.subcategory.data}
          bordered
          pagination={{
            pageSize: store.subcategory.pageSize,
            total: store.subcategory.total_data,
            current: store.subcategory.page + 1,
            showSizeChanger: true,
            simple: false,
          }}
          onChange={async (page) => {
            let pageNumber = page.current;
            store.subcategory.pageSize = page.pageSize;
            store.subcategory.page = pageNumber - 1;
            modalLoader.setLoading(true);
            await getData();
            modalLoader.setLoading(false);
          }}
        />
      )}
      {store.ui.mediaQuery.isMobile && (
        <List
          itemLayout="horizontal"
          position={"top"}
          pagination={{
            onChange: async (page, pageSize) => {
              store.subcategory.pageSize = pageSize;
              store.subcategory.page = page - 1;
              modalLoader.setLoading(true);
              await store.subcategory.getData();
              modalLoader.setLoading(false);
            },
            pageSize: store.subcategory.pageSize,
            total: store.subcategory.total_data,
            current: store.subcategory.page + 1,
            style: { marginBottom: "1rem", marginRight: "1rem" },
          }}
          dataSource={store.subcategory.data}
          style={{ padding: 0 }}
          renderItem={(item) => {
            return (
              <div>
                <List.Item
                  key={item.id}
                  style={{
                    backgroundColor: "#ffffff",
                    paddingTop: 0,
                    paddingBottom: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <List.Item.Meta
                    className={[""].join(" ")}
                    title={item.code}
                    description={
                      <div style={{}}>
                        <p>
                          <small>Sub Category : {item.name}</small> <br />
                          <small>Category : {item.categoryName}</small>
                          <br />
                        </p>
                        <p></p>
                      </div>
                    }
                  />
                  <div style={{ marginRight: 16 }}>
                    <p
                      style={{
                        fontSize: 9,
                        margin: 0,
                      }}
                    >
                      {store.authentication.userData.role === "Admin" && (
                        <Button onClick={() => handleEditButton(item)}>
                          Edit
                        </Button>
                      )}
                    </p>
                  </div>
                </List.Item>
                <Divider plain style={{ margin: 0 }} />
              </div>
            );
          }}
        />
      )}
      <Modal
        visible={store.subcategory.visibleModalSubcategory}
        title={idData ? "Edit Sub Kategori" : "Buat Sub Kategori Baru"}
        okText={idData ? "Edit" : "Buat"}
        cancelText="Batal"
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values, "isi form");
              handleSubmit(values);
              form.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          {idData === "" && (
            <Form.Item
              name="code"
              label="Code"
              rules={[
                { required: true, message: "Please input code category!" },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input name category!" }]}
          >
            <Input />
          </Form.Item>
          {/* {!idData && ( */}
          <Form.Item
            name="categoryId"
            label="Categories"
            rules={[{ required: true, message: "Please input category id!" }]}
          >
            <Select placeholder="Select Category" allowClear>
              {store.category.data.map((item) => (
                <Option value={item.id} key={item.id} defaultValue={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* )} */}
        </Form>
      </Modal>
    </div>
  );
});
