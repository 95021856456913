import React, { useContext, useEffect, useState } from "react";
import { useStore } from "../../utils/useStore";
import {
  Card,
  Tabs,
  Col,
  Button,
  Typography,
  Select,
  Modal,
  Row,
  DatePicker,
  Form,
  message,
  Input
} from "antd";

import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { Product } from "./Product";
import { LINKS } from "../../routes/app";
import { observer } from "mobx-react-lite";
import { ModalLoaderContext } from "../../utils/modal";
import { FilterOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
export const Transaction = observer(() => {
  const store = useStore();
  const { Title } = Typography;
  const { Option } = Select;
  const modalLoader = useContext(ModalLoaderContext);
  const [form] = Form.useForm();
  const [visibleModalBuy, setVisibleModalBuy] = useState(false);
  const [barang, setBarang] = useState({});

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        await store.transaction.getDataCategories();
        await store.transaction.getDataSubCategories();
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const routeData = [
    {
      route: LINKS.TRANSACTION,
      name: "Transaksi",
    },
  ];


  const handleChangeTabs = async (item) => {
    modalLoader.setLoading(true);
    store.transaction.dataSubCategories = [];
    store.transaction.data = [];
    store.transaction.filterSubCategory = null;
    store.transaction.filterCategory = item;
    await store.transaction.getDataSubCategories();
    modalLoader.setLoading(false);
  };

  const handleChangeSubcategory = async (item) => {
    store.transaction.filterSubCategory = item;
    modalLoader.setLoading(true);
    await store.transaction.getData();
    modalLoader.setLoading(false);
  };


  const handleBuyProduct = async (data, productCode, productId) => {
    modalLoader.setLoading(true);
    try {
      const response = await store.transaction.buyProd({
        ...data,
        productCode: productCode,
        productId: productId
      });

      if (response.status === 201) {
        message.success(response?.body?.message || "Berhasil Beli Produk");
      } else {
        message.error(response?.body?.error || "Gagal Beli Produk", 3);
      }
      setVisibleModalBuy(false);
    } catch (e) {
      console.log("testingan");
      console.log(e.response, "testingan");
      console.log(e.result, "testingan1");
      if (e.response?.body?.error) {
        message.error(e.response.body.error);
        setVisibleModalBuy(false);
        modalLoader.setLoading(false);
        return;
      }
      console.log(e, "apa errornya");
      message.error("Gagal Beli Product");
    }
   // message.success("Berhasil Beli Produk");
    setVisibleModalBuy(false);
    modalLoader.setLoading(false);
  };

  // const handleBuyStag = async (data, productCode) => {
  //   modalLoader.setLoading(true);
  //   try {
  //     const response = await store.transaction.buyProduct({
  //       ...data,
  //       productCode: productCode,
  //     });
  //     if (response.status === 201) {
  //       message.success(response?.body?.message || "Berhasil Beli Produk");
  //     } else {
  //       message.error(response?.body?.error || "Gagal Beli Produk", 3);
  //     }
  //   } catch (e) {
  //     console.log("testingan");
  //     console.log(e.response, "testingan");
  //     console.log(e.result, "testingan1");
  //     if (e.response?.body?.error) {
  //       message.error(e.response.body.error);
  //       setVisibleModalBuy(false);
  //       modalLoader.setLoading(false);
  //       return;
  //     }
  //     console.log(e, "apa errornya");
  //     message.error("Gagal Beli Product");
  //   }
  //   // setDataProd(false);
  //   // setDataStag(false);
  //   setVisibleModalBuy(false);
  //   modalLoader.setLoading(false);
  // };


  const handleCancel = () => {
    form.resetFields();
    setVisibleModalBuy(false);
  };

  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent data={routeData} text="" />
      <Card>
        <Col span={12} style={{ marginBottom: 30 }}>
          {/* <Button
            onClick={() => {
              store.transaction.visibleModalFilterTransaction = true;
            }}
          >
            <FilterOutlined />
            Filter
          </Button> */}
        </Col>
        {store.ui.mediaQuery.isDesktop && (
          <Tabs onChange={handleChangeTabs} size="default" tabBarGutter="50">
            {store.transaction.dataCategories.map((item, index) => (
              <TabPane tab={item.name} key={item.id}>
                <Product />
              </TabPane>
            ))}
          </Tabs>
        )}

        {store.ui.mediaQuery.isMobile && (
          <div>
            <Row>
              <span style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Jenis Produk
              </span>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  placeholder={"Pilih Jenis Produk"}
                  allowClear={true}
                  onChange={(val) => handleChangeTabs(val)}
                  style={{ marginBottom: "10px", width: "100%" }}
                >
                  {store.transaction.dataCategories.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row>
              <span style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Kategori
              </span>
            </Row>
            <Row>
              <Col span={24}>
                <Select
                  placeholder={"Pilih Kategori"}
                  allowClear={true}
                  onChange={(val) => handleChangeSubcategory(val)}
                  style={{ marginBottom: "10px", width: "100%" }}
                  value={store.transaction.filterSubCategory}
                >
                  {store.transaction.dataSubCategories.map((item, index) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row
              justify={"center"}
              align={"center"}
              style={{ marginBottom: "1rem" }}
            >
              <Col
                span={12}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Produk & Nominal
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                {/* <Search
                  placeholder="input search text"
                  style={{ width: 200, marginRight: 10 }}
                /> */}
              </Col>
            </Row>
            {store.transaction.data.length != 0 && (
              <Row>
                {store.transaction.data.map((item, index) => (
                  <Col key={index} xs={24} md={16} lg={8}>
                    <Card
                      onClick={() => {
                        setVisibleModalBuy(true);
                        setBarang(item);
                        console.log(item?.product_name)
                      }}
                      hoverable
                      style={{
                        cursor: "pointer",
                        marginLeft: 10,
                        borderColor: "salmon",
                        height: 100,
                        marginBottom: 10,
                      }}
                    >
                      <span style={{ color: "black" }}>
                        {item?.product_name}
                      </span>
                      <br />
                      <span style={{ color: "grey", fontSize: 10 }}>
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        }).format(item?.price)}
                      </span>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
            <Modal
              visible={visibleModalBuy}
              title={`Apakah anda yakin membeli ${barang?.product_name}?`}
              okText={"Confirm"}
              onCancel={() => {
                form.resetFields();
                setVisibleModalBuy(false);
              }}
              // footer={footerLayoutFilter}
              footer={[
                <Button
                  key="back"
                  style={{
                    backgroundColor: "#e74e5e",
                    color: "#fff",
                  }}
                  onClick={() => {
                    form.resetFields();
                    handleCancel();
                  }}
                >
                  Batal
                </Button>,
                <Button
                  key="Beli"
                  style={{
                    backgroundColor: "#4e79e7",
                    color: "#fff",
                    marginRight:10
                  }}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => {
                        console.log(values, "isi form");
                        handleBuyProduct(values, barang.product_code, barang.product_id);
                        form.resetFields();
                      })
                      .catch((info) => {
                        console.error("Validate Failed:", info);
                      });
                  }}
                >
                  Beli
                </Button>,
                // <Button
                //   key="Beli Stag"
                //   type="primary"
                //   onClick={() => {
                //     form
                //       .validateFields()
                //       .then((values) => {
                //         console.log(values, "isi form");
                //         handleBuyStag(values, barang.product_code);
                //         form.resetFields();
                //       })
                //       .catch((info) => {
                //         console.error("Validate Failed:", info);
                //       });
                //   }}
                // >
                //   Beli Staging
                // </Button>,
              ]}
            >
              <Form form={form} layout="vertical">
                <Form.Item
                  name="destination"
                  label="Nomor Tujuan"
                  rules={[
                    {
                      required: true,
                      message: "Tolong masukan nomor tujuan!",
                    },
                    // {
                    //   pattern: /^(?:\d*)$/,
                    //   message: "Value should contain just number",
                    // },
                    // {
                    //   pattern: /^[\d]{1,12}$/,
                    //   message: "Value should be 1 - 12 character",
                    // },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        )}
      </Card>
    </div>
  );
});
