import React, { useContext, useState } from "react";
import { Button, Form, Input, message, Modal, Space, Table } from "antd";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { useStore } from "../utils/useStore";
import { ModalLoaderContext } from "../utils/modal";

export const CommissionComponent = observer((props) => {
  const store = useStore();
  const [form] = Form.useForm();
  const history = useHistory();
  const [idData, setIdData] = useState("");
  const modalLoader = useContext(ModalLoaderContext);

  const handleEditButton = (data) => {
    console.log(data, "isi data");
    form.setFieldsValue({
      value: data.commission,
    });
    store.commission.visibleModalCommission = true;
    setIdData(data.id);
  };

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Nominal",
      dataIndex: "commission",
      key: "commission",
      render: (text) => <span>{text}%</span>,
    },
    {
      title: "Tindakan",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEditButton(record)}>Edit</Button>
        </Space>
      ),
    },
  ];

  if (store.authentication.userData.role === "Customer Service") columns.pop();

  const handleCancel = () => {
    setIdData("");
    store.commission.visibleModalCommission = false;
  };

  const handleSubmit = async (data) => {
    modalLoader.setLoading(true);
    try {
      const response = await store.commission.update(idData, data);
      response?.body?.statusCode === 201 || response?.body?.statusCode === 200
        ? message.success(response?.body?.message || "Berhasil Ubah Komisi")
        : message.error(response?.body?.message || "Gagal Ubah Komisi");
    } catch (e) {
      message.error(e.response?.body?.message || "Gagal Ubah Komisi");
    }
    modalLoader.setLoading(false);
    store.commission.visibleModalCommission = false;
    setIdData("");
    form.resetFields();
  };

  return (
    <div>
      <Table
        style={{ textAlign: "center" }}
        columns={columns}
        dataSource={store.commission.data}
        bordered
        pagination={{
          pageSize: store.product.pageSize,
          total: store.product.total_data,
          current: store.product.page + 1,
          showSizeChanger: true,
          simple: false,
        }}
        onChange={async (page) => {
          let pageNumber = page.current;
          store.commission.pageSize = page.pageSize;
          store.commission.page = pageNumber - 1;
          modalLoader.setLoading(true);
          await store.commission.getData();
          modalLoader.setLoading(false);
        }}
      />

      <Modal
        visible={store.commission.visibleModalCommission}
        title={"Edit Commission"}
        okText={"Edit"}
        cancelText="Batal"
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values, "isi form");
              handleSubmit(values);
              form.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="value"
            label="Commission"
            rules={[{ required: true, message: "Please input commission!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
