import {makeAutoObservable} from "mobx";

export class UI {
    mediaQuery = {};
    testValue = "Test Mobx";
    leftDrawerIsShown = false;
    params = {};
    baseUrl = '/app';

    constructor(ctx) {
        this.ctx = ctx;
        makeAutoObservable(this);
    }

    setTestValue() {
        this.testValue = "yoshahhh #!";
    }

    setMediaQuery(data) {
        if (this.mediaQuery.isDesktop !== data.isDesktop || this.mediaQuery.isMobile !== data.isMobile) {
            this.mediaQuery = data;
        }
    };

    toggleLeftDrawerIsShown() {
        //console.log('what')
        this.leftDrawerIsShown = !this.leftDrawerIsShown;
    }

    setParams(data) {
        this.params = data;
    };
}
