import React from 'react';
import {Store} from "../store";
import {useLocalObservable} from "mobx-react-lite";

const storeContext = React.createContext(null);

export const store = new Store();
// store.authentication.loadToken();

export const StoreProvider = ({children}) => {
    const localStore = useLocalObservable(() => {
        return store;
    });
    return <storeContext.Provider value={localStore}>{children}</storeContext.Provider>
};

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // throw new Error('useStore must be used within a StoreProvider.');
    }
    return store;
};
