import {makeAutoObservable} from "mobx";
import {http} from "../utils/http";

export class Role {
  page = 0;
  pageSize = 10;
  data = [];
  total_data = 0;

  constructor(ctx) {
    this.ctx = ctx;
    makeAutoObservable(this);
  }

  async getData(isForMembership = false) {
    try {
      const response = await http.get(
        `/config/roles${isForMembership ? "/for-membership" : ""}?page=${
          this.page
        }&pageSize=${this.pageSize}`
      );
      this.data = response.body.data ?? [];
      this.total_data = response?.body?.count ?? 0;
    } catch (e) {
      console.error(e);
    }
  }

  async create(data) {
    try {
      return await http.post("/users").send(data);
    } catch (e) {
      console.error(e);
    }
  }

  async update(id, data) {
    try {
      return await http.put("/users/" + id).send(data);
    } catch (e) {
      console.error(e);
    }
  }

  async delete(id) {
    try {
      return await http.del("/users/" + id);
    } catch (e) {
      console.error(e);
    }
  }
}
