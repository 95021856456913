import { makeAutoObservable } from "mobx";
import { http } from "../utils/http";

export class Product {
    page = 0;
    pageSize = 10
    data = [];
    total_data = 0;
    total_data_partner = 0;
    filterSupplier = null;
    filterSubCategory = null;
    visibleModalProduct = false;
    visibleModalFilterProduct = false;
    uploadBtnProduct = false;

    pageCategories = 0;
    pageSizeCategories = 100;
    dataCategories = [];
    total_dataCategories = 0;

    pageSubCategories = 0;
    pageSizeSubCategories = 10;
    dataSubCategories = [];
    total_dataSubCategories = 0;
    filterCategory = null;

    dataPriceHistory = [];
    totalDataPriceHistory = 0;
    pagePriceHistory = 0;
    pageProductPartner = 0;
    pageSizeProductPartner = 10
    pageSizePriceHistory = 10

    dataDetailProduct = {};
    dataProductPartner = []

    constructor(ctx) {
        this.ctx = ctx;
        makeAutoObservable(this);
    }

    async getData() {
        try {
            const response = await http.get(`/product/all?supplier=${this.filterSupplier}&sub-category=${this.filterSubCategory}&page=${this.page}&pageSize=${this.pageSize}`);
            console.log(response)
            this.data = response.body.data.map((item, idx) => {
                item.key = idx;
                return item
            }) ?? []

            this.total_data = response?.body?.count ?? 0
            //console.log(this.total_data)
        } catch (e) {
            console.error(e);
        }
    }


    async getDataSubCategories() {
        try {
            const response = await http.get(`/product/sub-categories?category=${this.filterCategory}&page=${this.pageSubCategories}&pageSize=${this.pageSizeSubCategories}`);
            this.dataSubCategories = response.body.data.map((item, idx) => {
                item.key = idx;
                return item
            }) ?? []
            this.total_dataSubCategories = response.body.count ?? 0
        } catch (e) {
            console.error(e);
        }
    }

    async getDataCategories() {
        try {
            const response = await http.get(`/product/categories?page=${this.pageCategories}&pageSize=${this.pageSizeCategories}`);
            this.dataCategories = response.body.data.map((item, idx) => {
                item.key = idx;
                return item
            }) ?? []
        } catch (e) {
            console.error(e);
        }
    }

    async getPriceHistoryByProduct(id) {
        try {
            const response = await http.get(`/product/price-history/${id}?page=${this.pagePriceHistory}&pageSize${this.pageSizePriceHistory}`);
            this.dataPriceHistory = response.body.data
            this.totalDataPriceHistory = response?.body?.count ?? 0
        } catch (e) {
            console.error(e);
        }
    }

    async getDetailProduct(id) {
        try {
            const response = await http.get(`/product/${id}`);
            this.dataDetailProduct = response.body.data
        } catch (e) {
            console.error(e);
        }
    }

    async getProductPartner(id) {
        console.log({ id });
        if (id === undefined) {
            try {
                const response = await http.get(`/product/by-categories?page=${this.pageProductPartner}&pageSize=${this.pageSizeProductPartner}&sub-category=`);
                console.log(response)
                this.dataProductPartner = response.body.data
                this.total_data_partner = response?.body?.count ?? 0
            } catch (e) {
                console.error(e);
            }
        } else {
            try {
                const response = await http.get(`/product/by-categories?page=${this.pageProductPartner}&pageSize=${this.pageSizeProductPartner}&sub-category=${id}`);
                console.log(response)
                this.dataProductPartner = response.body.data
                this.total_data_partner = response?.body?.count ?? 0
            } catch (e) {
                console.error(e);
            }
        }

    }

    async create(data) {
        try {
            const response = await http.post('/product').send(data);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e)
        }
    }

    async buyProduct(data) {
        try {
            const response = await http.post('/transaction/order').send(data);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async update(id, data) {
        try {
            const response = await http.put(`/product/${id}`).send(data);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async delete(id) {
        try {
            const response = await http.del(`/product/${id}`);
            await this.getData();
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async uploadExcel(data) {
        try {
            const response = await http.upload(data);
            return response;
        } catch (e) {
            console.error(e);
        }
    }

    async uploadProduct(data) {
        try {
            const response = await http.post('/product/upload-product').send(data);
            return response;
        } catch (e) {
            console.error(e);
        }
    }
}


