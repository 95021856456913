import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  List,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Select,
  Option,
  Typography, DatePicker,
} from "antd";
import { useStore } from "../../utils/useStore";
import { observer } from "mobx-react-lite";
import {
  DownloadOutlined,
  PlusSquareOutlined,
  FilterOutlined, ExportOutlined,
} from "@ant-design/icons";
import { MembershipModal } from "./MembershipModal";
import { BreadcumbComponent } from "../../component/BreadcumbComponent";
import { LINKS } from "../../routes/app";
import { useHistory } from "react-router-dom";
import { ModalLoaderContext } from "../../utils/modal";
import moment from "moment";

export const Membership = observer(() => {
  const history = useHistory();
  const { Option } = Select;
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [formExport] = Form.useForm();
  const store = useStore();
  const [visibleModal, setVisibleModal] = useState(false);
  const [isVisibleTopUpModal, setIsVisibleTopUpModal] = useState(false);
  const [destination, setDestination] = useState(null);
  const [initialData, setInitialData] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const modalLoader = useContext(ModalLoaderContext);
  const [filterMembership, setFilterMembership] = useState([]);
  const [filterPartner, setFilterPartner] = useState([]);
  const [filterBuyer, setFilterBuyer] = useState("");
  const [modalExport, setModalExport] = useState(false);
  const [filterSupplier, setFilterSupplier] = useState("");

  useEffect(() => {
    const init = async () => {
      try {
        modalLoader.setLoading(true);
        const isAdmin = store.authentication.userData.role === "Admin";
        await getData();
        await store.membership.getData();
        await store.membership.getDataFilter();
        await store.membership.getDataBySuperior();
        await store.partner.getData();
        await store.supplier.getData();
        await store.role.getData(isAdmin);
        modalLoader.setLoading(false);
      } catch (e) {
        console.error(e);
        modalLoader.setLoading(false);
        if (e.response?.body?.message) {
          message.error(e.response.body.message);
          return;
        }
        message.error(e.message);
      }
    };

    init();
  }, []);

  const getData = async () => {
    store.authentication.userData.role === "Admin" ||
    store.authentication.userData.role === "Customer Service"
      ? await store.membership.getData()
      : await store.membership.getDataBySuperior();
  };

  const handleRemoveFilter = async () => {
    store.membership.filterMembership = null;
    store.membership.filterPartner = null;
    setFilterMembership([]);
    setFilterPartner([]);
    store.membership.visibleModalFilterMembership = false;
    await store.membership.getData();
  };
  const handleCancelFilter = async () => {
    store.membership.filterMembership = null;
    store.membership.filterPartner = null;
    store.membership.visibleModalFilterMembership = false;
    await store.membership.getData();
  };

  const handleSubmitFilter = async () => {
    store.membership.filterMembership = filterMembership;
    store.membership.filterPartner = filterPartner;
    modalLoader.setLoading(true);
    await store.membership.getData();
    modalLoader.setLoading(false);
    setFilterMembership([]);
    setFilterPartner([]);
    store.membership.visibleModalFilterMembership = false;
  };

  const footerLayoutFilter = [
    <Button
      key={"remove"}
      onClick={handleRemoveFilter}
      style={{
        backgroundColor: "#e74e5e",
        color: "#fff",
      }}
    >
      Hapus Filter
    </Button>,
    <Button key={"cancel"} onClick={handleCancelFilter}>
      Batal
    </Button>,
    <Button
      key={"submit"}
      onClick={handleSubmitFilter}
      style={{
        backgroundColor: "#4e79e7",
        color: "#fff",
      }}
    >
      Terapkan
    </Button>,
  ];
  const handleCancelTransaction = () => {
    setIsVisibleTopUpModal(false);
    setDestination(null);
  };

  const handleSubmitTransaction = async (data) => {
    modalLoader.setLoading(true);
    try {
      data.destination = destination;
      if (data.amount) {
        data = {
          ...data,
          amount: Number(data.amount),
        };
      }

      let response = null;

      (await store.authentication.userData.role) === "Admin"
        ? (response = await store.transaction.distributeAdmin(data))
        : (response = await store.transaction.distribute(data));

      response?.body?.statusCode === 201
        ? message.success("Sukses Top Up")
        : message.error("Saldo Tidak Mencukupi");

      modalLoader.setLoading(false);
      await getData();
    } catch (e) {
      console.log(e, "apa errornya");
      modalLoader.setLoading(false);
      message.error("Gagal Top Up");
    }
    setConfirmLoading(false);
    setIsVisibleTopUpModal(false);
    form.resetFields();
    setDestination(null);
  };

  const handleSubmitExport = async () => {
    const date = formExport.getFieldsValue();
    const dataAll ={
      dateStart: moment(date.start_date).format("YYYY-MM-DD"),
      dateEnd: moment(date.end_date).format("YYYY-MM-DD"),
      supplier: filterSupplier,
      buyer: filterBuyer
    }
    modalLoader.setLoading(true);
    try {
      await store.transaction.exportTransactionAll(dataAll)
      // console.log('responseiniyak', response);
      // message.success("Success export");
      setModalExport(false);
      modalLoader.setLoading(false);
    } catch (e) {
      setModalExport(false);
      console.error(e, "apa errornya");
      message.error(e.response?.body?.message || "Failed to export");
      modalLoader.setLoading(false);
    }
  };

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      render: (text, record) => record?.name ?? record?.user_detail?.name,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text, record) => record?.name ?? record?.username,
    },
    {
      title: "Role",
      dataIndex: ["roles", "name"],
      key: "role",
    },
    {
      title: "Saldo",
      dataIndex: ["coa", "amount"],
      key: ["coa", "amount"],
      width: "20%",
      render: (text) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(text),
    },
    {
      title: "Tindakan",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {store.authentication.userData.role !== "Customer Service" && (
            <Button
              onClick={() => {
                setDestination(record?.id);
                console.log(record?.id);
                setIsVisibleTopUpModal(true);
              }}
            >
              <DownloadOutlined /> Top Up Saldo
            </Button>
          )}
          <Button
            onClick={async () => {
              await store.transaction.getDataHistoryTopUp(record.id);
              await store.transaction.getDetailHistoryTransaction(record.id);
              await store.authentication.getProfit(record.id);
              history.push(LINKS.USER_DETAIL.replace(":id", record.id));
              console.log(record.id);
            }}
          >
            Detail
          </Button>
        </Space>
      ),
    },
  ];

  //if (store.authentication.userData.role === "Customer Service") columns.pop();
  const routeData = [
    {
      route: LINKS.HOME,
      name: "Beranda",
    },
    {
      route: LINKS.MEMBERSHIP,
      name: <span style={{ fontWeight: "bold" }}>Keanggotaan</span>,
    },
  ];

  const dataRoute = [
    {
      route: LINKS.MEMBERSHIP,
      name: "Keanggotaan",
    },
  ];
  const onSubmit = async (data, image, imageStore) => {
    data.superior = true;
    console.log(imageStore, "Apa imageStore");

    if (!imageStore) {
      imageStore = [];
    }

    if (initialData.id) {
      data.image_identity = image;
      data.image_store = imageStore;
    }

    if (initialData.id) {
      setConfirmLoading(true);
      modalLoader.setLoading(true);
      try {
        console.log(data, "edit data");
        const request = {
          ...data,
          image_identity: image,
          image_store: imageStore,
        };
        await store.membership.update(initialData.id, request);
        console.log(data, "edit data");
        message.success(
          initialData.isChangePassword
            ? "Success Change Member Password"
            : "Success Update Data Member"
        );
        await getData();
        modalLoader.setLoading(false);
      } catch (e) {
        modalLoader.setLoading(true);
        message.error(
          initialData.isChangePassword
            ? "Failed Update Member Password"
            : "Failed Update Data Member"
        );
      }
      setConfirmLoading(false);
      setVisibleModal(false);
    } else {
      setConfirmLoading(true);
      modalLoader.setLoading(true);
      try {
        console.log(data, "data member");
        const request = {
          ...data,
          image_identity: image,
          image_store: JSON.stringify(imageStore),
        };
        const response = await store.membership.create(request);
        response?.body?.statusCode === 201 || response?.body?.statusCode === 200
          ? message.success(
              response?.body?.message
                ? "Berhasil Tambah Member Baru"
                : response?.body?.message
            )
          : message.error("Username Tersebut Sudah Terdaftar");
        await getData();
      } catch (e) {
        console.log(e, "apa errornya");
        message.error(e.response?.body?.error);
      }
      modalLoader.setLoading(false);
      setConfirmLoading(false);
      setVisibleModal(false);
      setInitialData({});
      form.resetFields();
    }
  };

  return (
    <div className={["ppob-container"].join(" ")}>
      <BreadcumbComponent
        data={
          store.authentication.userData.role === "Admin" ||
          store.authentication.userData.role === "Customer Service"
            ? routeData
            : dataRoute
        }
      />
      <Card>
        <div>
          <Row style={{ marginBottom: 20 }}>
            <Col span={12}>
              {store.authentication.userData.role === "Admin" && (
                <Button
                  onClick={() => {
                    store.membership.visibleModalFilterMembership = true;
                    store.membership.page = 0;
                  }}
                >
                  <FilterOutlined />
                  Filter
                </Button>
              )}
              {store.authentication.userData.role === "Admin" && (
              <Button
                  style={{
                    marginLeft: "10px"
                  }}
                  onClick={() => setModalExport(true)}
              >
                <ExportOutlined /> Export
              </Button>
              )}
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              {/* <Search
                placeholder="input search text"
                style={{
                  width: store.ui.mediaQuery.isMobile ? 160 : 200,
                  marginRight: store.ui.mediaQuery.isMobile ? 0 : 10,
                  marginBottom: store.ui.mediaQuery.isMobile ? 10 : 0,
                }}
              /> */}

              {store.authentication.userData.role !== "Customer Service" && (
                <Button
                  onClick={() => {
                    setInitialData({});
                    setVisibleModal(true);
                  }}
                >
                  <PlusSquareOutlined /> New
                </Button>
              )}
            </Col>
          </Row>
          {store.ui.mediaQuery.isDesktop && (
            <Table
              key="1"
              hasEmpty
              columns={columns}
              //style={{ cursor: "pointer" }}
              dataSource={
                store.authentication.userData.role === "Admin" ||
                store.authentication.userData.role === "Customer Service"
                  ? store.membership.dataMember
                  : store.membership.data
              }
              pagination={{
                pageSize: store.membership.pageSize,
                total:
                  store.authentication.userData.role === "Admin" ||
                  store.authentication.userData.role === "Customer Service"
                    ? store.membership.dataTotal
                    : store.membership.total_data,
                current: store.membership.page + 1,
                showSizeChanger: true,
                simple: false,
              }}
              onChange={async (page) => {
                let pageNumber = page.current;
                store.membership.pageSize = page.pageSize;
                store.membership.page = pageNumber - 1;
                modalLoader.setLoading(true);
                await store.membership.getData();
                await getData();
                modalLoader.setLoading(false);
              }}
            />
          )}

          {store.ui.mediaQuery.isMobile && (
            <List
              itemLayout="horizontal"
              position={"top"}
              pagination={{
                onChange: async (page, pageSize) => {
                  store.membership.pageSize = pageSize;
                  store.membership.page = page - 1;
                  modalLoader.setLoading(true);
                  await getData();
                  modalLoader.setLoading(false);
                },
                pageSize: store.membership.pageSize,
                total:
                  store.authentication.userData.role === "Admin" ||
                  store.authentication.userData.role === "Customer Service"
                    ? store.membership.dataTotal
                    : store.membership.total_data,
                current: store.membership.page + 1,
                style: { marginBottom: "1rem", marginRight: "1rem" },
              }}
              dataSource={
                store.authentication.userData.role === "Admin" ||
                store.authentication.userData.role === "Customer Service"
                  ? store.membership.dataMember
                  : store.membership.data
              }
              style={{ padding: 0 }}
              renderItem={(item) => {
                return (
                  <div>
                    <List.Item
                      key={item.id}
                      style={{
                        backgroundColor: "#ffffff",
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <List.Item.Meta
                        className={[""].join(" ")}
                        title={item.user_detail?.name}
                        description={
                          <div style={{}}>
                            <p>
                              <small>Username : {item.username}</small> <br />
                              <small>Role : {item.roles?.name}</small> <br />
                              <small>Saldo : {item.coa?.amount}</small> <br />
                              {store.authentication.userData.role !==
                                "Customer Service" && (
                                <Button
                                  style={{ marginRight: 10, marginTop: 7 }}
                                  onClick={() => {
                                    setDestination(item?.id);
                                    console.log(item?.id);
                                    setIsVisibleTopUpModal(true);
                                  }}
                                >
                                  <DownloadOutlined /> Top Up Saldo
                                </Button>
                              )}
                              <Button
                                onClick={async () => {
                                  await store.transaction.getDataHistoryTopUp(
                                    item.id
                                  );
                                  history.push(
                                    LINKS.USER_DETAIL.replace(":id", item.id)
                                  );
                                  console.log(item.id);
                                }}
                              >
                                Detail
                              </Button>
                            </p>
                          </div>
                        }
                      />
                      <div style={{ marginRight: 16 }}>
                        <p
                          style={{
                            fontSize: 9,
                            margin: 0,
                          }}
                        >
                          {/* <Button
                            type={
                              item?.isActive === true ? "danger" : "primary"
                            }
                            onClick={() =>
                              changeStatus(item?.id, item?.isActive)
                            }
                          >
                            {item?.isActive === true ? "Inactive" : "Active"}
                          </Button> */}
                        </p>
                      </div>
                    </List.Item>
                    <Divider plain style={{ margin: 0 }} />
                  </div>
                );
              }}
            />
          )}
        </div>
      </Card>
      <Modal
        visible={isVisibleTopUpModal}
        title="Top Up Saldo"
        okText="Top Up"
        cancelText="Batal"
        onCancel={() => {
          form.resetFields();
          handleCancelTransaction();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values, "isi form");
              handleSubmitTransaction(values);
              form.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="amount"
            label="Nominal"
            rules={[{ required: true, message: "Please input amount!" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `Rp. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\Rp.\s?|(,*)/g, "")}
            />
          </Form.Item>
        </Form>
      </Modal>
      <MembershipModal
        visible={visibleModal}
        confirmLoading={confirmLoading}
        initialData={initialData}
        onCreate={async (data, image, imageStore) => {
          onSubmit(data, image, imageStore);
        }}
        onCancel={async () => {
          setInitialData({});
          setVisibleModal(false);
          await store.membership.getData();
        }}
      />
      <Modal
        visible={store.membership.visibleModalFilterMembership}
        title={"Filter"}
        footer={footerLayoutFilter}
        onCancel={async () => {
          // setFilterMembership([]);
          // setFilterPartner([]);
          store.membership.filterMembership = null;
          store.membership.filterPartner = null;
          store.membership.visibleModalFilterMembership = false;
          await store.membership.getData();
        }}
      >
        <Row>
          <Col span={24}>
            <Title level={5} type={"secondary"} strong>
              Atasan/Superior
            </Title>
            <Select
              mode={"multiple"}
              placeholder="Choose Superior"
              onChange={(val) => {
                setFilterMembership(val);
              }}
              style={{ marginBottom: "20px", width: "100%" }}
              value={filterMembership}
            >
              {store.membership.data.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={24}>
            <Title level={5} type={"secondary"} strong>
              Tipe
            </Title>
            <Select
              mode={"multiple"}
              placeholder="Choose Type"
              onChange={(val) => {
                setFilterPartner(val);
              }}
              style={{ marginBottom: "20px", width: "100%" }}
              value={filterPartner}
            >
              <Option value="partner">Partner</Option>
              <Option value="b2c">B2C</Option>
            </Select>
          </Col>
        </Row>
      </Modal>

      <Modal
          visible={modalExport}
          title={"Export"}
          onOk={handleSubmitExport}
          onCancel={() => {
            setModalExport(false);
          }}
      >
        <Row>
          <Col span={24}>
            <Form layout="vertical" name="filter" form={formExport}>
              <Form.Item
                  name="start_date"
                  label="Dari"
                  rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                  name="end_date"
                  label="Sampai"
                  rules={[{ required: true, message: "Please input Date!" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                  name="supplier"
                  label="Supplier"
              >
                <Select
                    mode={"single"}
                    placeholder="Choose Supplier"
                    onChange={(val) => {
                      setFilterSupplier(val);
                    }}
                    style={{ width: "100%" }}
                    value={filterSupplier}
                >
                  <Option value="all">All Supplier</Option>
                  {store.supplier.data.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                  name="buyer"
                  label="Buyer"
              >
                <Select
                    mode={"single"}
                    placeholder="Choose Buyer"
                    onChange={(val) => {
                      setFilterBuyer(val);
                    }}
                    style={{ width: "100%" }}
                    value={filterBuyer}
                >
                  <Option value="all">All Buyer</Option>
                  {store.membership.dataFilterMembership.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name} ({item.username})
                      </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
